import ApiLogin from "../data/login.api";
import Cookies from 'js-cookie'
import { LoginByAccount, LoginByPhone} from "../model/login.model";
import { InputFormOfVerifyByPhone } from "./form.uc";
import { getPublickey } from "./member.uc";
// import { ApiMember } from "~/api";

class LoginByPhoneUc extends InputFormOfVerifyByPhone{

  loginCore() {
    return new ApiLogin().loginOrRegistry(this.form.loginOrRegistrySubmition).then(null,(res)=>{
      const errCode=10057
      if(res.data.code==errCode){
        this.form.updateCaptchaImg()
        this.form.captcha=""
        this.form.verCode=""
      }
    })
  }
  viewFirm = (firm) => {
    this.form.viewingFirm = firm
  }

  stopViewFirm = () => {
    this.form.viewingFirm = null
  }

}

export class LoginByAccountUc{
  constructor() {
    this.form = null
  }

  fetchForm() {
    this.form = new LoginByAccount()
    return Promise.resolve(this.form)
  }

  loginCore() {
    return getPublickey(this.form.pwd)
    .then((rasPw) => {
      return new ApiLogin().login({
        accountType: this.form.userType,
        account: this.form.account,
        pwd: rasPw
      })
    })

  }
}

function LoginUc(type, options = { onLoginFinish: null }){
  let _this;
  switch(type) {
    case LoginUc.byPhone:
      _this = new LoginByPhoneUc();
      break;
    case LoginUc.byAccount:
      _this = new LoginByAccountUc();
      break;
    default:
      _this = {}
  }

  _this.login = function() {
    return _this.loginCore().then((tokenWrapper) => {
      return tokenWrapper
    })
  }

  _this.handleCompanyProperty = function(tokenWrapper) {
    if (!tokenWrapper.entAttIsBlank) {
      _this.form.promptCompanyProperty = true;
    } else {
      options.onLoginFinish()
    }
    return tokenWrapper;
  }

  _this.logout = function () {
    return new ApiLogin().logout()
  }

  _this.isLogin = function () {
    return new ApiLogin().isLogin()
  }

  return _this;
}

LoginUc.byPhone = 1
LoginUc.byAccount = 2

export default LoginUc;