<template>
  <div v-if="coulmnId">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      :disabled="finished"
      success-text="刷新成功"
    >
    <van-list 
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      :immediate-check="false"
    >
      <ListItem  v-for="item in newsList " :key="item.id" :listItem="item"/>
    </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
  import {getNewsList} from '#domain-member/data/product.api.js'
  import ListItem from './list-item.vue'
  export default {
    data() {
      return {
        page:1,
        limit:10,
        loading:true,//是否在加载数据
        isLoading: false, //是否加载更多
        finished:false, //数据是否加载完成
        newsList:[]
      }
    },
    props: {
      coulmnId: {
        type: String,
        default: 0
      },
    },
    components: {
      ListItem,
    },
    mounted () {
      this.initNewsList(this.page,this.limit)
    },
    methods: {
      onRefresh(){
        this.initNewsListRefresh()
      },
      onLoad(){
        this.initNewsList(false,this.page,this.limit)
      },
      async initNewsList () {
        const { data: res } = await getNewsList(this.page,this.limit,this.coulmnId)
        if(res.code==0){
          this.newsList = [ ...this.newsList,...res.data.list,]
          this.page = this.page+1
         } else {
            this.page = this.page
         }
            this.loading = false
            this.isLoading = false
            if(Math.ceil((res.data.total/res.data.pageSize))<=res.data.pageNo){
              return  this.finished = true
            }
         },
      async initNewsListRefresh () {
        const { data: res } = await getNewsList(1,10,this.coulmnId)
        if(res.code==0){
          this.newsList = res.data.list
          this.isLoading = false
        } 
      },
    },
  }
</script>
<style lang="less" scoped>
.list{
  overflow-y: scroll;
  height: 100%;
}

</style>