<template>

  <div v-if="consultingDetails.itemDetailsNewsInfo">
    <div class="news-content">
      <h1 class="news-title">
        {{ consultingDetails.itemDetailsNewsInfo.title }}
      </h1>
      <div class="news-main">
        <div class="news-info">
          <div class="news-info-lf">
            <span>{{ consultingDetails.itemDetailsNewsInfo.reviewerDate }}</span>
            <span>责任编辑：{{ consultingDetails.itemDetailsNewsInfo.creatorName || '暂无' }}</span>
          </div>
          <div  class="right">
            <div class="num">
							<div class="eye">
								<img src="../../../assets/eye.png" alt="">
							</div>
							<div class="eye-num">
               	{{ consultingDetails.itemDetailsNewsInfo.viewNum }}
							</div>
						</div>
          </div>
        </div>
        <Ifream :iframeCon="consultingDetails.itemDetailsNewsInfo.details" :innerHtml="true"></Ifream>
      </div>
			<div class="datasource">
				<span>来源:&nbsp;&nbsp;</span>
        <span>{{replaceRichText(consultingDetails.itemDetailsNewsInfo.source)|| '盟浪头条' }}</span>
			</div>
    </div>
		<Back/>
  </div>
</template>

<script>
  import Back from '../../components/goback/index.vue'
	import {ConsultingNews} from '../../../entity/consulting.js'
	import Ifream from '../../components/iframe'
	export default {
		data() {
			return {
			 	ConsultingNews,
				consultingDetails:new ConsultingNews() ,
				id:this.$route.query.id,
				coulmnId:this.$route.query.coulmnId
			}
		},
		created () {
			this.consultingDetails.getConsultingNewsDetails(this.coulmnId,this.id)
		},
		components: {
			Ifream,
			Back
		},
		methods: {
			// 读取富文本内容
	    replaceRichText(val) {
        return val && val.replace(/<style type="text\/css">[\w\d\ \n\=\#\"\'\!\/\-\_\:\;\.\,\%\&微软雅黑苹方宋体\(\)\{\}\n\r]+<\/style>|<[\w\d\ \n\=\#\"\'\!\\\/\-\_\:\;\.\,\?\%\&微软雅黑苹方宋体\(\)]+>|&nbsp;/g,"").replace(/\n{2,}/g, "\n")
      },
		},
	}
</script>

<style lang="less" scoped >
  .news-content {
  	background-color: #ffffff;
  	min-height: 600px;
  	.news-title {
			display: block;
			width: 339px;
			height: auto;
			margin:auto;
			margin-top:20px;
			font-family: PingFangSC-Medium;
			font-size: 20px;
			color: #333333;
			letter-spacing: 0;
			text-align: left;
			line-height: 28px;
			font-weight: 600;
			margin-bottom: 12px;
  	}
  	.news-main {
    	width: 339px;
    	margin: auto;
    	.news-info {
				width: 100%;
				height: 18px;
				// padding-right: 5px;
      	display: flex;
      	justify-content: space-between;
      	align-items: center;
      	line-height: 18px;
				font-family: PingFangSC-Regular;
				font-size: 13px;
				color: #666666;
				text-align: center;
				font-weight: 400;
				margin-bottom: 12px;
      .right {
        font-size: 12px;
				.num {
					display: block;
					display: flex;
					justify-content: space-between;
					text-align: right;
          .eye{
						width: 14px;
						height: 8px;
						img{
							width: 100%;
							height:100%;
						}
					}
					.eye-num{
						margin-left: 5px;
					}
        }
      }
      .news-info-lf {
        display: flex;
        align-items: center;
        span{
          margin-right: 10px;
        }
      }
    }
    .iframe-box{
      width: 339px;
      overflow: hidden;
      .news-html {
        width: 339px;
        padding: 30px 0 40px 0;
        min-height: 600px;
        overflow: auto;
      }
    }
  }
	.datasource{
		width: 339x;
		margin-top: 10px;
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #666666;
		letter-spacing: 0;
		font-weight: 400;
		padding-left:25px;
	}
}
</style>