<template>
  <div>
    <h1>这里是个人中心修改密码页面</h1>
    <Header/>
    <!-- 修改密码的地方 -->
    <div class="edit-password">
      <div class="new-password">
        <div class="new-password-description">
          新密码
        </div>
        <div class="new-password-input">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from '../components/head/index'
  export default {
    data() {
      return {
       text:''
      }
    },
    components: {
      Header,
    },
    
  }
</script>

<style lang="less" scoped>
.edit-password{
  margin-top: 43px;
  .new-password{
    .new-password-description{
      margin: auto;
    }
    .new-password-input{
      margin: auto;
    }

  }
}

</style>