<template>
  <modal-like>
    <div style="text-align: center" class="logo-con">
      <a href="/">
        <img src="@/assets/logo/head_logo.png" class="logo"/>
      </a>
    </div>
    <LoginCore class="login-h5" />
  </modal-like>
</template>

<script>
import LoginCore from '#domain-member/view/login.vue'
import ModalLike from '@/views/layout/modal-like.vue'

export default {
  components: { LoginCore, ModalLike },
}
</script>

<style lang="less" scoped>
.logo{
  width: 91px;
}
.logo-con{
  text-align: center;
  margin-bottom: 15px;
}
</style>