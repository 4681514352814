<template>
  <div>
    <div class="menu">
      <div class="search-box">
        <div class="nosearch" v-if="isShowSearch == false">
          <div class="reset" @click="reset">重置</div>
          <div class="search" @click="openOverlay">
            搜索
            <span class="arrow_down" @click="openOverlay"></span>
          </div>
        </div>
        <div class="hassearch" v-else>
          <div class="search-form">
            <el-form
              :label-position="labelPosition"
              ref="searchForm"
              :rules="rules"
              :model="form"
            >
              <div class="search-form-row">
                <el-form-item
                  label="时间段:"
                  prop="timeFrame"
                  class="timeFrame"
                >
                  <el-select
                    v-model="form.timeFrame"
                    placeholder="请选择时间段"
                    class="search"
                    filterable
                    collapse-tags
                    @change="changeTime"
                  >
                    <el-option
                      v-for="item in timeFrameList"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="search-form-row">
                <el-form-item label="风险类型:" class="riskType">
                  <el-select
                    v-model="form.riskTypes"
                    placeholder="请选择风险类型"
                    class="search"
                    filterable
                    multiple
                    collapse-tags
                  >
                    <el-option
                      v-for="item in riskTypeList"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="风险等级:">
                  <el-select
                    v-model="form.analystRatings"
                    placeholder="请选择风险等级"
                    class="search"
                    filterable
                    multiple
                    collapse-tags
                  >
                    <el-option
                      v-for="item in analystRatingsList"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="filter-condition-btn">
            <div class="filter-condition-btn-reset" @click="reset">重置</div>
            <div class="filter-condition-btn-search" @click="search">
              搜索
              <span class="arrow_top" @click="closeOverlay"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="risk-look-table">
      <div class="list-head">
        <div class="list-table-top">
          <div
            class="
              list-table-top-text list-table-item
              risk-type-top risk-type-top-indtance
            "
          >
            风险类型
          </div>
          <div class="list-table-top-text list-table-item risk-type-grade">
            风险评级
          </div>
          <div class="list-table-top-text list-table-item event-count">
            事件数量
          </div>
          <div class="list-table-top-text list-table-item company-count">
            公司数量
          </div>
        </div>
      </div>
      <div class="list-table-contenct" v-if="tableData.length > 0">
        <div
          v-for="(item, index) in tableData"
          :key="index"
          class="list-table-connect-row"
        >
          <!-- 风险类型 -->
          <div
            v-if="false"
            class="list-table-contenct-item list-table-item risk-type-top"
            :title="item.riskType"
          >
            {{ item.riskType }}
          </div>
          <div
            class="
              risk-type
              list-table-contenct-item list-table-item
              risk-type-top
            "
          >
            <div class="risk-type-item risk-type-risk">
              <div class="risk-type-item-icon">
                <img
                  src="../../../assets/productIntro/3-risktracker/F.png"
                  alt=""
                  v-if="item.risk.riskType == riskTypeF"
                />
                <img
                  src="../../../assets/productIntro/3-risktracker/E.png"
                  alt=""
                  v-if="item.risk.riskType == riskTypeE"
                />
                <img
                  src="../../../assets/productIntro/3-risktracker/S.png"
                  alt=""
                  v-if="item.risk.riskType == riskTypeS"
                />
                <img
                  src="../../../assets/productIntro/3-risktracker/g.png"
                  alt=""
                  v-if="item.risk.riskType == riskTypeG"
                />
              </div>
              <div class="risk-type-item-text">
                {{ item.risk.riskName }}
              </div>
            </div>
          </div>
          <!-- 风险等级 -->
          <div class="list-table-contenct-item list-table-item risk-type-grade">
            {{ Number(item.analystRatings) }}
          </div>
          <!-- 事件数量 -->
          <div class="list-table-contenct-item event-count">
            {{ item.eventAmount }}
          </div>
          <div
            class="list-table-contenct-item list-table-item company-count"
            @mouseenter="showList(index)"
            @mouseleave="noList(index)"
          >
            <div class="wd">
              {{ item.companyAmount }}
            </div>
            <!-- class="new-span"   -->
          </div>
        </div>
      </div>
    </div>
    <jurisdictionDialoag
      serviceCode="SERVICE-002"
      ref="productIntro"
      :productName="productName"
      :authority="authority"
      :isHasApplay="isHasApplay"
    />
    <div class="mask">
      <van-overlay :show="showOverlay" />
    </div>
    <div class="paginations">
      <MyPagination
        :total="total"
        @changePage="changePage"
        :currentPage="currentPage"
        :disAdd="disAdd"
        :pageSize="pageSize"
      />
    </div>
    <div>
      <dialoag :isShow="isShowLogin" @close="isShowLogin = false" />
    </div>
  </div>
</template>

<script>
const riskTypeF = "F"
const riskTypeE = "E"
const riskTypeS = "S"
const riskTypeG = "G"
import dialoag from '../../../components/dialoag.vue'
import MyPagination from './my-paganation.vue'
import jurisdictionDialoag from '../../../views/components/product-intro/jurisdiction-message.vue'
import { getTimeFrameList, getRiskLevelList, getRiskTypeList } from '../../../api/risk.js'
import { DropdownMenu, DropdownItem } from 'vant'
import { Form, FormItem, Input, Select, Option, Message, Icon } from 'element-ui'
import request from '@/untils/request'
export default {
  props: {
    token: {
      type: Boolean,
      default: false
    },
    authority: {
      type: Number,
    }
  },
  data () {
    return {
      disAdd:false,
      riskTypeF,
      riskTypeE,
      riskTypeS,
      riskTypeG,
      isHasApplay: false,
      isShowoverlay: true,
      labelPosition: 'right',
      showOverlay: false,
      isShowSearch: false,
      currentPage: 1,
      isShowLogin: false,
      active: null,
      form: {
        timeFrame: 'WEEK',// 开发后调成'WEEK'//时间段  THREE_MONTHS
        riskTypes: null,//风险类型
        analystRatings: ['5.0'],//风险等级
      },
      page: 1,//风险看板的页码,
      pageSize: 10,//风险看板的页数
      total: null,
      tableData: [],//风险事件看板table,
      timeFrameList: [],//时间段的option
      analystRatingsList: [],//风险等级的option
      riskTypeList: [],//风险类型的option
      rules: {
        timeFrame: [
          { required: true, message: '请选择时间段', trigger: 'change' },
        ],
      },
      productName: '避雷通',
    }
  },
  components: {
    jurisdictionDialoag,
    MyPagination,
    dialoag,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Message.name]: Message,
    [Icon.name]: Icon
  },
  mounted () {
    this.getRiskTableList(1, 10)
    this.getTimeOption()
    this.getRiskLevelOption()
    this.getRiskTypeOption()
  },

  methods: {
    applicance () {
      this.isHasApplay = false
      this.showApplicance()
    },
    showApplicance () {
      this.$refs.productIntro.showApplicance()
    },
    getRiskTableList (page, pageSize) {
      request.post('/ml-product/lightning/lightningnewsv2/riskEventStatisticsKanban', { ...this.form, page: page, limit: pageSize }).then(({ data }) => {
        if (data.code == 0) {
          this.tableData = data.data.list
          this.total = data.data.total
          if(this.total<=this.pageSize){
            this.disAdd=true
          } else {
            this.disAdd=false
          }
        }
      })
    },
    async getTimeOption () {
      const { data } = await getTimeFrameList()
      if (data.code == 0) {
        this.timeFrameList = data.data.dataList
      } else {
        Message({
          type: 'error',
          message: data.msg
        })
      }
    },
    async getRiskLevelOption () {
      const { data } = await getRiskLevelList()
      if (data.code == 0) {
        this.analystRatingsList = data.data.dataList
      } else {
        Message({
          type: 'error',
          message: data.msg
        })
      }
    },
    async getRiskTypeOption () {
      const { data } = await getRiskTypeList()
      if (data.code == 0) {
        this.riskTypeList = data.data
      } else {
        Message({
          type: 'error',
          message: data.msg
        })
      }
    },
    changeMenu (row) {

    },
    openOverlay () {
      this.showOverlay = true
      this.isShowSearch = true
    },
    closeOverlay () {
      this.showOverlay = false
      this.isShowSearch = false
    },
    changeTime () {

    },
    resetForm () {
      this.form = {
        timeFrame: 'WEEK',// 开发后调成'WEEK'//时间段  THREE_MONTHS
        riskTypes: null,//风险类型
        analystRatings: ['5.0'],//风险等级
      }
    },
    reset () {
      this.resetForm()
      this.tableData = []
      this.page = 1
      this.isLoading = false
      this.getRiskTableList(1, 10)
      this.showOverlay = false
      this.isShowSearch = false
    },
    search () {
      if (this.token) {
        if (this.authority == 1 || this.authority == 4) {
          this.showOverlay = false
          this.isShowSearch = false
          this.tableData = []
          this.page = 1
          this.isLoading = false
          this.getRiskTableList(1, 10)
        } else {
          this.showOverlay = false
          this.isShowSearch = false
          this.isHasApplay = true
          this.showApplicance()
        }
      } else {
        this.isShowLogin = true
        // this.$router.push('/login')
      }
    },
    changePage (val) {
      if (this.token) {
        if (this.authority == 1 || this.authority == 4) {
          this.page = val
          this.currentPage = val
          this.getRiskTableList(this.page, 10)
        } else {
          this.isHasApplay = true
          this.showApplicance()
        }
      } else {
        this.isShowLogin = true
        // this.$router.push('/login')
      }
    }

  },
}
</script>

<style lang="less" scoped>
.risk-look-table {
  // height: 460px;
  margin-bottom: 25px;
  .list-head {
    display: flex;
    align-items: center;
    width: 375px;
    height: 50px;
    background: #f7f8fa;
    .list-table-top {
      display: flex;
    }
  }
  .list-table-contenct {
    width: 375px;
    border: 1px solid #ebecef;
    .list-table-connect-row {
      width: 375px;
      height: 40px;
      background: #ffffff;
      border-bottom: 1px solid #ebecef;
      display: flex;
    }
  }
}
.list-table-top-text {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3f4050;
}
.risk-type-item-icon {
  width: 13px;
  height: 17px;
}
.list-table-contenct-item {
  display: flex;
  align-items: center;
  // line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #777783;
}
.risk-type-top {
  // width: 150px; //88+75
  width: 180px;
  text-align: center;
}
.risk-type-grade {
  display: flex;
  justify-content: center;
  width: 63px;
}
.event-count {
  width: 63px;
  display: flex;
  justify-content: center;
}
.company-count {
  width: 63px;
  display: flex;
  justify-content: center;
}
.search-box {
  margin-top: 12px;
  position: relative;
  .nosearch {
    display: flex;
    margin-top: 14px;
    margin-bottom: 14px;
    padding-left: 7px;
    padding-right: 7px;
    .reset {
      width: 168px;
      height: 33px;
      border: 1px solid #417dff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #417dff;
      line-height: 33px;
      text-align: center;
    }
    .search {
      width: 168px;
      height: 33px;
      background: #417dff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 33px;
      text-align: center;
      margin-left: 25px;
      position: relative;
      .arrow_down {
        width: 0;
        height: 0;
        position: absolute;
        top: 15px;
        right: 53px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #ffffff;
      }
    }
  }
  .hassearch {
    margin-bottom: 14px;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 2;
    /deep/.search-form {
      .el-input {
        width: 280px;
      }
      .el-input__inner {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a0a3ac;
      }
      .el-form-item__label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323338;
        min-width: 80px;
      }
      .search-form-row {
        .el-form-item__content {
          display: flex;
        }
      }
    }
    // 按钮
    .filter-condition-btn {
      height: 33px;
      display: flex;
      padding-left: 7px;
      padding-right: 7px;
      margin-bottom: 20px;
      margin-top: 17px;
      .filter-condition-btn-reset {
        width: 168px;
        height: 33px;
        border: 1px solid #417dff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #417dff;
        line-height: 33px;
        text-align: center;
      }
      .filter-condition-btn-search {
        width: 168px;
        height: 33px;
        background: #417dff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 33px;
        text-align: center;
        margin-left: 25px;
        position: relative;
        .arrow_top {
          width: 0;
          height: 0;
          position: absolute;
          bottom: 14px;
          right: 28px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 4px solid #ffffff;
        }
      }
    }
  }
}
.filter-condition-text-box {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.filter-condition-title-box {
  display: flex;
  flex-wrap: 1;
  flex: 1;
}

/deep/.mask {
  .van-overlay {
    top: 323px;
  }
}
.timeFrame {
  /deep/.el-form-item__label:before {
    display: none;
  }
}
.risk-type {
  padding-left: 16px;
  .risk-type-item {
    display: flex;
    align-items: center;
    .risk-type-item-icon {
      width: 13px;
      height: 17px;
      margin-right: 3px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.risk-type-risk {
  width: 160px;
}
.risk-type-top-indtance {
  text-align-last: left;
  padding-left: 16px;
}

/deep/.riskType {
  .el-tag {
    max-width: 140px;
    display: inline-block;
    display: flex;
    align-items: center;
  }
  .el-select__tags-text {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
/deep/.el-icon-close {
  color: #fff !important;
  background-color: #dee1e9 !important;
}
.paginations {
  margin: auto;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 79px;
}
</style>