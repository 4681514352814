export default {
  tableData:[
    {
      dataName:"FIN-ESG评估数据库",
      zhTableName:"FIN-ESG指标表"
    },
    {
      dataName:"FIN-ESG评估数据库",
      zhTableName:"FIN-ESG原始指标（值）表"
    },
    {
      dataName:"FIN-ESG评估数据库",
      zhTableName:"FIN-ESG衍生指标（评分）表"
    },
    {
      dataName:"FIN-ESG评估数据库",
      zhTableName:"FIN-ESG评级表 "
    },
    {
      dataName:"专题数据库",
      zhTableName:"认证数据"
    },
    {
      dataName:"专题数据库",
      zhTableName:"专利数据"
    },
    {
      dataName:"专题数据库",
      zhTableName:"碳数据"
    },
    {
      dataName:"主表数据库",
      zhTableName:"证券表"
    },
    {
      dataName:"主表数据库",
      zhTableName:"证券历史变更表"
    },
    {
      dataName:"主表数据库",
      zhTableName:"指数表"
    },
    {
      dataName:"主表数据库",
      zhTableName:"指数期表"
    },
    {
      dataName:"主表数据库",
      zhTableName:"指数期成分股表"
    },
    {
      dataName:"主表数据库",
      zhTableName:"公司表"
    },
    {
      dataName:"主表数据库",
      zhTableName:"公司历史变更表"
    },
    {
      dataName:"主表数据库",
      zhTableName:"行业表"
    },
    {
      dataName:"主表数据库",
      zhTableName:"交易所表"
    },
    {
      dataName:"主表数据库",
      zhTableName:"公司行业表"
    },
  ]
}