<template>
  <div v-if="newsData.consultNewsList">
		<div class="home-list-top">
			<div class="home-list-top-text" v-for="item in HomeUpdataConStudyIdea.esgType" :key="item.id" @click="getItemId(item)" :class="activeIndex==item.id?'home-list-top-text-active':'home-list-top-text'">
        <span>{{item.label}}</span>
			</div>

		</div>
		<div class="box-sanjiao-fianing" v-if="activeIndex==1">
			<div class="sanjiao"></div>
		</div>
		<div class="box-sanjiao-mark" v-if="activeIndex==2">
			<div class="sanjiao"></div>
		</div>
		<div class="home-list" v-if="newsData.consultNewsList.length!=0" >
      <div class="home-list-item" v-for=" item in newsData.consultNewsList" :key="item.id" @click="goNewsDetails(item)">
        <div class="home-list-item-top">
          {{item.title}}
				</div>
				<div class="home-list-item-bottom">
          <div class="home-list-item-bottom-time">{{item.reviewerDate? item.reviewerDate.split(' ')[0]:'' || item.releaseTime ? item.releaseTime.substring(0, 10) : '' }}</div>
				</div>
			</div>
		</div>
		<div  class="description" v-if="newsData.consultNewsList.length==0">
      暂无数据
		</div>
		<div class="more" @click="goMore">
      查看更多
		</div>
  </div>
</template>

<script>
import {HomeUpdataConStudyIdea}  from '#domain-news/entitiy-homeupadata-consult-study-idea.js'
  export default {
		data() {
			return {
				menuList:HomeUpdataConStudyIdea.menuList,
				activeIndex:1,
				HomeUpdataConStudyIdea,
				newsData:new HomeUpdataConStudyIdea(),
				newsId:'1'
			}
		},
		mounted () {
			this.newsData.init(this.menuList[0].id, this.activeIndex)
		},
		methods: {
			getItemId(item){
	     	this.activeIndex=item.id
				this.newsData.clickDifferentType(this.newsId,	this.activeIndex)
				  // this.esgDataList.clickDifferentType(row.id,this.esgType[0].id)
		 	},
			goMore(){
				this.$router.push('/consulting')
			},
			goNewsDetails(item){
				this.$router.push({
					path:'/consulting-details',
					query:{
						id:item.id,
						coulmnId:item.coulmnId,
            home:0
					}
				})
			}

		},
  }
</script>

<style lang="less" scoped>
	.home-list-top{
  	height: 40px;
		width: 100%;
		display: flex;
		justify-content: space-around;
		box-shadow: 0 1px 0 0 #D8E2F9;
		.home-list-top-text{
			font-size: 16px;
			color: #666666;
			font-weight: 500;
			line-height: 40px;
		}
		.home-list-top-text-active{
			font-size: 18px;
			color: #417DFF;
		 	letter-spacing: 1px;
			font-weight: 600;
			line-height: 40px;
		}
	}
	.box-sanjiao-fianing{
		.sanjiao {
  /* 高度和宽度必须指定为零 */
    	width: 0;
    	height: 0;
  /* 给四边都设置边框和颜色 */
    	border-bottom: 10px solid #417DFF;
    	border-left: 6px solid transparent; //和下面的组成底部
    	border-right: 6px solid transparent;
			position: relative;
			left:24%;
			top:-10px;
		}
	}
	.box-sanjiao-mark{
		.sanjiao {
    /* 高度和宽度必须指定为零 */
    	width: 0;
    	height: 0;
   /* 给四边都设置边框和颜色 */
    	border-bottom: 9px solid #417DFF;
   		border-top: 9px solid transparent;
    	border-left: 5px solid transparent;
    	border-right: 5px solid transparent;
			position: relative;
			left:72.3%;
			top:-18px
		}
	}
	.home-list{
		.home-list-item{
			width: 95%;
			height: auto;
			border-bottom: 1px solid #dadada;
			padding-left:16px;
			padding-top: 11px;
			padding-right: 19px;
			margin:auto;
			line-height: 22px;
			.home-list-item-top{
				width: 100%;
				height: auto;
			}
			.home-list-item-bottom{
       	width: 100%;
				height: 30px;
				.home-list-item-bottom-time{
					width: 100%;
					font-size: 12px;
					color: #999999;
					// letter-spacing: 0.67px;
					font-weight: 400;
					text-align: right;
					line-height: 30px;
				}
			}
		}
		.home-list-item:nth-child(1){
			margin-top: -10px;
		}
	}
	.more{
		width:95%;
		text-align: center;
		margin:auto;
		color:#417DFF;
		height: 35px;
		line-height: 35px;
		margin-top: 15px;
		border: 1px solid #417DFF;
		border-radius: 4px;
	}
	.nodata-text-description {
		width: 100%;
    min-height: 60px;
    line-height: 60px;
    text-align: center;
    color: #909399;
    font-size: 14px;
  }

</style>