<template>
  <div class="content">
    <p class="msg-title">
      如需查看更多，欢迎联系我们！
    </p>
    <el-form label-position="left" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <p> <img src="../../../assets/cfa/required.png" alt=""> 姓名</p>
      <el-form-item label="" label-width="0" prop="name">
        <el-input v-model.trim="ruleForm.name"></el-input>
      </el-form-item>
      <p> <img src="../../../assets/cfa/required.png" alt=""> 联系方式</p>
      <el-form-item label="" label-width="0" prop="tel">
        <el-input v-model.trim="ruleForm.tel"></el-input>
      </el-form-item>
      <p> <img src="../../../assets/cfa/required.png" alt=""> 企业类型</p>
      <van-cell is-link :title="ruleForm.companyType" @click="show = true" />
      <van-action-sheet title="选择类型" v-model="show" :actions="actions" @select="onSelect" />
      <p style="margin-top: 20px"> <img src="../../../assets/cfa/required.png" alt=""> 企业名称</p>
      <el-form-item label="" label-width="0" prop="companyName">
        <el-input v-model.trim="ruleForm.companyName"></el-input>
      </el-form-item>
      <p> 咨询留言</p>
      <el-form-item label="" label-width="0">
        <el-input type="textarea"  :maxlength="1000" show-word-limit :rows="4" v-model.trim="ruleForm.msgDesc" placeholder="为了便于服务反馈，您可留言相关感兴趣内容或需求"></el-input>
      </el-form-item>
    </el-form>
    <div class="btn-btm-box">
      <button class="btn-btm" @click="submitForm('ruleForm')">
        提交
      </button>
    </div>
  </div>
</template>

<script>
import { Form, FormItem,Input,Select,Option,Message } from 'element-ui';
import { ActionSheet,Cell,Toast } from "vant";
import request from '@/untils/request'
export default {
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [ActionSheet.name]: ActionSheet,
    [Cell.name]: Cell,
    [Message.name]: Message,
    [Toast.name]: Toast,
  },
  data () {
    var validatePhone = (rule, value, callback) => {
      let reg = /^1[3456789]\d{9}$/
      if(!value){
        return callback(new Error('请填写手机号'))
      }
      setTimeout(() => {
        if(!reg.test(value)){
          callback(new Error('请输入11位手机号'));
        } else {
          callback()
        }
      },100)
    }
    return {
      ruleForm: {
        name: '',
        tel: '',
        companyType: '',
        companyName: '',
        msgDesc: '',
        msgPath: null
      },
      show: false,
      actions: [],
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        tel: [
          { required: true,type:'number', validator:validatePhone, message: '请填写正确的联系方式', trigger: 'blur' }
        ],
        companyType: [
          { required: true, message: '请选择企业类型', trigger: 'change' }
        ],
        msgDesc: [
          { required: true, message: '请填写咨询留言', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请填写企业名称', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    request.post('/ml-admin/sys/dict/type/selectByDictType',{
      dictType: 'contactConsultingEnterpriseType'
    }).then(res=> {
      this.actions = res.data.data.dataList.map(item=> ({
        value: item.dictValue,
        name: item.dictLabel
      }))
    })
  },
  methods: {
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      this.ruleForm.companyType = item.value
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if(!this.ruleForm.companyType){
          Message({
            showClose: true,
            message: '企业类型不能为空',
            type: 'warning'
          });
        }
        if (valid) {
          request.post('/ml-carfinance/cf/green/addData',{...this.ruleForm,msgPath: '碳融通H5-绿色资产查询'}).then(res=> {
            if (res.data.code === 0) {
            Message({
              message: '提交成功',
              type: 'success',
              onClose:()=> {
                this.$router.go(-1)
              }
            })
          }
          })
        } else {
          Message({
            message: '请按要求填写！',
            type: 'error'
          })
          return false;
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
  .content {
    padding: 20px 30px;
    .msg-title {
      margin-bottom: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
    p {
      margin-bottom: 10px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2F3234;
      line-height: 22px;
    }
    .btn-btm-box {
      text-align: center;
      margin-top: 56px;
      .btn-btm {
        width: 144px;
        height: 40px;
        background: #417DFF;
        border-radius: 4px;
        color: #fff;
        border: none;
      }
    }
  }
  /deep/.van-cell {
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    padding: 1.667vw 4.267vw;
  }
  /deep/.van-action-sheet__header {
    background: #F6F8FDFF;
  }
</style>