<template>
  <el-form
    v-if="form" :model="form" 
    :rules="form.rules"
    hide-required-asterisk
    ref="form"
  >
    <el-form-item label="邮箱" prop="email">
      <el-input
        v-model.trim="form.email"
        placeholder="请输入邮箱"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="验证码" prop="verCode">
      <div class="cap-con">
        <el-input
          v-model.trim="form.verCode"
            placeholder="请输入验证码"
        >
        </el-input>
        <AsyncButton
          class="code-btn"
          :disabled="form.codeDisabled"
          :click="sendCode"
        >
          <span v-if="form.codeShow">获取验证码</span>
          <span v-if="!form.codeShow" style="color: #676767">剩余 {{ form.count }} 秒</span>
        </AsyncButton>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { Form, FormItem, Input, Select, Option, Button,Message  } from 'element-ui'
import AsyncButton from '../../../share/vue/async-button.vue'
import {debounce} from '../../../utils';

export default {
  props: ['uc'],
  computed: {
    form() {
      return this.uc.form
    }
  },
  components: {
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-select': Select,
    'el-option': Option,
    'el-button': Button,
    'Message': Message,
    AsyncButton,
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    sendCode () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validateField('email', errorMessage => {
          if (!errorMessage) {
            resolve(this.uc.sendCode())
          } else {
            reject(
              Message({
                type:'error',
                message:'请按规则填写',
                center:true,
                showClose:false,
                iconClass: 'login-phone',
                customClass: 'el-message--error login-phone'
              })
            )
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './style/form.less';
</style>
<style lang="less">
.login-phone{
  top: 0 !important;
}
.login-phone:not(:last-child){
  visibility: hidden !important;
}
</style>