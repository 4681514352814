import request from "../untils/request"

// 获取banner图的方法
export const getNewsList = function (page, limit, coulmnId) {
  return request.post("/api/ml-product/index/news/queryNewsPageByParam", {
    page: page,
    limit: limit,
    coulmnId: coulmnId
  })
}

//获取时间段的api
export const getTimeFrameList = function () {
  return request.post("/api/ml-admin/sys/dict/type/selectByDictType", { dictType: 'timeFrame' })
}

//获取风险等级的api
export const getRiskLevelList = function () {
  return request.post('/ml-admin/sys/dict/type/selectByDictType', {
    dictType: 'analystRatings'
  })
}
//获取风险类型的api
export const getRiskTypeList = function () {
  return request.get('/ml-product/lightning/lightningnewsv2/riskTypeList')
}

