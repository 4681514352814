<template>
  <div>
    <Header />
    <Swiper :imageLocation="imageLocation" />
    <div class="applay-btn" @click="openDialoag">申请试用</div>
    <div class="tabs-box">
      <div class="tabs">
        <div
          class="tabs-productintro"
          :class="active == First ? 'actived-color' : ''"
          @click="goProductintro"
        >
          产品介绍
        </div>
        <div
          class="apidocument"
          :class="active == Second ? 'actived-color' : ''"
          @click="goApiDocument"
        >
          API
        </div>
        <div
          v-if="false"
          class="datadictionaries"
          :class="active == Three ? 'actived-color' : ''"
          @click="goDataDiction"
        >
          数据字典
        </div>
        <div
          class="triangle"
          :class="
            active == Second
              ? 'triangletwo'
              : active == Three
              ? 'trianglethree'
              : ''
            "
          >
          <img src="@/assets/productIntro/6-database/triangle.png" alt="" />
        </div>
      </div>
    </div>
    <productIntro v-if="active==First"/>
    <applayDialoag :isShow="isShow" @close="isShow = false" />
    <dialoag :isShow="isShowLogin" @close="isShowLogin = false" />
    <apiNewSummarize v-if="active==Second"/>
  </div>
</template>

<script>
const First = 1
const Second = 2
const Three = 3
import dialoag from '../../components/dialoag.vue'
import Header from "../../views/components/head/index.vue"
import Swiper from "../components/Swipe/swipe.vue"
import productIntro from './product-intro.vue'
import applayDialoag from './component/applay-database-dialoag.vue'
import apiNewSummarize from './new-api-summarize.vue'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      First,
      Second,
      Three,
      imageLocation: "h5-数据库首页banner",
      active: 1,
      isShow: false,
      isShowLogin: false,
    }
  },
  computed: {
    ...mapGetters(['logined']),
  },
  components: {
    Header,
    Swiper,
    productIntro,
    applayDialoag,
    dialoag,
    apiNewSummarize
  },
  methods: {
    goProductintro () {
      this.active = 1
    },
    goApiDocument () {
      this.active = 2
      // this.$router.push('/database-apidocument')
    },
    goDataDiction () {
      if (this.logined) {
        this.active = 3
        this.$router.push('/data-dictionaries')
      } else {
        this.isShowLogin = true
      }

    },
    openDialoag () {
      this.isShow = true
    }
  },
}
</script>

<style lang="less" scoped >
.applay-btn {
  width: 136px;
  height: 28px;
  background: linear-gradient(90deg, #46d4cd 0%, #4ba1d3 100%);
  box-shadow: 4px 1px 4px 0px rgba(25, 75, 119, 0.14);
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  left: 15px;
  top: 183px;
}
.tabs-box {
  position: relative;
}
.tabs {
  width: 375px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px rgba(65, 125, 255, 0.2);
  border-bottom: 1px solid #d5e6fa;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  position: relative;
}
.actived-color {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #417dff;
}
.triangle {
  width: 10px;
  height: 8px;
  position: absolute;
  left: 26.5%;
  bottom: 10%;
  img {
    width: 100%;
    height: 100%;
  }
}
.triangletwo {
  left: 76.5%;
}
.trianglethree {
  left: 82%;
}
</style>