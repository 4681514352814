<template>
  <div>
    <documentSummarize v-if="false"/>
    <div class="docsummarize">
      <div class="new-api-top">
        概述
      </div>
      <div class="line"></div>
      <div class="intro"> 
        <p> 盟浪以API、数据库同步、Excel文件等形式为用户提供数据服务，提供的数据包括财务（F）、创新（I）、价值准则（N）、环境（E）、社会（S）、公司治理（G）维度的原始指标数据、评分评级数据，以及专题数据。</p>
        <p class="fiesg">更多信息请参考盟浪网FIN-ESG数据库:</p>
        <p>https://www.susallwave.com/finesg-db/activity/0</p>
      </div>
      <div class="line bot-line"></div>
      <div class="process-box">
        <div class="process-title">
        通过API方式调用数据的基本流程如下：
        </div>
        <div class="doc-process">
          <div class="doc-process-left">
            <div class="boxandtext">申请试用/购买</div>
            <div class="arrow-bot">
              <img
                src="@/assets/productIntro/6-database/arrows-bottom.png"
                alt=""
              />
            </div>
            <div class="boxandtext doc-process-left-two">获取凭证</div>
            <div class="arrow-bot">
              <img
                src="@/assets/productIntro/6-database/arrows-bottom.png"
                alt=""
              />
            </div>
            <div class="boxandtext doc-process-left-three">调用数据</div>
          </div>
          <div class="doc-process-right">
            <div class="doc-process-right-top doc-process-text">
              通过官方联络方式申请开通API权限
            </div>
            <div class="doc-process-right-two doc-process-text">
              由盟浪工作人员开通权限并提供 ACCESS_KEY,ACCSS_SECRET, 用于调用数据
            </div>
            <div class="doc-process-right-three doc-process-text">
              下载代码样例或自行开发程序
            </div>
          </div>
        </div>
        <div class="intro">
          <p>获取到Access_Key, Access_Secret后，就可以使用其来调用目标API，调用API时，您只需下载代码样例，依据API列表的说明修改输入参数后，即可调取需要的数据。</p>
        </div>
      </div>
      <div class="data-info-box">
        <div class="data-title">
          数据信息
        </div>
        <div class="line"></div>
        <div class="intro">
          <p> 更多信息请参考盟浪网FIN-ESG数据库</p>
          <p>https://www.susallwave.com/finesg-db/activity/0</p>
        </div>
        <div>
           <div class="table">
             <el-table
              :data="tableData.tableData"
              style="width: 100%"
              :header-cell-style="{ textAlign: 'left', background:'#287EDE',color: '#FFFFFF'}"
              border
              :span-method="objectSpanMethod"
              :cell-style="cellStyle"
              >
              <el-table-column
                prop="dataName"
                label="数据库名称"
                align="left"
               >
              </el-table-column>
              <el-table-column
                align="left"
                prop="zhTableName"
                label="表中文名"
                min-width="110px"
               >
              </el-table-column>
            </el-table>
           </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const specialTopic="专题数据库"
  import { Table, TableColumn } from 'element-ui'
  import documentSummarize from './api-docsummarize.vue'
  import tableData from './datainfo.js'
  export default {
    components: {
      documentSummarize,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data() {
      return {
        tableData,
        specialTopic
      }
    },
    methods: {
      cellStyle({row, column, rowIndex, columnIndex}){
        // //指定坐标rowIndex ：行，columnIndex ：列
        if(columnIndex === 0 && row.dataName!=specialTopic  ){ 
          return {'background':'#deeeff','color':'#333333'}   
        }else{
          return ''
        }
      },
     objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let data = tableData.tableData; //拿到当前table中数据
      let cellValue = row[column.property]; //当前位置的值
      let noSortArr = ['zhTableName']; //不需要合并的字段（不进行合并行的prop）
      if (cellValue && !noSortArr.includes(column.property)) {
        let prevRow = data[rowIndex - 1]; //获取到上一条数据
        let nextRow = data[rowIndex + 1]; //下一条数据
        if (prevRow && prevRow[column.property] === cellValue) { //当有上一条数据，并且和当前值相等时
          return { rowspan: 0, colspan: 0 };
        } else {
          let countRowspan = 1;
          while (nextRow && nextRow[column.property] === cellValue) { //当有下一条数据并且和当前值相等时,获取新的下一条
            nextRow = data[++countRowspan + rowIndex];
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 };
          }
        }
      }
    },

    },
  }
</script>

<style lang="less" scoped>
  .docsummarize{
    width: 375px;
    padding-top: 12px;
  }
  .new-api-top{
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(68,80,112,0.9);
    line-height: 24px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .intro{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    margin-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    .fiesg{
      margin-top: 5px;
    }
  }
  .process-title{
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 4px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0,0,0,0.9);
    line-height: 24px;
  }
  .doc-process-title {
    height: 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    line-height: 24px;
    padding-left: 16px;
    margin-top: 12px;
}
.doc-process {
  width: 375px;
  height: 253px;
  margin: auto;
  background: #deeeff;
  border-radius: 4px;
  padding-top: 18px;
  padding-left: 16px;
  display: flex;
  margin-top: 7px;
}
.doc-process-left {
  
  .boxandtext {
    width: 110px;
    height: 32px;
    background: #287ede;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  .arrow-bot {
    width: 10px;
    height: 47px;
    margin-left: 48px;
    margin-top: 4px;
    margin-bottom: 6px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.doc-process-right {
  margin-left: 10px;
  margin-top: 3px;
  .doc-process-right-top {
    height: 26px;
  }
  .doc-process-right-two {
    width: 206px;
    height: 78px;
    margin-top: 37px;
  }
  .doc-process-right-three {
    height: 26px;
    margin-top: 37px;
  }
  .doc-process-text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1e446f;
    line-height: 26px;
    padding-right: 10px;
  }
}
.data-info-box{
  .data-title{
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 13px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #445070;
    line-height: 22px;
  }
}
.table{
  width: 358px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 60px;
  /deep/.el-table {
    border-left:1px solid #ffff;
    th {
      background-color: #287ede;
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      td {
        border: none;
      }
    }
    tr:nth-child(even) {
      background: #deeeff;
    }
    td {
      border-right: solid 1px #ffffff;
      border-bottom: solid 1px #ffffff;
    }
    th > .cell {
      font-size: 14px;
    }
  }
  /deep/.el-table--border::after{
    background-color:#ffffff;
  }
  /deep/.el-table tr th:first-child {
    border-left: 0;
  }
}
.line{
  width: 360px;
  height: 2px;
  background-color: #979797;
  margin: auto;
  margin-top: 5px;
}
.bot-line{
  width: 359px;
  height: 1px;
  background-color:#E7E7E7 ;
  margin-left: 16px;
  margin-top: 8px;
 }
</style>