<template>
  <div>
    <div v-if="!logined">
      <button class="banner-btn" @click="showApplicance">
        申请试用义利通
      </button>
    </div>
    <div v-else>
      <button class="banner-btn" v-if="authority === 0" @click="showApplicance">
        申请试用义利通
      </button>
      <button class="banner-btn" v-else @click="showApplicance">
        进入义利通
      </button>
    </div>

    <ProductIntro
      :imageLocation="imageLocation"
      :imgIcon="imgIcon"
      :briefIntro="briefIntro"
      :briefIntroImg="briefIntroImg"
      :productName="productName"
      :authority="authority"
      serviceCode="SERVICE-005"
      ref="productIntro"
      @updateAuthority="updateAuthority"
    />
    <div>
      <div
        v-for="item in imgList"
        :key="item.url"
        class="character-function-detail"
      >
        <img :src="item.url" alt="" />
        <div class="detail-text">
          {{ item.describe }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductIntro from '@/views/components/product-intro/product-intro.vue'
import request from '@/untils/request'
import { Message,} from 'element-ui'
import { mapGetters } from 'vuex'
export default {
  components: {
    ProductIntro,
    [Message.name]: Message,
  },
  data() {
    return {
      imageLocation: 'h5-义利通首页banner',
      imgIcon: require('@/assets/productIntro/2-finesg/m3_1jiantou.png'),
      briefIntro:
        '义利评估报告应用了FIN-ESG模型，将国际ESG标准和中国特色相结合，包括28个行业特色指标，从财务、创新、价值准则、环境、社会和公司治理六个维度，对A股上市公司进行了正面和负面的全方位可持续发展基因检测。',
      briefIntroImg: require('@/assets/productIntro/2-finesg/m3_2img1.jpg'),
      imgList: [
        {
          url: require('@/assets/productIntro/2-finesg/m3_2img2.jpg'),
          describe: '热门报告一键阅读 最新动态极速浏览'
        },
        {
          url: require('@/assets/productIntro/2-finesg/m3_2img3.jpg'),
          describe: '报告列表条件搜索 机构报告精准查询'
        },
        {
          url: require('@/assets/productIntro/2-finesg/m3_2img4.jpg'),
          describe: '在线预览独立下载 数据分析同行对比'
        },
        {
          url: require('@/assets/productIntro/2-finesg/m3_2img5.jpg'),
          describe: '公司内部评论互动 @分析师答疑解惑'
        }
      ],
      productName: '义利通',
      authority: -1,
    }
  },
  computed: {
    ...mapGetters(['logined']),
  },
  methods: {
    updateAuthority (val) {
      this.authority = val
    },
    showApplicance() {
      this.$refs.productIntro.showApplicance()
    },
    // 判断按钮显示文件
    isValid() {
      request
        .post(
          '/ml-member/user/memberuserservice/getUserServiceStatusV2',
          null,
          { params: { serviceCode: 'SERVICE-005' } }
        )
        .then(({ data }) => {
          if(data.code==0){
            this.authority = data.data
          }
        })
    }
  },
  mounted() {
    if (this.logined) {
      this.isValid()
    }
  },
}
</script>

<style lang="less" scoped>
.banner-btn {
  position: absolute;
  background: linear-gradient(90deg, #ffa851, #ff7b4d);
  color: #ffffff;
  top: 190px;
  left: 30px;
  padding: 8px;
  border-radius: 15px;
  width: 135px;
  border: none;
  z-index: 100;
}
img {
  width: 100%;
}
.character-function-detail:nth-child(odd) {
  display: flex;
  position: relative;
  color: #de6554;
}
.character-function-detail:nth-child(even) {
  display: flex;
  position: relative;
  color: #ffffff;
}
.detail-text {
  position: absolute;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  text-align: center;
  line-height: 28px;
  font-weight: 600;
  top: 198px;
  margin-left: 57px;
}
</style>
