<script>
import { Button } from 'element-ui'

export default {
  props: {
    click: {
      type: Function,
      default: () => {},
    },
  },
  data () {
    return {
      loading: false,
    }
  },
  components: {
    Button,
  },
  methods: {
    onClick () {
      this.loading = true;
      this.click().finally(() => {
        this.loading = false;
      })
    }
  },
  render (h) {
    const _this = this;
    const children = Object.keys(this.$slots).map(slot => h('template', { slot }, this.$slots[slot]))
    return h(
      'Button',
      {
        props: { 
          type: "primary", 
          ...this.$attrs, 
          loading: this.loading 
        },
        on: {
          click: this.onClick.bind(_this),
        }
      },
      children
    )
  }
}
</script>