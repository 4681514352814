export default {
	resCode: [
		{
			code: '0',
			state: '成功'
		}, 
		{
			code: '00001',
			state: 'accessKey不存在',
			handle: '请输入正确的accessKey'
		}, 
		{
			code: '00002',
			state: '签名错误',
			handle: '签名规则 ：MD5(ACCESS_KEY+ACCESS_SECRET+ACCESS_TIMESTAMP+ACCESS_RANDOM) '                
		}, 
		{
			code: '00003',
			state: '参数错误',
			handle: '请确保正确的参数格式'                
		}, 
		{
			code: '00004',
			state: 'token不存在，或者过期，请重新登录 ',
			handle: 'token不存在，或者过期，请重新登录 '                
		}, 
		{
			code: '00005',
			state: '时间戳格式错误，必须是yyyyMMddHHmmss ',
			handle: '时间戳格式 例：20210727140559  '
		}, 
		{
			code: '00006',
			state: '过期的请求，请重新请求',
			handle: '时间戳已过期，请重新生成时间戳后请求 '
		}, 
		{
			code: '00007',
			state: '重复的请求',
			handle: '请更换随机数并生成新的签名后重新请求 '
		}, 
		{
			code: '00008',
			state: '参数不能为空',
			handle: '请填写参数'
		},
			{
			code: '00009',
			state: '服务器内部错误',
			handle: '服务器内部错误'
		}, 
		{
			code: '00010',
			state: '登陆信息错误',
			handle: '请填写正确的信息'
		}, 
		{
			code: '00011',
			state:'accessKey已禁用',
			handle: '请联系客服'
		},
			{
			code: '00012',
			state: 'accessKey已过期',
			handle: '请联系客服'
		},
			{
			code: '00013',
			state: '访问次数受限(秒, 分, 时, 天级)',
			handle: '请不要频繁调用'
		},
			{
			code: '00015',
			state: 'api不存在',
			handle: '请联系客服'
		}, 
		{
			code: '00016',
			state: 'api已禁用',
			handle: '请联系客服'
		}, 
		{
			code: '00017',
			state: 'api未授权',
			handle: '请联系客服'
		},
		{
			code: '00018',
			state: '没有默认权限',
			handle: '请联系客服'
		}
	]
}