<template>
    <div>
        <div class="top-left">
            <div class="top-left-img">
                <img :src="imgIcon" alt="">
            </div>
            <div class="top-left-text">
                <span>产品简介</span>
            </div>
        </div>
        <div class="product-intro">
            <span>{{briefIntro}}</span>
        </div>
    </div>
</template>

<script>
export default {
    name:'BriefIntro',
    props:["imgIcon", "briefIntro"],
}
</script>

<style lang="less" scoped>
.top-left{
    display: flex;  
    height: 40px;
    box-shadow: 0 1px 0 0 #D8E2F9;
    margin-top: 10px;
    align-items:center;
    .top-left-img{
        width: 15px;
        height: 11px;
        margin-left: 15px;
        margin-right: 10px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    .top-left-text{
        padding-top:6px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        span{
            margin-top:10px;
        }
      }
}

.product-intro{
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    text-align: justify;
    line-height: 28px;
    font-weight: 400;
    margin: 11px 0 15px 0;

    span{
        width: 91%;
        display: block;
        margin-left:auto; 
        margin-right:auto;
    }

    img{
        display: block;
        width: 91%;
        margin-left:auto; 
        margin-right:auto;
    }
}


</style>
