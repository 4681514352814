<template>
  <div>
    <van-dialog
      class="dialog-bgi"
      v-model="isShow"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <p class="title">提示</p>
      <div class="dialog-content">
        <img
          src="@/assets/productIntro/vip-icon.png"
          alt=""
          class="dialog-img"
        />
        <span
          >本次申请可试用【{{ productName }}】{{
            tryDay
          }}天。</span
        >
      </div>
      <div class="dialog-btn-box">
        <van-button class="submit-btn-cancel" @click="handelCancel"
          >取消</van-button
        >
        <van-button
          class="submit-btn"
          @click="confirmApplication"
          :loading="loading"
          >确认申请</van-button
        >
      </div>
    </van-dialog>
    <van-dialog
      class="dialog-bgi"
      v-model="isShowStatic1"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <p class="title">提示</p>
      <div class="dialog-content">
        <img
          src="@/assets/productIntro/vip-icon.png"
          alt=""
          class="dialog-img"
        />
        <span>{{ this.tip }}</span>
      </div>
      <div class="dialog-btn-box">
        <van-button class="submit-btn-cancel" @click="handelCancel"
          >我知道了</van-button
        >
      </div>
    </van-dialog>
    <van-dialog
      class="dialog-bgi"
      v-model="isShowStatic2"
      :show-cancel-button="false"
      :show-confirm-button="false"
      style="height: 55.333vw; opacity: 0.9"
    >
      <p class="title">提示</p>
      <div class="dialog-content">
        <img
          src="@/assets/productIntro/vip-icon.png"
          alt=""
          class="dialog-img"
        />
        <span>{{ this.tip }}</span>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import request from "@/untils/request"
import { mapGetters } from 'vuex'
import { Message } from 'element-ui'
export default {
  name: 'ProductIntro',
  computed: {
    ...mapGetters(['logined']),
  },
  props: ["productName", "serviceCode", "authority", "isHasApplay"],
  data () {
    return {
      userToken: false,
      isShow: false,
      tryDay: 0,
      isShowStatic1: false,
      isShowStatic2: false,
      loading: false,
      tip: ''
    }
  },
  components: {
    [Message.name]: Message,
  },
  methods: {
    showApplicance () {
      if (this.userToken === false) {
        this.$router.push('/login')
        return
      }
      request.post('/ml-member/user/memberuserservice/serviceList')
        .then(({ data }) => {
          if (data.code == 0) {
            const productItem = data.data
            const Item = productItem.find(item => item.serviceCode === this.serviceCode)
            if (Item.isTryOut) {
              if (this.isHasApplay) {
                this.isShow = false
                this.judgeStatus()
                if (this.authority == 1 || this.authority == 4) {
                  this.isShowStatic2 = true
                  setTimeout(() => {
                    this.isShowStatic2 = false
                  }, 2000)
                } else {
                  this.isShowStatic1 = true
                }
              } else {
                this.isShow = true
                this.getTryDays()
              }

            } else {
              this.judgeStatus()
              if (this.authority == 1 || this.authority == 4) {
                this.isShowStatic2 = true
                setTimeout(() => {
                  this.isShowStatic2 = false
                }, 2000)
              } else {
                this.isShowStatic1 = true
              }
            }
          } else {
            Message({
              type: 'error',
              message: data.msg
            })
          }

        })
    },
    getTryDays () {
      request.post('/ml-member/user/memberuserservice/getTryDays')
        .then(({ data }) => {
          if (data.code === 0) {
            this.tryDay = data.data
          }
        })
    },
    confirmApplication () {
      this.loading = true
      request
        .post('/ml-member/user/memberuserservice/tryOutByServiceCodes', [{ serviceCode: this.serviceCode }])
        .then(({ data }) => {
          if (data.code === 0) {
            this.isValid()
            this.loading = false
            this.isShow = false
            this.$notify({
              message: '恭喜您，申请试用成功!',
              type: 'success'
            })
          }
        }
        )
      this.isShow = false
    },
    handelCancel () {
      this.isShow = false
      this.isShowStatic1 = false
    },
    judgeStatus () {
      if (this.authority == 2) {
        // 购买过期
        this.tip = '产品已到期，建议前往盟浪网www.susallwave.com续费产品。'
      } else if (this.authority == 3) {
        // 试用过期
        this.tip = '试用已到期，建议前往盟浪网www.susallwave.com购买产品。'
      } else if (this.authority == 1 || this.authority == 4) {
        // 购买生效||试用生效
        this.tip = '请前往盟浪网www.susallwave.com使用。'
      } else {
        this.tip = '当前账户该功能暂未授权，建议前往盟浪网www.susallwave.com购买产品。'
      }

    },
    isValid () {
      request
        .post(
          '/ml-member/user/memberuserservice/getUserServiceStatusV2',
          null,
          { params: { serviceCode: this.serviceCode } }
        )
        .then(({ data }) => {
          if (data.code == 0) {
            this.$emit('updateAuthority', data.data)
          }
        })
    },
  },
  mounted () {
    if (this.logined) {
      this.userToken = true
    }
  }
}
</script>

<style lang="less" scoped>
img {
  width: 343px;
  margin: 15px 16px 15px 16px;
}
.dialog-bgi {
  width: 90%;
  height: 290px;
  background-size: cover;

  .dialog-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    img {
      margin: 0 auto;
      width: 100px;
      height: 100px;
    }
    span {
      text-align: center;
      flex-wrap: wrap;
      margin-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      line-height: 24px;
    }
  }

  .title {
    padding: 22px 0 0 22px;
    font-size: 4vw;
    font-family: PingFang SC;
    font-weight: bold;
    color: #3080f1;
    opacity: 1;
  }

  .dialog-btn-box {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .submit-btn-cancel {
      width: 35%;
      // height: 72px;
      background: #fff;
      opacity: 1;
      border-radius: 16px;
      font-size: 16px;
      color: #3080f1;
    }
    .submit-btn {
      margin-left: 20px;
      width: 35%;
      background: #3080f1;
      color: white;
      opacity: 1;
      border-radius: 16px;
      font-size: 16px;
    }
  }
  /deep/ .van_overlay {
    opacity: 0;
  }
}
</style>
