<template>
  <div>
    <div class="noLogim" v-if="!logined" @click="showApplicance">
      <button class="banner-btn">
      申请试用数据通
      </button>
    </div>
    <div v-else>
      <button class="banner-btn"  v-if="authority === 0 || authority === -1 " @click="showApplicance">
        申请试用数据通
      </button>
      <button class="banner-btn"  v-else  @click="showApplicance">
        进入数据通
      </button>
    </div>
    
    <ProductIntro
      :imageLocation="imageLocation"
      :imgIcon="imgIcon"
      :briefIntro="briefIntro"
      :briefIntroImg="briefIntroImg"
      :productName="productName"
      :authority="authority"
      serviceCode="SERVICE-001"
      titleText="产品特点"
      ref="productIntro"
      @updateAuthority="updateAuthority"
    />
    <div class="character-function">
      <div class="top-left">
        <div class="top-left-icon">
          <img
            :src="require('@/assets/productIntro/1-esg-data/m3_1jfangkuai.jpg')"
            alt=""
          />
        </div>
        <div class="top-left-text">
          <span>数据特点</span>
        </div>
      </div>
      <p>指标数量： 一级指标6个，二级指标30+，三级指标90+</p>
      <p>更新频率： 年度更新、半年更新、季度更新</p>
      <p>评估范围： 中证800</p>
      <p>历史长度： 2014年至今</p>
      <img
        :src="require('@/assets/productIntro/1-esg-data/m3_1img2.jpg')"
        alt=""
      />
      <div class="top-left">
        <div class="top-left-icon">
          <img
            :src="require('@/assets/productIntro/1-esg-data/m3_1jfangkuai.jpg')"
            alt=""
          />
        </div>
        <div class="top-left-text">
          <span>功能特色</span>
        </div>
      </div>
      <p>义利榜： 榜单呈现，即时掌握公司排名</p>
      <p>数据浏览： 在线查询，快速呈现评估结果</p>
      <p>指标说明： 工具浮窗，准确了解指标信息</p>
      <p>数据导出： 便捷下载，本地保存查询数据</p>
      <p>API 服务： 批量获取，助力量化投资分析</p>
      <img
        :src="require('@/assets/productIntro/1-esg-data/m3_1img3.jpg')"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import ProductIntro from '@/views/components/product-intro/product-intro.vue'
import request from '@/untils/request'
import { mapGetters } from 'vuex'
export default {
  components: {
    ProductIntro
  },
  data() {
    return {
      imageLocation: 'h5-数据通首页banner',
      imgIcon: require('@/assets/productIntro/1-esg-data/m3_1jiantou.png'),
      briefIntro:
        '数据通产品运用了义利评估模型（FIN-ESG Assessment Model)，延用国际通用ESG（环境、社会和公司治理）评估维度的同时，增加了财务(F)、创新(I)和价值准则(N)三大特色指标，通过在线浏览功能和API接口，以更便捷、更灵活、更安全的方式为客户提供FIN-ESG数据服务，助力对A股上市公司可持续发展风险与机遇的全方位分析。',
      briefIntroImg: require('@/assets/productIntro/1-esg-data/m3_1img1.jpg'),
      productName: '数据通',
      authority: -1,
    }
  },
  
  computed: {
    ...mapGetters(['logined']),
  },
  methods: {
    updateAuthority (val) {
      this.authority = val
    },
    showApplicance() {
      this.$refs.productIntro.showApplicance()
    },
    isValid() {
      request
        .post(
          '/ml-member/user/memberuserservice/getUserServiceStatusV2',
          null,
          { params: { serviceCode: 'SERVICE-001' } }
        )
        .then(({ data }) => {
          if(data.code==0){
            this.authority = data.data
          }
        })
    }
  },
  mounted() {
    if (this.logined) {
      this.isValid()
    }
  },
}
</script>

<style lang="less" scoped>
.banner-btn {
  position: absolute;
  background: linear-gradient(90deg, #ffdb9c, #fa7f7e);
  color: #ffffff;
  top: 170px;
  left: 30px;
  padding: 8px;
  border-radius: 15px;
  width: 135px;
  border: none;
  z-index: 100;
}
img {
  width: 100%;
}
.character-function {
  width: 343px;
  margin-left: 16px;
  p {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    text-align: justify;
    line-height: 28px;
    font-weight: 400;
  }
}

.top-left {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
  .top-left-icon {
    margin-right: 10px;
    img {
      width: 100%;
      height: 10px;
    }
  }
  .top-left-text {
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    color: #333333;
    letter-spacing: 1px;
    font-weight: 600;
  }
}
</style>
