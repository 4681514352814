<template>
  <div>
    <!-- api概述 -->
    <div class="doc-top">
      <h1 class="top">{{ apiDocSummarize.section.firstSection.title }}</h1>
      <div class="bot">
        {{ apiDocSummarize.section.firstSection.firstP }}
      </div>
    </div>
    <div class="doc-process-title">
      {{ apiDocSummarize.section.firstSection.secondP }}
    </div>
    <div class="doc-process">
      <div class="doc-process-left">
        <div class="boxandtext">申请试用/购买</div>
        <div class="arrow-bot">
          <img
            src="@/assets/productIntro/6-database/arrows-bottom.png"
            alt=""
          />
        </div>
        <div class="boxandtext doc-process-left-two">获取凭证</div>
        <div class="arrow-bot">
          <img
            src="@/assets/productIntro/6-database/arrows-bottom.png"
            alt=""
          />
        </div>
        <div class="boxandtext doc-process-left-three">调用数据</div>
      </div>
      <div class="doc-process-right">
        <div class="doc-process-right-top doc-process-text">
          通过官方联络方式申请开通API权限
        </div>
        <div class="doc-process-right-two doc-process-text">
          由盟浪工作人员开通权限并提供 ACCESS_KEY,ACCSS_SECRET, 用于调用数据
        </div>
        <div class="doc-process-right-three doc-process-text">
          下载代码样例或自行开发程序据
        </div>
      </div>
    </div>

    <div class="foot">
      <div class="foot-title">
        <h1>{{ apiDocSummarize.section.secondSection.title }}</h1>
      </div>
      <div class="foot-content">
        <p>
          {{ apiDocSummarize.section.secondSection.firstP }}
        </p>
        <p>
          {{ apiDocSummarize.section.secondSection.secondP }}
        </p>
        <p>
          {{ apiDocSummarize.section.secondSection.threeP }}
        </p>
        <p>
          {{ apiDocSummarize.section.secondSection.fourP }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import apiDocSummarize from '#susallwave-base/domain/finesg/data/apidoc-summarize.js'
export default {
  name:'apiDocCodeExample',
  data () {
    return {
      apiDocSummarize
    }
  },
}
</script>

<style lang="less" scoped>
.doc-top {
  width: 375px;
  background: #ffffff;
  padding-top: 12px;
  padding-left: 16px;
  padding-right: 16px;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  .top {
    height: 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    line-height: 24px;
  }
  .bot {
    // height: 110px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    margin-top: 4px;
  }
}
.doc-process-title {
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;
  padding-left: 16px;
  margin-top: 12px;
}
.doc-process {
  width: 375px;
  height: 253px;
  background: #deeeff;
  border-radius: 4px;
  padding-top: 18px;
  padding-left: 16px;
  display: flex;
  margin-top: 7px;
}
.doc-process-left {
  .boxandtext {
    width: 110px;
    height: 32px;
    background: #287ede;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  .arrow-bot {
    width: 10px;
    height: 47px;
    margin-left: 48px;
    margin-top: 4px;
    margin-bottom: 6px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.doc-process-right {
  margin-left: 10px;
  margin-top: 3px;
  .doc-process-right-top {
    height: 26px;
  }
  .doc-process-right-two {
    width: 206px;
    height: 78px;
    margin-top: 37px;
  }
  .doc-process-right-three {
    height: 26px;
    margin-top: 37px;
  }
  .doc-process-text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1e446f;
    line-height: 26px;
  }
}
.foot {
  width: 343px;
  margin: auto;
  border-bottom: 1px solid #ededed;
  margin-bottom: 61px;
  .foot-title {
    height: 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    line-height: 24px;
    margin-top: 12px;
  }
  .foot-content {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    margin-top: 4px;
    padding-bottom: 15px;
  }
}
</style>