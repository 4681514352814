<template>
<section>
  <el-dialog
    :visible="visible"
    width="80%"
    :before-close="close"
    append-to-body
    class="dialog-modal-company-property"
    :close-on-click-modal ="false"
  >
    <template name="title">
      <div class="title-con">
        <span class="bl"/>
        <span>提示</span>
      </div>
    </template>
    <div class="text-tip">请完善账号资料，以便为您提供更好的服务</div>
    <h1 class="my-h1">企业属性</h1>
    <el-select :value="uc.model.value" @change="v => uc.model.value = v" width="100%">
      <el-option
        v-for="(item) in uc.model.options"
        :key="item.dictValue"
        :label="item.dictLabel"
        :value="item.dictValue"
      ></el-option>
    </el-select>
    <span slot="footer" class="dialog-footer">
      <a class="go-sw" @click="submit">{{ submitBtnText || '进入盟浪网' }}</a>
    </span>
  </el-dialog>
</section>
</template>

<script>
import { Dialog } from 'element-ui'
import { Select, Option } from 'element-ui'
import { selectCompanyProperty } from '../uc/member.uc';

export default {
  props: ['visible', 'close', 'submitBtnText','companyProperty'],
  data() {
    return {
      dialogVisible: false,
      uc: new selectCompanyProperty(),
    };
  },
  mounted () {
    if(this.companyProperty){
      this.uc.model.value=this.companyProperty
    }
  },
  components: {
    'el-select': Select,
    'el-option': Option,
    ['el-dialog']: Dialog
  },
  methods: {
    submit() {
      return this.uc.submit().then(() => this.close && this.close())
    }
  }
};
</script>

<style scoped lang="less">
.text-tip{
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  line-height: 20px;
  margin-bottom: 20px;
}
.bl{
  display: inline-block;
  width: 4px;
  height: 20px;
  background: #3080F1;
  border-radius: 2px;
  margin-right: 10px;
  font-weight: 600;
}
.title-con{
  display: flex;
  align-items: center;
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 25px;
  margin-bottom: 30px;
  span{
    display: inline-block;
  }
}
.bot{
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: #fff;
  width: 100vw;
  height: 100vh;
  margin-bottom: 20px;
}
section{
  &>h1{
    font-size: 16px;
    font-weight: 400;
    color: #2F3234;
    line-height: 22px;
  }
}
.go-sw{
  margin: 0 auto;
  width: 144px;
  height: 35px;
  background: #417DFF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 22px;
}
/deep/.el-dialog__header{
  padding: 0 20px;
}
/deep/.el-dialog__body{
  padding-top: 23px;
  padding: 20px 16px;
}
.my-h1{
  margin-bottom: 5px;
}
/deep/.el-select{
  width: 100%;
}
</style>