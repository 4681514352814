<template>
  <div class="iframe-box" >
    <span class="text" v-if="innerHtml" v-html="modifyHtmlStr(this.iframeCon)"></span>
    <iframe
      v-else
      ref="iframe"
      border="0"
      class="news-html"
      :src="iframeSrc"
      frameborder="0"
      onload="this.height=this.contentWindow.document.body.scrollHeight+80"
    ></iframe>
  </div>
</template>
<script>
export default {
  props: {
    iframeCon: {
      type: String,
      default: ''
    },
    innerHtml: {
      type: Boolean,
      default: ''
    },
  },
  data() {
    return {}
  },
  computed: {
    iframeSrc() {
      if (this.iframeCon) {
        const css = `table, th, td{
            border: 1px solid #ccc;
            border-collapse: collapse;
            padding: 5px;
          }
          img{
            max-width: 100%;
          }`
        const html = `<html><head><style>${css}</style></head><body>${this.iframeCon}</body></html>`
        return this.toBolbUrl(html)
      }
    }
  },
  methods: {
    modifyHtmlStr(html) {
      return html.replace(/<img/g, '<img referrerPolicy="no-referrer" ')
    },
    // 转换富文本内容为blob url
    toBolbUrl(val) {
      if (val) {
        let valb = new Blob([val],{
          type: 'text/html'
        })
        return window.URL.createObjectURL(valb)
      } 
      return ''
    },
  }
}
</script>
<style lang="less" scoped>
.iframe-box {
  width: 100%;
  overflow: hidden;
  .news-html {
    width: 100%;
    padding: 30px 0 40px 0;
    overflow: auto;
  }
 /deep/.text{
    img{  
      max-width: 100%;
      height:auto;
    }
    p span {
      display: block;
      word-break:break-all
    }
  }
}
/* table 样式 */
table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}
table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
/deep/ blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
pre code {
  display: block;
}

/* ul ol 样式 */
ul,
ol {
  margin: 10px 0 10px 20px;
}
/deep/ p{ 
  font-family: PingFangSC-Regular;
  text-indent: 0em !important;
  font-size: 16px;
  color: #333333 !important;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0px;
}
/deep/ span p{ 
  font-family: PingFangSC-Regular;
  text-indent: 0em !important;
  font-size: 16px;
  color: #333333 !important;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 400;
  text-indent: 0em !important;
}
/deep/ section p{
  font-family: PingFangSC-Regular;
  text-indent: 0em !important;
  font-size: 16px;
  color: #333333 !important;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0px;
}
</style>
