<template>
  <el-form 
    v-if="form" :model="form" 
    class="login-byphone" 
    :rules="form.rules"
    hide-required-asterisk
    ref="form"
  >
    <el-select 
      :value="form.areaCode.value" placeholder="+86" 
      @change="i => form.areaCode.value = i"
      :class="{empty: !form.areaCode.value}"
    >
      <el-option
        v-for="(item, index) in form.areaCode.list"
        :key="index"
        :label="item.countryNameZh"
        :value="item.areaCode"
      ></el-option>
    </el-select>
    <el-form-item prop="mobile">
      <el-input
        v-model.trim="form.mobile"
        placeholder="请输入手机号"
        type="number"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="图片验证码" prop="captcha">
      <div class="cap-con">
        <el-input
          v-model="form.captcha"
          autocomplete="off"
          placeholder="请输入图片验证码"
        >
        </el-input>
        <img
          class="cap"
          :src="Api.baseUrl + Api.prefix + form.captchaImgUrl"
          @click="() => form.updateCaptchaImg()"
        >
      </div>
    </el-form-item>
    <el-form-item label="验证码" prop="verCode">
      <div class="cap-con">
        <el-input
          v-model.trim="form.verCode"
          autocomplete="off"
          placeholder="请输入验证码"
          type="number"
        />
        <AsyncButton
          class="code-btn"
          :click="() => uc.showCaptchaDialog(form).then(()=>{},(err)=>{
            open(err)
          })"
          :disabled="form.codeDisabled"
        >
          <span v-if="form.codeShow">获取验证码</span>
          <span v-if="!form.codeShow" style="color: #676767">剩余 {{ form.count }} 秒</span>
        </AsyncButton>
      </div>
    </el-form-item>
  </el-form>

</template>

<script>
import { Form, FormItem, Input, Select, Option, Button,Message } from 'element-ui'
import AsyncButton from '../../../share/vue/async-button.vue'
import Api from '../../../core/api'

export default {
  props: ['uc',],
  data(){ return { Api }},
  computed: {
    form() {
      return this.uc.form
    },
  },
  components: {
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-select': Select,
    'el-option': Option,
    'el-button': Button,
    [Message.name]: Message,
    AsyncButton,
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    open(err){
      Message({
        type:err.type,
        message:err.message,
        center:true,
        showClose:false,
        iconClass: 'login-phone',
        customClass: err.type=="error"?'el-message--error login-phone':(err.type=="warning"?"el-message--warning login-phone":(err.type=="success"?'el-message--success login-phone':''))
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './style/form.less';
/deep/ .el-select.empty>.el-input{
  width: 30%;
}
/deep/ .el-select>.el-input{
  margin-bottom: 8px;
  &>input{
    height: 20px;
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    border: none;
  }
}
.cap{
  height: 32px;
}


</style>
<style lang="less">
.login-phone{
  top: 0 !important;
}
.login-phone:not(:last-child){
  visibility: hidden !important;
}
</style>