<template>
  <div v-if="newsTabs.consultNewsTabList">
    <van-tabs v-model="active"   @change="onTabsChange" line-width="68px" line-height="2px" title-active-color="#417DFF" color="#417DFF">
      <van-tab v-for="item in newsTabs.consultNewsTabList" :key="item.id" :title="item.coulmnName">
        <consultNewList :coulmnId="item.id"  v-if="item.id" class="consult-newlist"/>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// import consultNewList from './list.vue'
import consultNewList from './newList.vue'
import {ConsultingNews} from '../../../entity/consulting'
  export default {
    components: {
      consultNewList,
    },
    data() {
      return {
        updataIndex:null,
        active: 0,
        ConsultingNews,
        newsTabs:new ConsultingNews(),
        coulmnIdIndex:null,//路由coulmnId对应的suoyin
      }
    },
    created () {		
      this.newsTabs.getTabs().then(()=>{
        this.coulmnIdIndex=this.$route.query.coulmnId
        if(this.coulmnIdIndex&&this.newsTabs.consultNewsTabList){
          this.newsTabs.consultNewsTabList.forEach((item,index)=>{
            if(item.id== this.coulmnIdIndex){
              this.active=index
              this.newsTabs.getTabindexAcquirecoulmnId(this.active)
            }
          })
        }
      })
    },
    methods: {
     onTabsChange(){
        this.newsTabs.getTabindexAcquirecoulmnId(this.active)
     },
    },
  }
</script>

<style lang="less" scoped>
  /deep/.van-tab__text--ellipsis{
    height: 100px;
    line-height: 100px;
  }
  /deep/ .van-tab__text {
    font-family: PingFangSC-Medium;
    font-size: 16px;
    letter-spacing: 0.89px;
    font-weight: 500;
  }

</style>