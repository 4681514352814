<template>
  <div class="content">
    <!-- <van-button type="primary" text="显示遮罩层" @click="show = true" /> -->
    <form class="search-top" action="/">
      <el-select :popper-append-to-body="false"  class="select-top" v-model="value" @visible-change="selChange" @change="getDataList" filterable placeholder="请选择">
        <el-option v-for="item in industryList" :key="item.code" :label="item.name" :value="item.code"></el-option>
      </el-select>
    </form>
    <div v-if="tableData.length>0" class="result-content">
      <van-overlay :z-index="999" :show="show" @click="show = false" />
      <div class="enterprise-box">
        <div class="box-title">
          <span class="title-icon">
            序号
          </span>
          <span class="title-name">
            企业名称
          </span>
        </div>
        <div class="industry-box">
          <div class="box-content" v-for="item,index in tableData" :key="index">
            <span class="content-icon">
              {{ index + 1 }}
            </span>
            <span class="content-row">
              <span :class="item.greenStat == '90001' ? 'green' : (item.greenStat == '90003' ? 'may-green' : (item.greenStat == '90002' ? 'no-green' : 'no-know'))">
                <!-- 绿色 -->
                {{item.greenStat == '90001' ? '绿色' : (item.greenStat == '90003' ? '可能绿' : (item.greenStat == '90002' ? '非绿' : '未判别'))}}
              </span>
              <span class="row-name">
                {{item.companyName}}
              </span>
            </span>
          </div>
        </div>
        <div class="link-box" @click="toLinkMsg">
          共查询到 {{total}} 条数据，获取更多 <img src="../../../assets/cfa/link-to.svg" alt="">
        </div>
      </div>
      <div class="description"> 
        <p class="description-title">
          <span class="text-title">
            说明
          </span>
          <span class="bgc-title">
          </span>
        </p>
        <p class="introduce">
          <!-- 数据统计显示标记为“绿色”和“可能绿”企业数量。 -->
        </p>
        <div class="explain-icon">
          <p class="explain-icon-item">
            <span class="line-center">
              <span class="item-green-icon">
              </span>
            </span>
            <span class="item-green-title">
              绿色
            </span>
            <span class="vertical-align">
              企业有绿色经营活动，符合《绿色产业指导目录（2019年版）》有关要求。
            </span>
          </p>
          <p class="explain-icon-item">
            <span class="line-center">
              <span class="item-may-green-icon">
              </span>
            </span>
            <span class="item-green-title">
              可能绿
            </span>
            <span class="vertical-align">
              企业有潜力进行绿色金融融资活动。
            </span>
          </p>
          <p class="explain-icon-item">
            <span class="line-center">
              <span class="item-no-green-icon">
              </span>
            </span>
            <span class="item-green-title">
              非绿
            </span>
            <span class="vertical-align">
              企业所在行业存在高碳排放风险或企业经营活动非绿色减排，不符合绿色金融融资评价要求。
            </span>
          </p>
          <p class="explain-icon-item">
            <span class="line-center">
              <span class="item-unable-to-judge-icon">
              </span>
            </span>
            <span class="item-green-title">
              未判别
            </span>
            <span class="vertical-align">
              企业部分信息缺失，不符合判断条件。
            </span>
          </p>
        </div>
      </div>
      <div class="statement">
        免责声明： <br>
        盟浪碳融通平台绿色企业识别仅供参考，数据来源以企业公开信息为主。
      </div>
    </div>
    <div v-else class="result-content data-null">
      <van-overlay :z-index="999" :show="show" @click="show = false" />
      <img src="../../../assets/cfa/bgi-cfa-search.png" alt="">
      <p class="data-null-msg">
        输入信息，免费获取智能识别结果！
      </p>
    </div>
  </div>
</template>

<script>
import { Search,Toast,Popup,Picker,Field,Overlay } from "vant";
import { Select,Option } from 'element-ui'
import request from '@/untils/request'
import {getSearchData} from '#domain-cfa/cfa-tabs.js';
  export default {
    components: {
      [Search.name]: Search,
      [Popup.name]: Popup,
      [Picker.name]: Picker,
      [Field.name]: Field,
      [Select.name]: Select,
      [Option.name]: Option,
      [Overlay.name]: Overlay,
    },
    data() {
      return {
        value: '',
        getSearchData,
        isShowAll: false,
        isShowAllBusinessScope: false,
        value: '',
        columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
        showPicker: false,
        show: false,
        industryList: [],
        tableData: [],
        total: 0
      }
    },
    created () {		
      request.post('/ml-carfinance/cf/green/map/categories').then(res=> {
        this.industryList = res.data.data.bizData
      })
    },
    methods: {
      toLinkMsg() {
        this.$router.push('/cfa-connect/components/message')
      },
      onConfirm(value) {
        this.value = value;
        this.showPicker = false;
      },
      selChange (val) {
        this.show = val
      },
      getDataList(val) {
        let data = {
          page: this.page,
          limit: this.limit,
          value: val,
          field: 'industryCode',
          operator: 'like'
        }
        request.post('/ml-carfinance/cf/green/geiQuery',data).then(res=> {
          this.tableData = res.data.data.bizData.pageObject.list.slice(0,5)
          this.total = Number(res.data.data.bizData.pageObject.total)
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  .search-btn {
    width: 60px;
    height: 28px;
    text-align: center;
    background: #417DFF;
    border-radius: 4px;
    border: none;
    color: #fff;
    line-height: 28px;
    margin-top: 5px;
  }
  .result-content {
    min-height: 600px;
    height: 100%;
    width: 100%;
    background: #f4f4f4;
    padding: 20px 16px;
    .enterprise-box {
      width: 343px;
      background: #FFFFFF;
      border-radius: 6px;
      padding: 10px 12px;
      .link-box {
        height: 26px;
        background: #E9F0FF;
        border-radius: 4px;
        border: 1px solid #417DFF;
        margin-left: 70px;
        color: #417DFFFF;
        text-align: center;
        font-size: 12px;
        line-height: 26px;
        margin: 30px auto;
        margin-bottom: 20px;
        img {
          vertical-align: text-top;
          padding-top: 2px;
        }
      }
      .box-title {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        .title-icon {
          margin-right: 25px;
        }
      }
      .box-content {
        margin-top: 20px;
        .content-row {
          span {
            display: inline-block;
            line-height: 21px;
            margin-right: 10px;
            vertical-align: top;
          }
          .green {
            color: #0EB37CFF;
            width: 44px;
            height: 20px;
            background: rgba(15,212,146,0.2400);
            border-radius: 4px;
            font-size: 12px;
            text-align: center;
          }
          .may-green {
            color: #09A3C2FF;
            width: 44px;
            height: 20px;
            background: rgba(15,179,212,0.2100);
            border-radius: 4px;
            text-align: center;
            font-size: 12px;
          }
          .no-green {
            color: #245FD4FF;
            width: 44px;
            height: 20px;
            background: rgba(36,95,212,0.2500);
            border-radius: 4px;
            text-align: center;
            font-size: 12px;
          }
          .no-know {
            width: 44px;
            height: 20px;
            background: #E2E5EA;
            border-radius: 4px;
            color: #858585FF;
            text-align: center;
            font-size: 12px;
          }
        }
        .content-icon {
          display: inline-block;
          font-size: 14px;
          font-family: YouSheBiaoTiHei;
          color: #333333;
          vertical-align: middle;
          margin-right: 40px;
        }
        .row-name {
          width: 200px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          vertical-align: bottom;
        }
      }
    }
    .description {
      margin-top: 15px;
      width: 343px;
      min-height: 331px;
      background: #FFFFFF;
      padding: 10px 12px;
      border-radius: 6px;
      .description-title {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        position: relative;
        .bgc-title {
          display: block;
          position: absolute;
          bottom: 4px;
          width: 58px;
          height: 6px;
          background: linear-gradient(90deg, #417DFF 0%, rgba(255,255,255,0) 100%);
          opacity: 0.4;
        }
      }
      .introduce {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-bottom: 20px;
      }
      .explain-icon {
        line-height: 20px;
        .explain-icon-item {
          margin-bottom: 20px;
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 16px;
          border-bottom: 1px solid #F7F7F7FF;
          .item-green-title {
            display: inline-block;
            vertical-align: middle;
            width: 50px;
            color: #333333;
            font-size: 14px;
            margin-right: 15px;
          }
          .vertical-align {
            display: inline-block;
            vertical-align: middle;
            color: #333333;
            font-size: 14px;
            max-width: 75%;
          }
        }
      }
      .item-green-icon {
        display: inline-block;
        position: relative;
        width: 5px;
        height: 5px;
        background: #0EB37C;
        border-radius: 50%;
        margin-right: 5px;
        margin-top: 3px;
      }
      .item-may-green-icon {
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #09A3C2FF;
        border-radius: 50%;
        margin-right: 5px;
        margin-top: 3px;
      }
      .item-no-green-icon {
        display: inline-block;
        width: 5px;
        height: 5px;
        margin-right: 5px;
        border-radius: 50%;
        margin-top: 3px;
        background: #245FD4FF;
      }
      .item-unable-to-judge-icon {
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #858585FF;
        border-radius: 50%;
        margin-right: 5px;
        margin-top: 3px;
      }
    }
  }
  .data-null {
    text-align: center;
    padding-top: 100px;
    .data-null-msg {
      text-align: center;
      margin-top: 23px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  .search-top {
    display: flex;
    padding: 10px 16px;
    justify-content: space-between;
  }
  .statement {
    width: 322px;
    height: 51px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
    margin-top: 16px;
    margin-left: 10px;
  }
  /deep/.van-overlay {
    top: 152px;
  }

  .select-top  {
    width: 100%;
  }
  /deep/.el-select-dropdown {
    border-radius: 0px !important;
    box-shadow: 0 0 0 0 rgb(0 0 0 / 0%) !important;
    -webkit-box-shadow: 0 0 0 0 rgb(0 0 0 / 0%) !important;
    top: 35px !important;
    left: -17px !important;
    min-width: 110% !important;
    border-top: none;
    max-height: 400px;
    overflow: scroll;
  }
  /deep/.el-select-dropdown__wrap {
    max-height: 100%;
  }
  /deep/.popper__arrow {
    display: none !important;
  }
  .line-center {
    line-height: 12px;
  }
</style>