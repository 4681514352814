import { Message } from 'element-ui';

let staticFetcher;

export default class Api {
  constructor(http) {
    this.http = http || staticFetcher;
  }
  async get (...args) {
    this.requestArgs = args;
    return this.http.get(...args).then(res => this.handleResponse(res));
  }

  async post (...args) {
    this.requestArgs = args;
    return this.http.post(...args).then(res => this.handleResponse(res));
  }

  async plainPost (...args) {
    return this.http.post(...args)
  }

  async put (...args) {
    this.requestArgs = args;
    return this.http.put(...args).then(res => this.handleResponse(res));
  }

  async delete (...args) {
    this.requestArgs = args;
    return this.http.delete(...args).then(res => this.handleResponse(res));
  }

  handleResponse(res) {
    if (this.success(res)) {
      return this.withoutResWrapper(res);
    } else {
      return this.handelFail(res);
    }
  }

  success(res) {
    return res.data && res.data.code === 0;
  }

  withoutResWrapper(res) {
    return res.data.data;
  }

  handelFail(res) {
    console.error(res)
    return Promise.reject(res)
  }

  handleSuccess(operation) {
    return operation.then((response) => {
      Message.success('操作成功');
      return response;
    })
  }
}
export const setStaticFetcher = (fetcher, options) => {
  staticFetcher = fetcher
  if (options) {
    Api.baseUrl = options.baseUrl
    Api.prefix = '/api'
  }
}
