<template>
  <div v-if="listItem" class="news-box">
    <div class="news-box-item" @click="goDetails(listItem)">
      <div class="news-box-item-left">
        <img :src="listItem.backgroundUrl" alt="">
      </div>
      <div class="news-box-item-right">
        <div class="news-box-item-right-top">
          {{listItem.title}}
        </div>
        <div class="news-box-item-right-bottom">
          <div class="news-box-item-right-bottom-title"> 
            {{listItem.source}}
          </div>
          <div  class="news-box-item-right-bottom-time">
            {{String(listItem.reviewerDate).substring(0,10)}}
          </div>
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    methods: {
      goDetails(item){
        this.$router.push({
          path:'/consulting-details',
          query:{
            id:item.id,
            coulmnId:item.coulmnId,
            home:1
          }
        })
      }
    },
    props: {
      listItem: {
        type: Object,
        default: ()=>({})
      }
    },
  }
</script>

<style lang="less" scoped>
  .news-box-item{
    width: 327px;
    height: 105px;
    margin:auto;
    padding-top: 13px;
    display: flex;
    border-bottom: 1px solid #dadada;
    .news-box-item-left{
      width: 120px;
      height: 81px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .news-box-item-right{
      width: 207px;
      height: 81px;
      margin-left: 15px;
      .news-box-item-right-top{
        width: 100%;
        height: 64px;
        margin-bottom: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        line-height: 21px;
        font-weight: 400;
      }
      .news-box-item-right-bottom{
        width: 207px;
        display: flex;
        justify-content: space-between;
        height: 17px;
        line-height: 17px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #999999;
        letter-spacing: 0.67px;
        font-weight: 400;
        .news-box-item-right-bottom-title{
          width: 60%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .news-box:nth-child(1){
    border-top: 1px solid #dadada;
  }

</style>