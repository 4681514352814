import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/plugins/vantui.js";
import "./style/reset.less";
import "vant/lib/index.less";
import "./assets/font/font.less";
import config from "../config/index";
import request from '@/untils/request'
import VConsole from 'vconsole';
if(process.env.NODE_ENV=='development'){
  const vConsole = new VConsole();
  Vue.use(vConsole)
}
import { setStaticFetcher as setStaticFetcherForBase } from '#susallwave-base/core/api'
setStaticFetcherForBase(request, { baseUrl: process.env.VUE_APP_URL })
Vue.config.productionTip = false;
Vue.prototype.config = config;
Vue.prototype.$bus = new Vue()
if(process.env.VUE_APP_BAIDU_STATISTICS){
  var _hmt = _hmt || [];
  window._hmt =_hmt;
  (function () {
    var hm = document.createElement("script");
    hm.src = process.env.VUE_APP_BAIDU_STATISTICS;
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
  //  百度统计 VUE每个页面的访问量
  router.beforeEach((to, from, next)=> {
    if(_hmt){
      if(to.path){
        _hmt.push(['_trackPageview', to.fullPath])
      }
    }
    next()
  })
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");