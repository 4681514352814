import Api from '../../core/api'

export class HomeUpdataConStudyIdea {
  static esgType =[
    {
      label:"金融机构ESG视界",
      firstType:'1',
      id:1
    },
    {
      label:"上市企业ESG视界",
      firstType:'2',
      id:2
    },
   ]
  static menuList =[
    {
      label:"资讯",
      id:'1',
      url:'/ml-product/index/news/getNewsPage',
      goPath:'/consulting-news',
      goDetailsPath:'/consulting-news/news-detail',
      getTabListUrl:""
    },
    {
      label:"政策",
      id:'4',
      url:"ml-product/sys/policyNews/homePage",
      goPath:'/policy-unscramble',
      goDetailsPath:'/policy-unscramble/policy-detail',
      getTabListUrl:"/ml-product/sys/policyColumn/getList",
      getPolicyListUrl:'/ml-product/sys/policyNews/pageList'
    },
    {
      label:"研究",
      id:'2',
      url:'/ml-product/index/researchreport/homePage',
      goPath:'/research-insight',
      goDetailsPath:'/research-insight/report-detail',
      getTabListUrl:""
    },
    {
      label:"先锋",
      id:'3',
      url:'/ml-product/index/dk/homePageDakaNewsList',
      goPath:'/big-shot-esg',
      goDetailsPath:'/big-shot-esg/details',
      getTabListUrl:""
    },
    {
      label:"培训",
      id:'5',
      url:'/ml-product/index/researchreport/homePage',
      goPath:'/train-research',
      goDetailsPath:'/train-research/train-details',
      getTabListUrl:"/ml-product/index/researchreport/getList",
      getCultivateList:"/ml-product/index/researchreport/page"
    },
  ]
  static studyInsightApiUrl='/ml-product/index/researchreport/homePage'//语义化
  static ideaResearchTrain="/ml-product/index/researchreport/page"
  static ideaLeaderApiUrl='/ml-product/index/dk/homePageDakaNewsList'//意见领袖
  static policyId='4'
  static trainId='5'
  constructor(url,dataList,firstObj,path,exhibitionId,esgMarkedList,esgMarkfirstObj,detailsPath,listUrl,tabUrl,argumentsType,trainArguments,consultNewsList){
    this.dataList=dataList //数据列表
    this.url=url //接口需要的url
    this.firstObj=firstObj //数据中的第一个数据
    this.path=path //点击查看更多的path路径
    this.exhibitionId=exhibitionId
    this.esgMarkedList=esgMarkedList // 上市公司Esg的列表
    this.esgMarkfirstObj=esgMarkfirstObj // 上市公司Esg的列表中的第一个对象
    this.detailsPath=detailsPath //点击查看详情的url
    this.listUrl=listUrl //进入列表页,获取列表数据的url
    this.tabUrl=tabUrl //进入列表页后 获取tab导航栏的url
    this.argumentsType=argumentsType
    this.trainArguments=trainArguments
    this.consultNewsList=consultNewsList //单独资讯的列表
  }
  //根据不同的参数获取列表的方法 可读性
  // 
  getList(urlId,esgId,page,limit){
    return new Api().post(this.byIdAcquireUrl(urlId),{
      firstType:this.byIdAcquireFirstType(esgId),
      type:this.argumentsType|| null,
      kind:this.trainArguments|| null,
      page:page||1,
      limit:limit||10,
      sort: (this.byIdAcquireUrl(urlId)== HomeUpdataConStudyIdea.studyInsightApiUrl || this.byIdAcquireUrl(urlId)== HomeUpdataConStudyIdea.ideaResearchTrain)?1:null,
      isRecommended:this.byIdAcquireUrl(urlId)== HomeUpdataConStudyIdea.studyInsightApiUrl?2:null,
      orderField: this.byIdAcquireUrl(urlId)== HomeUpdataConStudyIdea.ideaLeaderApiUrl?'reviewer_date':null
    }).then((res)=>{
     
      this.consultNewsList= res.list || []
      if(esgId==HomeUpdataConStudyIdea.esgType[0].id){
        this.dataList  = res.list || []
        if (this.dataList !== 0) {
          this.firstObj = {...res.list[0]}
        } else {
          this.firstObj={}
        }
      } else {
        this.esgMarkedList=[]
          this.esgMarkedList= res.list || []
          if (this.esgMarkedList !== 0) {
            this.esgMarkfirstObj ={... res.list[0]}
          } else{
            this.esgMarkfirstObj={}
          }
      }
    })
  }
  //根据用户点击的不同的id 获取不同的url this
  byIdAcquireUrl(urlId){
    HomeUpdataConStudyIdea.menuList.forEach((item)=>{
      if(item.id==urlId){
        this.url=item.url
      }
    })
    return this.url
  }
  //根据id 找esg不同的参数
  byIdAcquireFirstType(esgId){
    HomeUpdataConStudyIdea.esgType.forEach((item)=>{
      if(item.id==esgId){
        this.firstType=item.firstType
      }
    })
    return  this.firstType
  }
  //根据id去跳到查看更多
  byIdGoMore($router,id){
    HomeUpdataConStudyIdea.menuList.forEach((item)=>{
      if(item.id==id){
        this.path=item.goPath
      }
    })
    return $router.push(this.path)
  }
  init(urlId,esgId){
    return this.getList(urlId,esgId,1,10)
  }
  //点击查看更多
  getMore($router,id){
    return  this.byIdGoMore($router,id)
  }
  //点击不同的tab获取不同的数据
  clickDifferentType(urlId,esgId){
    if( this.byIdAcquireUrl(urlId)==HomeUpdataConStudyIdea.ideaLeaderApiUrl){
      return  this.getList(urlId,esgId)
    } else{
      return  this.getList(urlId,esgId,1,10)
    }
  }
  //根据不同的id 跳详情页面并上传获取详情的url 
  byIdAcquireGopath($router,typeId,detailsId){
    HomeUpdataConStudyIdea.menuList.forEach((item)=>{
      if(item.id==typeId){
        this.detailsPath=item.goDetailsPath
      }
    })
    return  $router.push({
      path:`${this.detailsPath}/${detailsId}`,
    })
  }
  //根据id获取政策的参数id
  byIdAcquireArgumerntsType(id){
    if(id==HomeUpdataConStudyIdea.policyId ){
      this.argumentsType="1"
    } else {
      this.argumentsType=null
    }
    if(id==HomeUpdataConStudyIdea.trainId){
      this.trainArguments="2"
    }else {
      this.trainArguments="1"
    }
  }
}

