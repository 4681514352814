<template>
  <div>
    <div v-if="!logined">
      <button class="banner-btn"  @click="showApplicance">
        申请试用信披通
      </button>
    </div>
    <div v-else>
      <button class="banner-btn" v-if="authority === 0" @click="showApplicance">
        申请试用信披通
      </button>
      <button class="banner-btn" v-else @click="showApplicance">
        进入信披通
      </button>
    </div>
    <ProductIntro
      :imageLocation="imageLocation"
      :imgIcon="imgIcon"
      :briefIntro="briefIntro"
      :productName="productName"
      :authority="authority"
      serviceCode="SERVICE-003"
      ref="productIntro"
      @updateAuthority="updateAuthority"
    />
    <div>
      <div
        v-for="item in imgList"
        :key="item.url"
        class="character-function-detail"
      >
        <img :src="item.url" alt="" />
        <div class="detail-text">{{ item.describe }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductIntro from '@/views/components/product-intro/product-intro.vue'
import request from '@/untils/request'
import { mapGetters } from 'vuex'
export default {
  components: {
    ProductIntro
  },
  computed: {
    ...mapGetters(['logined']),
  },
  data() {
    return {
      imageLocation: 'h5-信披通首页banner',
      imgIcon: require('@/assets/productIntro/4-sustainability/m3_4jiantou.png'),
      briefIntro:
        '信披通是由盟浪独立设计研发的非财务信息报告智能自助编写平台，采用GRI可持续发展报告标准、社科院CSR等全球领先披露框架以及沪、深、港交易所披露指南，融合国内市场政策法律，提供行业信披实践参考，助力公司及机构以合规、高效、便捷的方式编写非财务信息报告。',
      imgList: [
        {
          url: require('@/assets/productIntro/4-sustainability/m3_4img1.jpg'),
          describe: '热门报告一键阅读 最新动态极速浏览'
        },
        {
          url: require('@/assets/productIntro/4-sustainability/m3_4img2.jpg'),
          describe: '热门报告一键阅读 数据沉淀历年对比'
        },
        {
          url: require('@/assets/productIntro/4-sustainability/m3_4img3.jpg'),
          describe: '报告全程线上制作 工作全局进度把控'
        },
        {
          url: require('@/assets/productIntro/4-sustainability/m3_4img4.jpg'),
          describe: '提供详细使用教程 同业实践360°参考'
        },
        {
          url: require('@/assets/productIntro/4-sustainability/m3_4img5.jpg'),
          describe: '专业客服在线咨询 降本增效实现信披'
        }
      ],
      productName: '信披通',
      authority: -1,
    }
  },
  methods: {
    updateAuthority (val) {
      this.authority = val
    },
    showApplicance() {
      this.$refs.productIntro.showApplicance()
    },
    isValid() {
      request
        .post(
          '/ml-member/user/memberuserservice/getUserServiceStatusV2',
          null,
          { params: { serviceCode: 'SERVICE-003' } }
        )
        .then(({ data }) => {
          this.authority = data.data
        })
    }
  },
  mounted() {
    if (this.logined) {
      this.isValid()
    }
  },
}
</script>

<style lang="less" scoped>
.banner-btn {
  position: absolute;
  background: linear-gradient(90deg, #ffa851, #ff7b4d);
  color: #ffffff;
  top: 170px;
  left: 30px;
  padding: 8px;
  border-radius: 15px;
  width: 135px;
  border: none;
  z-index: 100;
}

img {
  width: 100%;
}

.character-function-detail:nth-child(odd) {
  display: flex;
  position: relative;
  color: #19ad93;
}
.character-function-detail:nth-child(even) {
  display: flex;
  position: relative;
  color: #ffffff;
}
.detail-text {
  position: absolute;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
  top: 198px;
  margin-left: 57px;
}
</style>
