import ApiMember from '../data/member.api';
import { InputFormOfVerifyByPhone } from './form.uc';
import { ResetNewPwd, InputVerifyUserByEmailForm } from './member.uc';

export function Verify(type) {
  switch(type) {
    case Verify.byPhone:
      return new VerifyByPhone();
    case Verify.byEmail:
      return new VerifyByEmail();
    default:
      console.error('Verify: unknow type')
  }
}
Verify.byPhone = '0'
Verify.byEmail = '1'

class VerifyByPhone extends InputFormOfVerifyByPhone{
  title = '手机验证'
  verify() {
    return new ApiMember().verifyUserByPhone(this.form.loginOrRegistrySubmition).then(null,(res)=>{
      const errCode=10057
      if(res.data.code==errCode){
        this.form.updateCaptchaImg()
        this.form.captcha=""
        this.form.verCode=""
      }
    })
  }
}

class VerifyByEmail extends InputVerifyUserByEmailForm{
  title = '邮箱'
  verify() {
    return new ApiMember().verifyUserByEmail(this.form)
  }
}

export default class ResetPwd{
  static STEP_VERF = '0';
  static SETP_RESET = '1';
  steps = [
    { title: '安全验证', code: ResetPwd.STEP_VERF },
    { title: '重置密码', code: ResetPwd.SETP_RESET },
  ]
  ways = new Map([
    [Verify.byPhone, new Verify(Verify.byPhone)],
    [Verify.byEmail, new Verify(Verify.byEmail)]
  ])

  step = ResetPwd.STEP_VERF
  currentVerifyType = Verify.byPhone
  byPhone = this.ways.get(Verify.byPhone)
  byEmail = this.ways.get(Verify.byEmail)
  resetNewPwd = new ResetNewPwd()

  get currentVerifyForm() {
    return this.currentVerify.form
  }

  getVerifyWay(code) { return this.ways.get(code)} 

  verify() {
    return this.currentVerify.verify().then(({ id }) => {
      this.step = ResetPwd.SETP_RESET;
      this.verifyId = id
    })
  }

  goFinishVerify() {
    return this.step = ResetPwd.SETP_RESET
  }

  submitResetPwd(hasLogin) {
    return this.resetNewPwd.submit(this.verifyId, hasLogin);
  }

  get currentVerify() {
    return this.ways.get(this.currentVerifyType)
  }
}