<template>
  <div class="user">
    <div class="user-top">
      <div class="user-top-logo">
        <img src="../../assets/logo/head_logo.png" alt="">
      </div>
      <div class="user-info">
        <div class="user-info-icon">
          <img src="../../assets/user/top-user.png" alt="">
        </div>
        <div class="user-info-info">
          {{user!=null && user.userName ?user.userName:'用户昵称'}} 
        </div>
      </div>
    </div>
    <!-- 下面的具体信息 -->
    <div class="user-bottom">
      <div class="user-bottom-top">
        <div class="user-bottom-top-left">
          <div class="user-bottom-top-left-img">
            <img src="../../assets/user/bottom-user.png" alt="">
          </div>
        </div>
        <div class="user-bottom-top-right">
          <div class="user-bottom-top-right-name">
            {{ user.userName }}
          </div>
          <div class="user-bottom-top-right-iphone">
            <div class="user-bottom-top-right-iphone-icon"> 
               <img src="../../assets/user/phone.png" alt="">
            </div>
            <div class="user-bottom-top-right-iphone-num">
              {{user.mobile}}
            </div>
          </div>
          <div class="user-bottom-top-right-nature">
            <div class="user-bottom-top-right-nature-box">
              <div class="user-bottom-top-right-nature-icon">
                <img src="../../assets/user/nature.png" alt="">
              </div>
              <div class="user-bottom-top-right-nature-name">
                {{user.enterpriseAttributes}}
              </div>
            </div>
            <div class="user-bottom-top-right-nature-edit" @click="goEditCompanyProperty">
              <div class="user-bottom-top-right-nature-edit-icon">
                <img src="../../assets/user/edit.png" alt="">
              </div>
              <div class="user-bottom-top-right-nature-edit-text">
                修改
              </div>
            </div> 
          </div>
        </div>
      </div>
      <div class="user-bottom-bottom" @click="goEditPassWord">
        修改登录密码
      </div>
    </div>
    <div class="exit" @click="exitLogin">
      <div class="exit-icon">
        <img src="../../assets/user/exit.png" alt="">
      </div>
      <div class="exit-text">
        退出登录
      </div>
    </div>
    <ModalCompanyProperty :visible="isShowlCompanyProperty" :submitBtnText="submitBtnText" :companyProperty="user.enterpriseAttributes" :close="close" class="companyProperty"/>
  </div>
</template>

<script>
import ApiMember from "../../../../susallwave-base/domain/member/data/admin.api"
import { selectCompanyProperty } from '../../../../susallwave-base/domain/member/uc/member.uc.js';
import ModalCompanyProperty from '../../../../susallwave-base/domain/member/view/modal-company-property'
import { mapActions,mapState } from "vuex"; 
  export default {
    computed: {
      ...mapState([
        'user','tokenInfo'
      ])
    },
    data() {
      return {
        dataList:[],
        ApiMember,
        isShowlCompanyProperty:false,
        submitBtnText:'确定修改',
        uc: new selectCompanyProperty(),
        companyProperties:[]
      }
    },
    watch: {
      user: { 
        handler(newVal, oldVal){
          if(newVal){
            this.getCompanypropertyName()
          }
        },
        deep:true 
      }
    },
    mounted () {
      setTimeout(()=>{
        this.getCompanypropertyName()
      },200)
    this.isLogin().then((login) => {
      if (!login) {
        this.$router.push('/login')
      }
    })
    },
    components: {
      ModalCompanyProperty,
    },
    methods: {
      ...mapActions([
        'isLogin',
        'logout',
         'getUserInfo'
      ]),
      exitLogin(){
        if(this.user!=null){
          this.logout().then(() => {
            this.$router.push('/')
          })
        }
      },
      goEditCompanyProperty(){
        //打开弹窗
        this.isShowlCompanyProperty=true
      },
      goEditPassWord(){
        this.$router.push('/reset-pwd')
      },
      close(){
        if (this.tokenInfo && this.tokenInfo.token) {
          this.getUserInfo().then(()=>{
            this.isShowlCompanyProperty=false
          })
        } else {
          this.isShowlCompanyProperty=false
        }
      },
      getCompanypropertyName(){
        this.uc.model.options.forEach((item)=>{
          if(item.dictValue==this.user.enterpriseAttributes){
            this.user.enterpriseAttributes=item.dictLabel
            }
          })
        return this.user.enterpriseAttributes
      }
    },
  }
</script>

<style lang="less" scoped>
.user {
   min-height: 100vh;
   position: relative;
}
  .user-top{
    display: flex;
    justify-content: space-between;
    padding-left: 17px;
    padding-right: 16px;
    margin-top: 8px;
    .user-top-logo{
      width: 63px;
      height: 35px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .user-info{
      display: flex;
      align-items: center;
      justify-content: center;
      .user-info-icon{
        width: 20px;
        height: 20px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .user-info-info{
        height: 20px;
        line-height: 20px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #333333;
        letter-spacing: 0.44px;
        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .user-bottom{
    width: 343px;
    height: 196px;
    margin:auto;
    margin-top: 17px;
    background-image: linear-gradient(137deg, #FFFFFF 0%, #D4D8DE 100%);
    box-shadow: inset 0 1px 3px 0 #FFFFFF;
    border-radius: 10px;
    .user-bottom-top{
      display: flex;
      justify-content: space-around;
      width: 343px;
      height: 146px;
      .user-bottom-top-left{
        width: 72px;
        height: 96px;
        margin-top: 38px;
        .user-bottom-top-left-img{
          width: 72px;
          height: 72px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .user-bottom-top-right{
        width: 187px;
        margin-top: 26px;
        .user-bottom-top-right-name{
          font-family: PingFangSC-Semibold;
          font-size: 18px;
          color: #333333;
          line-height: 20px;
          font-weight: 600;
          margin-bottom: 16px;
        }
        .user-bottom-top-right-iphone{
          width: 100%;
          height: 24px;
          display: flex;
          .user-bottom-top-right-iphone-icon{
            width: 24px;
            height: 24px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .user-bottom-top-right-iphone-num{
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #585858;
            letter-spacing: 0;
            line-height: 24px;
            font-weight: 400;
            margin-left: 10px;
          }

        }
        .user-bottom-top-right-nature{
          margin-top: 12px;
          display: flex;
          justify-content: space-between;
          .user-bottom-top-right-nature-box{
            display: flex;
            justify-content: space-between;
            .user-bottom-top-right-nature-icon{
              width: 24px;
              height: 24px;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .user-bottom-top-right-nature-name{
              font-family: PingFangSC-Regular;
              font-size: 14px;
              color: #666666;
              letter-spacing: 0.78px;
              font-weight: 400;
              line-height: 24px;
              margin-left: 10px;
            }
          }
          .user-bottom-top-right-nature-edit{
            display: flex;
            justify-content: center;
            align-items: center;
            .user-bottom-top-right-nature-edit-icon{
              img{
                width: 12px;
                height: 12px;
              }
            }
            .user-bottom-top-right-nature-edit-text{
              margin-left: 5px;
              font-family: PingFangSC-Regular;
              font-size: 12px;
              color: #2E7DF4;
              font-weight: 400;
            }
          }
        }
      }
    }
    .user-bottom-bottom{
      width: 343px;
      height: 50px;
      line-height: 50px;
      background-image: linear-gradient(269deg, #3C5999 0%, #3080F1 100%);
      font-family: PingFangSC-Semibold;
      font-size: 18px;
      color: #FFFFFF;
      font-weight: 600;
      text-align: center;
      border-bottom-left-radius:8px ;
      border-bottom-right-radius:8px ;
    }
  }
  .exit {
    position: fixed;
    left:50%;
    transform: translateX(-50%);
    bottom:10%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    .exit-icon{
      width: 16px;
      height: 15px;
      margin-top: 3px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .exit-text{
      margin-left: 5px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #2E7DF4;
      text-align: center;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .companyProperty{
    /deep/.el-dialog{
      width: 310px !important;
    }
  }
</style>