<template>
  <div>
    <div class="list">
      <div class="list-item">
        <div class="list-item-box" @click="goToNewsDetail(news)">
          <div class="list-item-title" :title="news.title">
            {{ news.title }}
          </div>
          <!-- 企业 -->
          <div class="company">
            <div class="company-img">
              <img
                src="../../../assets/productIntro/3-risktracker/company.png"
                alt=""
              />
            </div>
            <div class="company-name-box">
              <div
                class="company-name"
                v-for="(itemCompany, index) in news.newsPrincipalPart"
                :key="index"
              >
                {{ itemCompany }}
              </div>
            </div>
          </div>
          <!-- 风险类型 -->
          <div class="risk" v-if="!isCollection">
            <div class="risk-icon">
              <img
                src="../../../assets/productIntro/3-risktracker/risk.png"
                alt=""
              />
            </div>
            <div class="risk-type">
              <div
                class="risk-type-item"
                v-for="(itemRiskType, index) in news.risks"
                :key="index"
              >
                <div class="risk-type-item-icon">
                  <img
                    src="../../../assets/productIntro/3-risktracker/F.png"
                    alt=""
                    v-if="itemRiskType.riskType == riskTypeF"
                  />
                  <img
                    src="../../../assets/productIntro/3-risktracker/E.png"
                    alt=""
                    v-if="itemRiskType.riskType == riskTypeE"
                  />
                  <img
                    src="../../../assets/productIntro/3-risktracker/S.png"
                    alt=""
                    v-if="itemRiskType.riskType == riskTypeS"
                  />
                  <img
                    src="../../../assets/productIntro/3-risktracker/g.png"
                    alt=""
                    v-if="itemRiskType.riskType == riskTypeG"
                  />
                </div>
                <div class="risk-type-item-text">
                  {{ itemRiskType.riskName }}
                </div>
              </div>
            </div>
          </div>
          <!-- 来源 -->
          <div class="source" :class="isCollection == true ? 'mb' : ''">
            <div class="source-icon">
              <img
                src="../../../assets/productIntro/3-risktracker/source.png"
                alt=""
              />
            </div>
            <div class="source-text">
              <span>{{ news.source }}</span> |
              <span>{{ news.releaseTime }}</span>
            </div>
          </div>
          <!-- 风险等级 -->
          <div class="risk-grade" v-if="!isCollection">
            <div class="risk-grade-icon">
              <img
                src="../../../assets/productIntro/3-risktracker/riskgrade.png"
                alt=""
              />
            </div>
            <div
              class="risk-grade-icon-img"
              v-for="item in Number(news.analystRatings)"
              :key="item"
            >
              <img
                src="../../../assets/productIntro/3-risktracker/baolei.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const riskTypeF = "F"
const riskTypeE = "E"
const riskTypeS = "S"
const riskTypeG = "G"
export default {
  props: {
    newsList: {
      type: Array,
      default: () => ([])
    },
    news: {
      type: Object,
      default: () => ({})
    },
    noRiskType: {
      type: Boolean,
      default: false
    },
    isCollection: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      riskTypeF,
      riskTypeE,
      riskTypeS,
      riskTypeG
    }
  },
  methods: {
    goToNewsDetail (row) {
      window.open(row.sourceUrl, '_blank')
    },
  },
}
</script>

<style lang="less" scoped>
.list {
  .list-item {
    .list-item-box {
      width: 375px;
      // min-height: 173px;
      height: auto;
      padding-left: 27px;
      overflow: hidden;
      background: linear-gradient(
        360deg,
        rgba(250, 251, 255, 0.8) 0%,
        #e8eeff 100%
      );
      .list-item-title {
        width: 325px;
        height: 24px;
        font-size: 18px;
        margin-bottom: 18px;
        margin-top: 22px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #25324b;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .company {
        display: flex;
        margin-bottom: 8px;
        .company-img {
          width: 13px;
          height: 14px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .company-name-box {
          width: 329px;
          display: flex;
          flex-wrap: wrap;
          .company-name {
            margin-left: 10px;
            height: 25px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 17px;
          }
        }
      }
      .risk {
        display: flex;
        .risk-icon {
          width: 14px;
          height: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .risk-type {
          width: 325px;
          display: flex;
          flex-wrap: wrap;
          margin-left: 10px;
          .risk-type-item {
            display: flex;
            align-items: center;
            margin-right: 17px;
            .risk-type-item-icon {
              width: 13px;
              height: 17px;
              margin-right: 3px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .risk-type-item-text {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              display: flex;
              align-items: center;
            }
          }
        }
      }
      .source {
        margin-top: 8px;
        display: flex;
        .source-icon {
          width: 13px;
          height: 14px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .source-text {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
          margin-left: 10px;
        }
      }
      .risk-grade {
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 22px;
        .risk-grade-icon {
          width: 13px;
          height: 11px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .risk-grade-icon-img {
          width: 13px;
          height: 14px;
          margin-left: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.mb {
  margin-bottom: 19px;
}
</style>