<template>
  <div v-if="menus" class="menu-box">
    <van-tabs v-model="active" color="#0052D9">
      <div v-for="item in menus" :key="item.id">
        <van-tab :title="item.title" v-if="item.id != documentSummarizeType">
          <documentSummarize v-if="active == documentSummarizeType" />
          <doCodeExample v-if="active == doCodeExampleType" />
          <statusCode v-if="active == statusCodeType" />
        </van-tab>

        <van-tab v-else>
          <template #title>
            <div class="apimenu">
              <van-dropdown-menu active-color="#0052D9">
                <van-dropdown-item :title="droupMenuTitle" ref="apilist">
                  <div class="cell">
                    <van-cell
                      center
                      v-for="(item, index) in apiListArr"
                      :key="item.id"
                      @click="goDetaisl(item, index)"
                    >
                      <template>
                        <div
                          class="text"
                          :class="
                            item.isDoc == false
                              ? 'title-text'
                              : activiteIndex == index
                              ? 'color-text'
                              : ''
                          "
                        >
                          {{ item.title }}
                        </div>
                      </template>
                    </van-cell>
                  </div>
                </van-dropdown-item>
              </van-dropdown-menu>
            </div>
          </template>
          <apiList v-if="active == apiListType" :detailsTable="detailsTable" />
        </van-tab>
      </div>
    </van-tabs>
  </div>
</template>

<script>
const documentSummarizeType = 0
const apiListType = 1
const doCodeExampleType = 2
const statusCodeType = 3
const jsonDeatilId = "0-3-0"
import documentSummarize from './api-docsummarize.vue'
import doCodeExample from './api-doc-code-example.vue'
import statusCode from './api-status-code.vue'
import apiList from './api-list.vue'
import axios from 'axios'

export default {
  provide () {
    return {
      tables: this
    }
  },
  data () {
    return {
      jsonDeatilId,
      documentSummarizeType,
      apiListType,
      doCodeExampleType,
      statusCodeType,
      active: 0,
      allApiList: [],//只有api的是个树
      menus: [],//nav
      apiListArr: [],//是个数组
      tagName: '',
      droupMenuTitle: "API列表",
      detailsTable: {},
      activiteIndex: 1
    }
  },
  components: {
    documentSummarize,
    doCodeExample,
    statusCode,
    apiList,
  },
  mounted () {
    this.getApiList()
    this.fetchDocDetail(String(jsonDeatilId))
  },
  methods: {
    treeToArray (tree) {
      const obj = []
      tree.forEach((item) => {
        if (item.children) {
          obj.push(item, ...item.children)
          // ES6新增的 删除对象的属性 Reflect.deleteProperty(对象，属性名)
          Reflect.deleteProperty(item, 'children')
          this.treeToArray(obj)
        } else {
          obj.push(item)
        }
      })
      return obj
    },
    goDetaisl (val, index) {
      if (val.isDoc) {
        this.$refs.apilist[0].toggle() //vant 内置方法关闭遮罩
        this.activiteIndex = index
        this.fetchDocDetail(val.id)
      }
    },
    getApiList () {
      axios.get('/doc-finesg-db/apis.json').then((res) => {
        this.menus = res.data
        if (this.menus) {
          this.menus.forEach((item) => {
            if (item.id == documentSummarizeType) {
              this.allApiList = item.children
            }
          })
          this.apiListArr = this.treeToArray(this.allApiList)
        }
      })
    },
    fetchDocDetail (id) {
      axios.get(`/doc-finesg-db/details/${id}.json`).then(r => {
        this.enhanceDocDetail(r.data)
        this.detailsTable = r.data
        return this.detailsTable
      })
    },
    enhanceDocDetail (doc) {
      if (doc) {
        doc.sections.forEach(section => {
          const mapWidths = new Map([
            ['通用数据', [221]],
            ['输入参数', [221, 80, 120, 110, 80]],
          ])
          if (mapWidths.has(section.title)) {
            section.content.columns.forEach((c, i) => {
              c.width = mapWidths.get(section.title)[i]
            })
          }
        })
      }
      return doc
    }
  },
}
</script>

<style lang="less" scoped>
.menu-box {
  /deep/.van-dropdown-menu__title--active::after {
    content: '';
  }
  /deep/.van-tab--active {
    color: #0052d9;
  }
  /deep/.van-tabs__line {
    width: 65px;
    bottom: 5vw;
    z-index: 2029;
  }
}
.apimenu {
  /deep/.van-dropdown-menu {
    width: 91px;
  }
}
.text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.title-text {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333 !important;
  line-height: 20px;
}
.cell {
  padding-left: 12px;
  max-height: 400px;
}
.color-text {
  color: #0052d9;
}
</style>