<template>
  <div class="body-home">
    <Header />
    <Swiper :imageLocation="imageLocation"/>
    <ConsutingNews class="consuting-news"/>
    <HomeEsg/>
    <div class="bottom-line">已经到底啦</div>
  </div>
</template>

<script>
import Header from './components/head/index.vue'
import Swiper from "./components/Swipe/swipe.vue"
import ConsutingNews from './consulting/homeList/index.vue'
import {HomeUpdataConStudyIdea} from "#domain-news/entitiy-homeupadata-consult-study-idea.js"
import HomeEsg from './components/home-esg/index'
// import Header from "@/views/components/header.vue";
export default {
  components: {
    Header,
    Swiper,
    ConsutingNews,
    HomeEsg
  },
  data() {
    return {
      imageLocation:"h5-首页banner",
      data: new HomeUpdataConStudyIdea()
    }
  },
  mounted () {
  },
}
</script>

<style lang="less" scoped>
.consuting-news{
  margin-top: 7px;
}

.bottom-line{
  height:60px;
  padding-top:21px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #666666;
  letter-spacing: 0;
  font-weight: 400;
  text-align: center;
}
</style>
