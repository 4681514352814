<template>
  <div>
    <div class="home-esg">
      <div class="home-esg-top">
         <div class="home-esg-top-png">
           <img src="../../../assets/home/esg-arrows.png" alt="">
         </div>
         <div class="home-esg-top-text">
          <span>ESG产品</span>
         </div>
      </div>

      <div class="home-esg-item">
        <div  v-for="(item, index) in imgList" :key="index" class="esg-product" @click="$router.push(item.link)">
          <img :src="item.url"/>
          <div class="crown"></div>
          <div class="r-point" v-if="false"></div>
          <div class="esg-title">{{item.title}}</div>
          <div class="esg-describe">{{item.describe}}</div>
          <div class="cube">立即体验</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        imgList:[
          {url:require('@/assets/home/cna.png'),link:'/activity-page',title:'盟浪碳融通',describe:'绿色金融综合服务平台'},
          {url:require('@/assets/home/sustainability-reporter.png'),link:'/database-connect',title:'FIN-ESG数据库',describe:'全A股覆盖  9年ESG数据沉淀'},
          // {url:require('@/assets/home/esg-data.png'),link:'/data-connect',title:'盟浪数据通',describe:'企业可持续发展价值分析平台'},
          // {url:require('@/assets/home/sustainability-reporter.png'),link:'/sustainability-connect',title:'盟浪信披通',describe:'ESG可持续发展报告编写平台'},
          // {url:require('@/assets/home/riskTracker.png'),link:'/risktracker-connect',title:'盟浪避雷通',describe:'企业ESG风险评估预警平台'},
          // {url:require('@/assets/home/finesgAssessor.png'),link:'/finesg-connect',title:'盟浪义利通',describe:'企业ESG价值评估报告平台'},
        ]
      }
    }
    
  }
</script>

<style lang="less" scoped>
  .home-esg{
    .home-esg-top{
      display:flex;
      align-content: center;
      width: 375px;
      height: 40px;
      margin-top: 15px;
      padding-top: 10px;
      box-shadow: 0 1px 0 0 #D8E2F9;
      .home-esg-top-png{
        width: 15px;
        height: 10px;
        margin-left: 15px;
        margin-right: 10px;
        img{
          width:100%;
          height: 100%;
        }

      .home-esg-top-text{
        font-family: PingFangSC-Semibold;
        font-size: 18px;
        color: #333333;
        letter-spacing: 1px;
        font-weight: 600;
      }
        
      }
    }
  }

  .home-esg-item{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20.33px;
    width:100%;
    .esg-product{
      margin:auto;
      margin-top:11px;
      position:relative;
      width:91%;
      img{
        width: 100%;
        height: auto;
      }
      .crown{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height:100%;
        background: no-repeat url('@/assets/home/crown.png');
      }
      .esg-title{
        position:absolute;
        top:17px;
        left:24px;
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #FFFFFF;
        letter-spacing: 1px;
        font-weight: 500;
      }
      .r-point {
        position:absolute;
        top:23px;
        left:65px;
        width: 3px;      
        height: 3px;     
        border: 1px solid #FFFFFF;      
        border-radius: 100px;      
        background-color: #FFFFFF;
    }
    .esg-describe{
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #FFFFFF;
      letter-spacing: 0.67px;
      font-weight: 400;
      position: absolute;
      top:46px;
      left:25px;
    }
    .cube{
      width: 75px;
      height:30px;
      border-radius: 4px;
      border: 1px solid #FFFFFF;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      text-align: center;
      position:absolute;
      top:25px;
      left:246px;
    }
  }
}
</style>