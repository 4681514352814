<template>
  <div>
    <div class="card-box">
      <div class="card" v-for="(item, index) in accessType" :key="index">
        <div class="item-card">
          <div
            class="item-card-title"
            :class="
              item.type == I
                ? 'bgci'
                : item.type == N
                ? 'bgcN'
                : item.type == E
                ? 'bgcE'
                : item.type == S
                ? 'bgcS'
                : item.type == G
                ? 'bgcG'
                : ''
            "
          >
            {{ item.title }}
          </div>
          <div
            class="item-cart-content-box"
            :class="
              item.type == I
                ? 'bgcIcontent'
                : item.type == N
                ? 'bgcNcontent'
                : item.type == E
                ? 'bgcEcontent'
                : item.type == S
                ? 'bgcScontent'
                : item.type == G
                ? 'bgcGcontent'
                : ''
            "
          >
            <div
              class="item-cart-content"
              v-for="itemDesc in item.content"
              :key="itemDesc.id"
            >
              <div class="item-cart-img">
                <img
                  src="@/assets/productIntro/6-database/F.png"
                  alt=""
                  v-if="item.type == F"
                />
                <img
                  src="@/assets/productIntro/6-database/I.png"
                  alt=""
                  v-if="item.type == I"
                />
                <img
                  src="@/assets/productIntro/6-database/N.png"
                  alt=""
                  v-if="item.type == N"
                />
                <img
                  src="@/assets/productIntro/6-database/E.png"
                  alt=""
                  v-if="item.type == E"
                />
                <img
                  src="@/assets/productIntro/6-database/S.png"
                  alt=""
                  v-if="item.type == S"
                />
                <img
                  src="@/assets/productIntro/6-database/G.png"
                  alt=""
                  v-if="item.type == G"
                />
              </div>
              <div
                class="item-cart-desc"
                :class="
                  item.type == I
                    ? 'text_i'
                    : item.type == N
                    ? 'text_n'
                    : item.type == E
                    ? 'text_e'
                    : item.type == S
                    ? 'text_s'
                    : item.type == G
                    ? 'text_g'
                    : ''
                "
              >
                {{ itemDesc.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const F = "F"
const I = "I"
const N = "N"
const E = "E"
const S = "S"
const G = "G"

export default {
  props: {
    accessType: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      F,
      I,
      N,
      E,
      S,
      G
    }
  },

}
</script>

<style lang="less" scoped>
.card-box {
  width: 343px;
  margin: auto;
  margin-top: 10px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.item-card {
  width: 108px;
  height: 125px;
  background: #f9efea;
  margin-bottom: 8px;
}
.item-card-title {
  width: 108px;
  height: 25px;
  background: #c46e4a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.item-cart-content-box {
  width: 108px;
  height: 100px;
  background: #f9efea;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 13px;
  .item-cart-content {
    height: 17px;
    display: flex;
    align-items:flex-start;
    margin-bottom: 4px;
    .item-cart-img {
      width: 11px;
      height: 11px;
      display: flex;
      align-items:flex-start;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-cart-desc {
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c46e4a;
      line-height: 17px;
      margin-left: 3px;
      display: flex;
      align-items: center;
    }
  }
}

.item-cart-content-box .item-cart-content:last-child{
  display: flex;
  align-items: flex-start;
  .item-cart-desc{
    padding-bottom: 6px;
  }
}
.bgci {
  background: #8c9173;
}
.bgcN {
  background: #178f99;
}
.bgcE {
  background: #91b233;
}
.bgcS {
  background: #36a9c7;
}
.bgcG {
  background: #d8b53c;
}
.bgcIcontent {
  background-color: #f3f4f0;
}
.bgcNcontent {
  background: #e2efef;
}
.bgcEcontent {
  background: #f3f8e7;
}
.bgcScontent {
  background: #e9faff;
}
.bgcGcontent {
  background: #f9f5e6;
}
.text_i {
  color: #8c9173 !important;
}
.text_n {
  color: #178f99 !important;
}
.text_e {
  color: #91b233 !important;
}
.text_s {
  color: #36a9c7 !important;
}
.text_g {
  color: #d8b53c !important;
}
</style>