<template>
  <div>
    <div class="pageContainer">
      <div class="pagesInner">
        <div class="page-prev" @click="reducePage">
          <img
            src="../../../assets/productIntro/3-risktracker/left-arrows.png"
            alt=""
          />
        </div>
        <div class="page-pager">
          <div
            class="pager-one"
            :class="isShow ? 'item-content-nobgc' : 'item-content'"
            @click="homePage"
          >
            <span>{{ 1 }}</span>
          </div>
          <div class="pager-one item-content-division">
            <span>/</span>
          </div>
          <div
            class="pager item-content"
            v-if="isShow && resetEndbgc == false && currentPage > 1"
          >
            <span>{{ currentPage }}</span>
          </div>
          <div
            class="pager-one item-content-division"
            v-if="isShow && resetEndbgc == false && currentPage > 1"
          >
            <span>/</span>
          </div>
          <div
            class="pager-last"
            :class="resetEndbgc ? 'item-content-end' : 'item-content-end-nobgc'"
            @click="endPage"
          >
            <span v-if="isFakeTotal">{{fakeTotal}}</span>
            <span v-else>{{ Math.ceil(total / pageSize) }}</span>
          </div>
        </div>
        <div class="page-next" @click="addPage">
          <img
            src="../../../assets/productIntro/3-risktracker/right-arrows.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 5
    },
    disAdd:{
      type:Boolean,
      default:false
    },
    isFakeTotal:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      page: 1,
      isDisabledHome: false,
      isDisabledEnd: false,
      resetEndbgc: false,
      fakeTotal:null
    }
  },
  watch: {
    page (newV, oldV) {
      if(this.isFakeTotal){
        if (newV == this.fakeTotal) {
          this.resetEndbgc = true
        } else {
          this.resetEndbgc = false
        }
      } else {
        if (newV == Math.ceil(this.total / this.pageSize)) {
          this.resetEndbgc = true
        } else {
          this.resetEndbgc = false
        }
      }

      this.$emit('changePage', newV)
    },
  },
  computed: {
    isShow () {
      if (this.page > 1) {
        return true
      } else {
        return false
      }
    },
  },
  mounted () {
    this.page = this.currentPage
    if(this.isFakeTotal){
      this.fakeTotal=10
    }
  },

  methods: {
    addPage () {
      if(this.isFakeTotal){
        if(this.page == this.fakeTotal){
          this.isDisabledEnd = true
          return
        }else {
          this.isDisabledEnd = false
          if(this.disAdd){
           return 
         } else {
          this.page += 1
         }
        }
      } else {
        if (this.page == Math.ceil(this.total / this.pageSize)) {
          this.isDisabledEnd = true
          return
        } else {
          this.isDisabledEnd = false
          if(this.disAdd){
           return 
         } else {
          this.page += 1
         }
      }
      }

    },
    reducePage () {
      if (Number(this.page) == 1) {
        this.isDisabledHome = true
        return
      } else {
        this.isDisabledHome = false
        this.page = this.page - 1
      }
    },
    homePage () {
      this.page = 1
    },
    endPage () {
      if(this.isFakeTotal){
        this.page=this.fakeTotal
      } else {
        this.page = Math.ceil(this.total / this.pageSize)
      }
    }
  },
}
</script>

<style lang="less" scoped>
.page-pager {
  margin-left: 37px;
  margin-right: 37px;
  display: flex;
}
.pageContainer {
  text-align: center;
  .pagesInner {
    display: flex;
    justify-content: center;
  }
}
.item-content {
  display: block;
  padding: 5px 10px;
  background: #f2f3f5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  margin-left: 7px;
}
.item-content-nobgc {
  display: block;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  margin-left: 7px;
}
.item-content-end-nobgc {
  display: block;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  margin-left: 7px;
}
.item-content-end {
  display: block;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  background: #f2f3f5;
  margin-left: 7px;
}
.item-content-division {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-prev {
  width: 30px;
  height: 30px;
  img {
    width: 100%;
    height: 100%;
  }
}
.page-next {
  width: 30px;
  height: 30px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>