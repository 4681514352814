<template>
  <div class="activity">
    <div>
      <Header />
      <div class="banner">
        <div class="applay" @click="jump('signUp')">
          <div class="applay-text">立即报名</div>
          <div class="applay-icon">
            <img src="@/assets/activity/nine-activity/white-apply.png" alt="" />
          </div>
        </div>
      </div>

      <Swiper :imageLocation="imageLocation" v-if="false" />
    </div>
    <!-- 免费体验 -->
    <div class="free-experience">
      <div class="free-experience-small-title">
        <div class="desc-title">
          <div class="desc-left">
            <img src="@/assets/activity/nine-activity/img_left.png" alt="" />
          </div>
          <div class="desc-text">新品限时免费体验</div>
          <div class="desc-right">
            <img src="@/assets/activity/nine-activity/img_right.png" alt="" />
          </div>
        </div>
      </div>
      <div class="free-experience-big-title">盟浪碳融通</div>
      <div class="free-experience-big-title-line">
        <div class="free-experience-big-title-desc">
          绿色金融综合服务平台 
        </div>
      </div>
      <div class="free-desc-content">
        <p>帮助金融机构甄选绿色资产和绿色客户，</p>
        <p>开拓绿色票据、绿色供应链金融等新业务；</p>
        <p>帮助金融机构和实体企业核算自身和价值链碳排放</p>
        <p>以及绿色项目减排效益；</p>
        <p>帮助金融机构量化评估客户碳绩效，创新差异化绿色金融产品，</p>
        <p>制定差异化绿色金融政策，防范气候变化风险。</p>
      </div>

      <div class="free-btn" @click="$router.push('/cfa-connect')">
        <div class="free-btn-text">立即体验</div>
        <div class="free-btn-img">
          <img src="@/assets/activity/nine-activity/free.png" alt="" />
        </div>
      </div>
    </div>
    <div class="company-box">
      <div class="cover-company-box" v-for="item in coverData" :key="item.id">
        <div class="cover-company">
          <div class="num">{{ item.number }}</div>
          <div class="unit">{{item.unit}}</div>
        </div>
        <div class="company-text">{{item.desc}}</div>
      </div>
    </div>
    <!-- 谈融通亮点 -->
    <div class="cfa-light">
      <div class="top-title">
        <div class="desc-title">
          <div class="desc-left">
            <img src="@/assets/activity/nine-activity/img_left.png" alt="" />
          </div>
          <div class="desc-text">碳融通亮点</div>
          <div class="desc-right">
            <img src="@/assets/activity/nine-activity/img_right.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 下面的内容 -->
      <!-- 绿识别 -->
      <div class="cfa-light-content">
        <div class="cfa-light-green" v-for="item in lightData.slice(0,1)" :key="item.id">
          <div class="cfa-light-green-title">
            <div class="cfa-light-green-img">
              <img :src="item.url" alt="">
            </div>
            <div class="cfa-light-green-text">
             {{item.title}}
            </div>
          </div>
          <div class="cfa-light-green-content">
            <div class="green-light-content" v-for="itemIntro in item.content" :key="itemIntro.id">
              <div class="green-light-icon">
                 <img :src="itemIntro.url" alt="">
              </div>
              <div class="green-light-text" v-html="itemIntro.desc">
              </div>
            </div>
          </div>
        </div>

        <!-- 碳相关的数据 -->
        <div class="cfa-light-cna"  v-for="item in lightData.slice(1)" :key="item.id">
          <div class="cfa-light-cna-center">
            <div class="cfa-light-cna-title">
              <div class="cfa-light-cna-img">
                <img :src="item.url" alt="">
              </div>
              <div class="cfa-light-cna-text">
                {{item.title}}
              </div>
            </div>
            <div class="cfa-light-cna-content">
              <div class="cna-light-content" v-for="itemIntro in item.content" :key="itemIntro.id">
                <div class="cna-light-icon">
                  <img :src="itemIntro.url" alt="">
                </div>
                <div class="cna-light-text" v-html="itemIntro.desc">
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
 
    </div>
    <div class="product" >
      <div class="top-title product-top-title">
        <div class="desc-title">
          <div class="desc-left">
            <img src="@/assets/activity/nine-activity/img_left.png" alt="" />
          </div>
          <div class="desc-text">核心功能</div>
          <div class="desc-right">
            <img src="@/assets/activity/nine-activity/img_right.png" alt="" />
          </div>
        </div>
      </div>
      <div class="green-open">
        <div class="green-open-left center">
          <div>
            <div class="product-title product-title-des-green">【绿识别】绿不绿</div>
            <div class="product-title-des">绿色资产识别</div>
          </div>

        </div>
        <div class="middle-green">
          <img src="@/assets/activity/nine-activity/green-line.png" alt="" />
        </div>
        <div class="green-open-right">
          <div
            class="green-open-right-activity"
            v-for="(item, index) in greenProductDes"
            :key="index"
          >
            <div class="product-icon">
              <img src="@/assets/activity/nine-activity/correct.png" alt="" />
            </div>
            <div class="product-text lheighteen">{{ item.name }}</div>
          </div>
          <div class="know-more" v-if="false">
            <div class="know-more-text">了解更多</div>
            <div class="know-more-img">
              <img
                src="@/assets/activity/nine-activity/blue-right.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="cna-open">
        <div class="cna-open-left center">
          <div>
            <div class="product-title">【碳核算】准不准</div>
            <div class="product-title-des">
              <p>智能在线精准排放与</p>
              <p>环境效益核算</p>
            </div>
          </div>
        </div>
        <div class="cna-middle-green">
          <img src="@/assets/activity/nine-activity/cna-line.png" alt="" />
        </div>
        <div class="cna-open-right">
          <div
            class="cna-open-right-activity"
            v-for="(item, index) in cnaProductDes"
            :key="index"
          >
            <div class="product-icon">
              <img src="@/assets/activity/nine-activity/correct.png" alt="" />
            </div>
            <div class="product-text lheighteen" v-html="item.name"></div>
          </div>
          <div class="know-more" @click="$router.push('/cna-connect')">
            <div class="know-more-text">了解更多</div>
            <div class="know-more-img">
              <img
                src="@/assets/activity/nine-activity/blue-right.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="grade-open">
        <div class="grade-open-left center">
          <div>
            <div class="product-title">【碳评级】好不好</div>
            <div class="product-title-des">绿金产品创新基石</div>
          </div>
        </div>
        <div class="middle-green-grade">
          <img src="@/assets/activity/nine-activity/grade_line.png" alt="" />
        </div>
        <div class="grade-open-right">
          <div
            class="grade-open-right-activity"
            v-for="(item, index) in gradeProductDes"
            :key="index"
          >
            <div class="product-icon">
              <img src="@/assets/activity/nine-activity/correct.png" alt="" />
            </div>
            <div class="product-text lheighteen" v-html="item.name"></div>
          </div>
          <div class="know-more" v-if="false">
            <div class="know-more-text">了解更多</div>
            <div class="know-more-img">
              <img
                src="@/assets/activity/nine-activity/blue-right.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scenceBox">
      <div class="scence">
        <div class="desc-title">
          <div class="desc-left">
            <img src="@/assets/activity/nine-activity/img_left.png" alt="" />
          </div>
          <div class="desc-text">应用场景</div>
          <div class="desc-right">
            <img src="@/assets/activity/nine-activity/img_right.png" alt="" />
          </div>
        </div>
      </div>
      <div class="scence-text-des">
        解决绿色金融业务流程中的量化评估与动态管理问题
      </div>
      <div class="four-scence">
        <div class="item-scence" v-for="item in scenceData" :key="item.id">
          <div class="item-scence-center">
            <div class="item-scence-left">
              <div class="item-scence-left-title">{{item.title}}</div>
              <div class="item-scence-content" v-for="text in item.content" :key="text.id">
                <div class="item-scence-content-btn">
                </div>
                <div class="item-scence-content-text">
                  {{text.desc}}
                </div>
              </div>
            </div>
            <div class="item-sence-right">
              <img :src="item.url" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search-title" v-if="false">
      输入企业名称  立即获取绿识别结果
    </div>
    <div class="search-company" v-if="false">
      <div class="search-input">
        <div class="inputs">
          <el-input
            v-model="search.companyName"
            placeholder="输入企业名称  立即获取绿识别结果"
            clearable
          ></el-input>
          <div class="icon">
            <div class="icon-img">
              <img
                src="@/assets/activity/nine-activity/search_icon.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="support-box">
      <div class="support">
        <div class="desc-title">
          <div class="desc-left">
            <img src="@/assets/activity/nine-activity/img_left.png" alt="" />
          </div>
          <div class="desc-text">强劲支撑</div>
          <div class="desc-right">
            <img src="@/assets/activity/nine-activity/img_right.png" alt="" />
          </div>
        </div>
      </div>
      <div class="about-data">
        <div class="data-img"></div>
        <div class="data-text-box">
          <div class="data-libray-left-line"></div>
          <div class="data-libray-text-box">
            <div class="data-libray-text">
              <div class="support-title">数智基座支撑</div>
              <div class="support-title-small">盟浪FIN-ESG数据库</div>
              <div class="support-title-desc">
                <div class="support-title-desc-left">
                  <span>全A股覆盖</span><br />
                  <span>9年历史数据</span>
                </div>
              </div>
            </div>
              <div class="support-title-desc-right"  @click="$router.push('/database-connect')">
                <div class="support-title-desc-right-text">了解更多</div>
                <div class="support-title-desc-right-jt">
                  <img
                    src="@/assets/activity/nine-activity/support_jt.png"
                    alt=""
                  />
                </div>
              </div>
          </div>
        </div>

      </div>
      <div class="about-risk">
        <div class="risk-img"></div>
        <div class="data-text-box">
          <div class="data-libray-left-line"></div>
          <div class="data-libray-text-box">
            <div class="data-libray-text">
              <div class="support-title">上市企业负面舆情监控</div>
              <div class="support-title-small">盟浪避雷通</div>
              <div class="support-title-desc">
                <div class="support-title-desc-left risk-text">
                  每日更新上市企业负面动态<br />
                  随时把控投资风险
                </div>

              </div>
            </div>
          <div
            class="support-title-desc-right"
            @click="$router.push('/risktracker-connect')"
          >
            <div class="support-title-desc-right-text">立即查看</div>
            <div class="support-title-desc-right-jt">
              <img
                src="@/assets/activity/nine-activity/support_jt.png"
                alt=""
              />
            </div>
          </div>
          </div>

        </div>
      </div>
    </div>
    <div class="video" id="publish" v-if="isIos">
      <div class="video-box">
        <video
          controls="true"
          preload="auto"
          width="100%"
          height="100%"
          id="video-box-video"
          webkit-playsinline="true"
          playsinline="true"
          x5-video-player-type="h5"
          x5-video-orientation="landscape"
          loop
          autoplay
          muted
          ref="myVideo"
        >
         <source  src="https://susallwave-video.oss-cn-zhangjiakou.aliyuncs.com/2022-99%E6%B4%BB%E5%8A%A8/c7c540f0035d0bea06d1eed82b7b0c4d.mp4?OSSAccessKeyId=LTAI5t7ArdtXKgPgnfUQhCPL&Expires=1977640115&Signature=cPEYwnhcbNXkfEEHoIufrF3EqJw%3D" >
        </video>
      </div>
    </div>
    <div class="video" id="publish" v-else>
      <div class="video-box">
        <video
          controls="true"
          preload="auto"
          width="100%"
          height="100%"
          id="video-box-video"
          webkit-playsinline="true"
          playsinline="true"
          x5-video-player-type="h5"
          x5-video-orientation="landscape"
          loop
          ref="myVideo"
        >
         <source  src="https://susallwave-video.oss-cn-zhangjiakou.aliyuncs.com/2022-99%E6%B4%BB%E5%8A%A8/c7c540f0035d0bea06d1eed82b7b0c4d.mp4?OSSAccessKeyId=LTAI5t7ArdtXKgPgnfUQhCPL&Expires=1977640115&Signature=cPEYwnhcbNXkfEEHoIufrF3EqJw%3D" >
        </video>
      </div>
      <div 
        id="output"   
        v-if="videoPauseShow"   
        class="prism-big-play-btn"    
        @click="videoPlay"
      >
        <div class="btn">
          <div class="btn-img">
          </div>
          <div class="btn-text">
            视频播放
          </div>
        </div>
      </div>
    </div>
    <div class="signup-box" id="signUp">
      <div class="sign-title">立即报名，了解更多</div>
      <div class="sign-form">
        <div class="sign-form-box">
          <el-form :model="form" ref="form" :rules="rules">
            <div class="name-top">
              <el-form-item label="" prop="name">
                <div class="form-desscription">姓名</div>
                <el-input
                  v-model="form.name"
                  placeholder="输入您的姓名"
                ></el-input>
              </el-form-item>
            </div>
            <div class="topbotdistance">
              <el-form-item prop="companyName">
                <div>公司名</div>
                <el-input
                  v-model="form.companyName"
                  placeholder="输入您的公司名"
                ></el-input>
              </el-form-item>
            </div>
            <div class="topbotdistance">
              <el-form-item prop="mobile">
                <div>联系电话</div>
                <el-input
                  v-model.number="form.mobile"
                  placeholder="输入您的手机号"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
          <div class="sign-btn-box">
            <div class="sign-btn" @click="signup">
              <div class="sign-btn-text">立即报名</div>
              <div class="sign-btn-img">
                <img
                  src="@/assets/activity/nine-activity/white-apply.png"
                  alt=""
                />
              </div>
            </div>
            <div class="sign-description">提交成功后将获取直播链接</div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot"></div>
    <!-- 弹窗 -->
    <signDialoag :isShow="isShow" :isShowMessage="isShowMessage" @close="close" v-if="isShow" />
  </div>
</template>

<script>
const HOME_DEFAULT = 2
const activeId = "108588032709640695"
import { Form, FormItem, Input, Message } from 'element-ui'
import Header from '@/views/components/head/index.vue'
import Swiper from "../../components/Swipe/swipe.vue"
import signDialoag from '../../activity/nine-activity-page/components/dialoag.vue'
import request from '@/untils/request'
import { debounce } from "../../../untils/common-method.js"
export default {
  data () {
    var validatePhone = (rule, value, callback) => {
      let reg = /^1[3456789]\d{9}$/
      if (!value) {
        return callback(new Error('请填写联系电话'))
      }
      setTimeout(() => {
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请填写正确的联系方式'))
        }
      }, 100)
    }
    return {
      isIos:false,
      videoPauseShow: true,
      coverUnit:'',
      greenUnit:'',
      potentialUnit:"",
      asyntheticalUnit:'',
      activeId,
      isLate: false,//是否过期 true是过期 fasle是未过期
      HOME_DEFAULT,
      platformrecordInfo: {},//备案信息
      comprehensive: {},//数量
      isShow: false,//弹窗是否显示,
      isShowMessage:false,
      search: {
        companyName: ""
      },
      form: {
        name: "",
        companyName: "",
        mobile: null
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { max: 10, message: '姓名长度最多10个字符', trigger: 'blur' },
        ],
        mobile: [
          { required: true, validator: validatePhone, message: '请填写正确的联系方式', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请填写企业名称', trigger: 'blur' },
          { max: 30, message: '企业名称长度最多30个字符', trigger: 'blur' },
        ]
      },
      imageLocation: "h5-数据库首页banner",
      greenProductDes: [
        { name: "识别企业绿色经营活动", id: 0 },
        { name: "融资用途绿色判断", id: 1 },
        { name: "绿色票据识别", id: 2 },
      ],
      cnaProductDes: [
        { name: "温室气体排放核算", id: 0 },
        { name: "减排效益核算", id: 1 },
        { name: "绿色项目减排效益核算", id: 2},
        { name: "子公司/供应链环境影响<br/>核算", id: 3 },
        { name: "投融资活动环境影响和<br/>效益核算", id: 4},
      ],
      gradeProductDes: [
        { name: "多维度、全定量指标评<br/>估企业碳绩效", id: 0 },
        { name: "自身、同行、跨行业<br/>360度对标分析", id: 1 },
        { name: "国民经济全行业评级", id: 2 },
      ],
      coverData:[],
      lightData:[
        {
          url:require('@/assets/activity/nine-activity/green.png'),
          title:"绿识别",
          content:[
            {
              url:require('@/assets/activity/nine-activity/correct-icon.png'),
              desc:"全国首个依托企业公开信息识别企业绿色<br/>经营活动的工具",
              id:0
            },
            {
              url:require('@/assets/activity/nine-activity/correct-icon.png'),
              desc:"全国首个对工商全库企业识别绿色经营活<br/>动的工具",
              id:1
            },
            {
              url:require('@/assets/activity/nine-activity/correct-icon.png'),
              desc:"在保证准确率的基础上识别效率最高",
              id:2
            },
          ],
          id:0,
          },
          {
            url:require('@/assets/activity/nine-activity/cna.png'),
            title:"碳核算",
            content:[
              {
                url:require('@/assets/activity/nine-activity/correct-icon.png'),
                desc:"内置权威国家及国际碳排放核算标准",
                id:0
              },
              {
                url:require('@/assets/activity/nine-activity/correct-icon.png'),
                desc:"内置国家金融监管部门碳减排核算标准",
                id:1
              },
              {
                url:require('@/assets/activity/nine-activity/correct-icon.png'),
                desc:"内置同行对比及专业减排措施 ",
                id:2
              },
            ],
            id:1,
          },
          {
            url:require('@/assets/activity/nine-activity/grade.png'),
            title:"碳评级",
            content:[
              {
                url:require('@/assets/activity/nine-activity/correct-icon.png'),
                desc:"全定量指标评估，客观性更强",
                id:0
              },
              {
                url:require('@/assets/activity/nine-activity/correct-icon.png'),
                desc:"遵循国民经济行业分类，适用性更强",
                id:1
              },
              {
                url:require('@/assets/activity/nine-activity/correct-icon.png'),
                desc:"传统信用评级标识呈现，易用性更强",
                id:2
              },
            ],
            id:2,
          },
          
      ],
      scenceData:[
        {
          title:'绿色信贷',
          url:require('@/assets/activity/nine-activity/property.png'),
          content:[
            {
              desc:'存量客户绿色经营活动快速筛选',
              id:0,
            },
            {
              desc:'定制化筛选新增企业绿色名单',
              id:1,
            },
            {
              desc:'企业融资用途绿色判断',
              id:2,
            },
            {
              desc:'一键出具绿色认定报告',
              id:3,
            },
          ],
          id:0,
        },
        {
          title:'绿色票据',
          url:require('@/assets/activity/nine-activity/green-loans.png'),
          content:[
            {
              desc:'全线上、高效率识别绿色票据',
              id:0,
            },
            {
              desc:'支持绿色票据贴现融资',
              id:1,
            },
            {
              desc:'支持绿色票据质押贷款融资',
              id:2,
            },
            {
              desc:'支持绿色票据资产证券化融资',
              id:3,
            },
          ],
          id:1
        },
        {
          title:'环境信披',
          url:require('@/assets/activity/nine-activity/invest_icon.png'),
          content:[
            {
              desc:'企业运营碳排放和减排量核算',
              id:0,
            },
            {
              desc:'金融机构投融资组合碳排放测算',
              id:1,
            },
            {
              desc:'供应链条及集团分子公司碳排放核算',
              id:2,
            },
            {
              desc:'金融机构绿色融资及企业绿色项目环境效益测算',
              id:3,
            },
          ],
          id:2
        },
        {
          title:'产品创新',
          url:require('@/assets/activity/nine-activity/envir.png'),
          content:[
            {
              desc:'支持绿色信贷贷前准入',
              id:0,
            },
            {
              desc:'支持碳绩效挂钩金融产品',
              id:1,
            },
            {
              desc:'支持绿色信贷资金定价',
              id:2,
            },
            {
              desc:'支持绿色信贷贷后管理',
              id:3,
            },
          ],
          id:3
        }
      ]
    }
  },
  mounted () {
    this.platformrecordPage()
    this.getAmount()
    this.getActivityInfo(activeId)
    this.isIosphone()
    if(!this.isIos){
      this.getVideoImg()
      var video=document.getElementById("video-box-video"); 
      video.controls=false;
    }
  },

  components: {
    Header,
    Swiper,
    signDialoag,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Message.name]: Message,

  },
  methods: {
    close () {
      this.isShow = false
    },
    open () {
      this.isShow = true
    },
    //获取备案信息
    platformrecordPage () {
      request.post('/ml-admin/index/platformrecord/page', { isRecommended: HOME_DEFAULT }).then(({ data }) => {
        if (data.code == 0) {
          this.platformrecordInfo = data.data.list[0]
        }
      })
    },
    getAmount () {
      request.post('/ml-carfinance/cf/green/homeSummaryQuery').then((res) => {
        this.comprehensive = res.data.data.bizData

      }).then(() => {
        this.coverUnit=this.getUnit(this.comprehensive.coveredEnterprises)
        this.comprehensive.coveredEnterprises = this.onBalanceChange(this.comprehensive.coveredEnterprises)
        this.asyntheticalUnit=this.getUnit(this.comprehensive.evaluationDimension)
        this.comprehensive.evaluationDimension = this.onBalanceChange(this.comprehensive.evaluationDimension)
        this.greenUnit= this.getUnit(this.comprehensive.numberOfGreenEnterprises)
        this.comprehensive.numberOfGreenEnterprises = this.onBalanceChange(this.comprehensive.numberOfGreenEnterprises)
        this.potentialUnit=this.getUnit(this.comprehensive.numberOfPossibleGreenEnterprises)
        this.comprehensive.numberOfPossibleGreenEnterprises = this.onBalanceChange(this.comprehensive.numberOfPossibleGreenEnterprises)
        this.coverData=[
          {  number: this.comprehensive.coveredEnterprises, unit:this.coverUnit,desc:"覆盖企业",id:0},
          {  number: this.comprehensive.numberOfGreenEnterprises, unit:this.greenUnit,desc:"绿色企业",id:1},
          {  number: this.comprehensive.numberOfPossibleGreenEnterprises, unit:this.greenUnit,desc:"潜在绿色企业",id:2},
          {  number: this.comprehensive.evaluationDimension, unit:this.asyntheticalUnit,desc:"综合评估维度",id:3}
        ]
      })
    },
    onBalanceChange (newValue) {
      if (newValue / 10000 >= 1) {
        newValue = newValue / 10000
        if ((newValue + '').indexOf('.') != -1) {
          newValue = Math.floor(newValue)
          if (this.numberWithCommas(this.numberWithoutCommas(newValue)).replace(/([\w]{6})[\w\W]+([\w]{4})$/, '$1…$2').length > 5) {
            return '9,999 '
          } else {
            return this.numberWithCommas(this.numberWithoutCommas(newValue)).replace(/([\w]{6})[\w\W]+([\w]{4})$/, '$1…$2')
          }
        } else {
          newValue = Math.floor(newValue)
          if (this.numberWithCommas(this.numberWithoutCommas(newValue)).replace(/([\w]{6})[\w\W]+([\w]{4})$/, '$1…$2').length > 5) {
            return '9,999 '
          } else {
            return this.numberWithCommas(this.numberWithoutCommas(newValue)).replace(/([\w]{6})[\w\W]+([\w]{4})$/, '$1…$2')
          }
        }
      } else {
        newValue = Math.floor(newValue)
        if (this.numberWithCommas(this.numberWithoutCommas(newValue)).replace(/([\w]{6})[\w\W]+([\w]{4})$/, '$1…$2').length > 5) {
          return '9,999'
        } else {
          return this.numberWithCommas(this.numberWithoutCommas(newValue)).replace(/([\w]{6})[\w\W]+([\w]{4})$/, '$1…$2')
        }
      }
    },
    numberWithoutCommas (x) {
      return String(x).replace(/,/g, '')
    },
    numberWithCommas (x) {
      return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    numberWithCommasWithAdd (x) {
      return String(x).replace(/\B(?=(\d{4})+(?!\d))/g, ",")
    },
    getActivityInfo (id) {
      request.get(`/ml-product/activity/info?id=${id}`).then(({ data }) => {
        if (data.code == 0) {
          this.isLate = data.data.isExpiration
        }
      })
    },
    jump (id) {
      document.querySelector(`#${id}`).scrollIntoView(true)
    },
    signup: debounce(
      function () {
        this.$refs.form.validate((valid) => {
          if (valid) {
            request.post('/ml-product/activityRetained/saveOrUpdate', {
              activityId: activeId,
              ...this.form
            }).then(({ data }) => {
              if (data.code == 0) {

                if (!this.isLate) {
                  this.isShowMessage=true
                  this.isShow = true
   
                  this.form = {
                    name: "",
                    companyName: "",
                    mobile: null
                  }
                } else {
                  Message({
                    type: 'error',
                    message: "活动已过期"
                  })
                }
              }
            })
          }
        })
      }, 500
    ),
    getUnit(val){
      val= Math.floor(val)
      if(String(val).length>=5){
        return '万'
      } else{
        return '个'
      }
    },
    getVideoImg(){
		  let	output = document.getElementById("output"); 
			let canvas = document.createElement('canvas')  
			let img = document.createElement("img"); 
			let video= document.getElementById('video-box-video') 
			video.currentTime = 1  //截取第一帧
			canvas.width = video.clientWidth 
			canvas.height = video.clientHeight 
      video.addEventListener('onloadeddata',()=>{
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
        let dataURL = canvas.toDataURL('image/png') 
        img.src = dataURL; 
        img.width = canvas.width; 
        img.height = canvas.height;
        output.appendChild(img); 
      })
    },
    videoPlay() {
      this.videoPauseShow = false;
      this.$refs.myVideo.play();
      const  videos=document.getElementById("video-box-video"); 
      videos.controls=true;
    },
    isIosphone(){
      var u = navigator.userAgent;
      var isios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      this.isIos=isios
      return  this.isIos
    }
  },
}
</script>

<style lang="less" scoped>
@import url('./activity');
</style>
