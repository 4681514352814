<template>
  <div>
    <van-tabs v-model="active"  @change="onTabsChange" line-width="68px" line-height="2px" title-active-color="#417DFF" color="#417DFF">
      <van-tab title="企业查询">
        <EnterpriseQuery/>
      </van-tab>
      <van-tab title="行业查询">
        <IndustryQuery/>
      </van-tab>
      <van-tab title="区域查询">
        <AreaQuery/>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Search ,Overlay} from "vant";
import { Toast } from 'vant';
import EnterpriseQuery from './components/enterprise-query.vue';
import IndustryQuery from './components/industry-inquiry.vue';
import AreaQuery from './components/area-query.vue';
  export default {
    components: {
      [Search.name]: Search,
      [Overlay.name]: Overlay,
      EnterpriseQuery,
      IndustryQuery,
      AreaQuery
    },
    data() {
      return {
        active: 0,
        show: false
      }
    },
    created () {		
      
    },
    methods: {
     onTabsChange(name,title){
      console.log(name,title);
     },
    },
  }
</script>

<style lang="less" scoped>
  /deep/.van-tab__text--ellipsis{
    height: 100px;
    line-height: 100px;
  }
  /deep/ .van-tab__text {
    font-family: PingFangSC-Medium;
    font-size: 16px;
    letter-spacing: 0.89px;
    font-weight: 500;
  }
  /deep/.van-tab {
    color: #333333FF;
  }
</style>