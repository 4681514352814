<template>
  <div class="dialoag-box">
    <el-dialog
      title="提示"
      :visible="isShow"
      width="344px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div class="top">
        <div class="qr-box">
          <div class="qr">
            <img src="@/assets/activity/nine-activity/qrcode.png" alt="" />
          </div>
          <div class="search">
            <img src="@/assets/activity/nine-activity/diasearch.png" alt="" />
          </div>
        </div>

        <div class="qr-line"></div>
      </div>
      <div class="text">微信扫一扫预约</div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Message } from 'element-ui'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    isShowMessage:{
      type: Boolean,
      default: false
    }
  },
  mounted () {
    if(this.isShowMessage){
      Message({
        type: "success",
        message: '提交成功',
        center:true,
      })
    }
  },
  components: {
    [Message.name]: Message,
    ['el-dialog']: Dialog
  },
  methods: {
    close () {
      this.$emit('close')
    }
  },
}
</script>

<style lang="less" scoped>
.dialoag-box {
  width: 344px;
  height: 327px;
  .top {
    .qr-box {
      position: relative;
      display: flex;
      justify-content: center;
      .qr {
        width: 126px;
        height: 126px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .search {
        width: 31px;
        height: 31px;
        position: absolute;
        left: 71%;
        top: 74%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .qr-line {
      width: 180px;
      height: 4px;
      margin: auto;
      margin-top: -4px;
      background: linear-gradient(90deg, #417dff 0%, #0bc2cd 100%);
      border-radius: 2px;
    }
  }
  .text {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }
  /deep/.el-dialog__body {
    padding-bottom: 56px;
  }
  /deep/.el-dialog__title {
    border-left: 4px solid #3080f1;
    border-radius: 2px;
    padding-left: 10px;
  }
}
</style>