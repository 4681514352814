<template>
  <div class="main">
    <section class="content">
      <slot />
    </section>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="less" scoped>
.main{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('@/assets/Login/login-bg.webp');
  background-repeat: no-repeat;
  background-color: #409eff;
  background-size: cover;
}
.content{
  width: 327px;
  min-height: 500px;
  padding: 16px 0 21px 0;
  background: #FFFFFF;
  box-shadow: 0vw 30px 40px 0 rgba(70, 78, 110, 0.33);
  border-radius: 10px;
}
</style>