<template>
  <div>
    <van-dialog
      class="dialog-bgi"
      style="font-size: 4vw"
      v-model="isShow"
      title=""
      :show-cancel-button="false"
      :show-confirm-button="false"
      :beforeClose="beforeClose"
      closeOnClickOverlay
    >
      <p class="title">我要留言</p>
      <van-field
        class="center-inp"
        style="min-height: 23vw !important"
        type="textarea"
        maxlength="300"
        v-model="guestbook"
        label=""
        placeholder="字符300字以内"
      />
      <div class="submit-btn-box">
        <van-button
          @click="handleCancel"
          class="submit-btn-cancel"
          type="primary"
        >
          取消
        </van-button>
        <van-button @click="handleConfirm" class="submit-btn" type="primary">
          提交
        </van-button>
      </div>
      <div @click="callPhone" style="font-size: 3vw; margin: 4vw 2vw">
        <p class="btm-tit" style="display: inline-block">联系客服</p>
        <span class="btm-tit">13694267645 &nbsp;</span>
        <span class="btm-timer"> (时间: 09: 00-22: 00) </span>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { set } from "lodash";
import request from "@/untils/request";
import { Toast } from "vant";
// 将所有 Toast 的展示时长设置为 3000 毫秒
Toast.setDefaultOptions({ duration: 3000 });
export default {
  name: "show-Dialog",
  props: {
    isShowDialog: {
      type: Boolean,
      default: false,
    },
    dialogClose: {
      type: Function,
    },
  },
  data() {
    return {
      guestbook: "",
      dialogOption: {
        // 是否在点击遮罩层后关闭弹窗
        closeOnClickOverlay: false,
        // 标题
        title: "",
        // 是否展示取消按钮
        showCancelButton: true,
        // 是否展示确认按钮
        showConfirmButton: true,
        width: "96%",
        beforeClose: this.beforeClose,
      },
      formData: {},
    };
  },
  computed: {
    isShow: {
      get() {
        return this.isShowDialog;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  watch: {
    isShow: {
      handler: "handleShow",
      immediate: true,
    },
  },
  methods: {
    transStatus() {
      this.$emit("dialogClose", false);
    },
    callPhone() {
      window.location.href = "tel://13694267645";
    },
    handleShow(val) {
      if (val) {
        const { title, width, dialogOption } = this.$attrs;
        title && set(this.dialogOption, "title", title);
        width && set(this.dialogOption, "width", width);
        dialogOption && Object.assign(this.dialogOption, dialogOption);
      }
    },
    // 确认按钮
    handleConfirm() {
      if (!this.guestbook) {
        Toast("请输入内容后提交！");
        return false;
      } else {
        request
          .post("/api/ml-member/consult/memberconsult/save", {
            message: this.guestbook,
            messageType: "8",
          })
          .then((res) => {
            if (res.data.code === 0) {
              Toast("提交成功!");
              this.guestbook = "";
              this.dialogClose();
            } else if (res.data.code === 401) {
              Toast("请先登录!");
              return false;
            }
          });
      }
    },
    // 取消按钮
    handleCancel() {
      this.dialogClose();
      this.guestbook = "";
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        setTimeout(() => {
          if (!this.guestbook) {
            Toast("请输入内容后提交！");
            done(false); //不关闭弹框
          } else {
            done();
            this.guestbook = "";
          }
        }, 1000);
      } else if (action === "cancel") {
        this.guestbook = "";
        done();
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-dialog__confirm {
  font-size: 32px;
  height: 10vw;
}
/deep/.van-dialog__cancel {
  height: 10vw;
  font-size: 32px;
}
/deep/.van-dialog__header {
  line-height: 5vw;
}
.dialog-bgi {
  width: 672px;
  height: 573px;
  background: url("../../assets/dialog.png") no-repeat;
  background-size: cover;
  padding: 0 40px;
  padding-top: 136px;
  .title {
    padding: 22px 0;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #2a9e88;
    opacity: 1;
  }
  .center-inp {
    overflow: auto;
    font-size: 32px;
    padding: 20px;
    /deep/.van-field__control {
      height: 124px !important;
      line-height: 50px;
    }
  }
  .submit-btn-box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .submit-btn-cancel {
      width: 190px;
      height: 72px;
      background: #fff;
      opacity: 1;
      border-radius: 16px;
      font-size: 30px;
      color: #28b38f;
    }
    /deep/.submit-btn {
      width: 190px;
      height: 72px;
      background: #28b38f;
      box-shadow: 0px 6px 12px #a3ecde;
      opacity: 1;
      border-radius: 16px;
      font-size: 30px;
    }
  }
  .btm-tit {
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #2a9e88;
    opacity: 1;
  }
  .btm-timer {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #52bfaa;
    opacity: 1;
  }
  .van-toast {
    width: 30vw !important;
  }
}
</style>
<style>
.van-toast {
  height: 150px;
}
.van-toast__text {
  text-align: center;
  font-size: 30px !important;
}
</style>
