import MemberUc from "./member.uc";
import { LoginByAccount, LoginByPhone } from "../model/login.model";
import { debounce, message } from '../../../utils';

import ApiLogin from "../data/login.api";
import ApiProduct from "../data/product.api";

export class InputFormOfVerifyByPhone{
  constructor() { 
    this.form = null 
    this.fetchForm()
  }

  fetchForm() {
    this.form = new LoginByPhone()
    return Promise.all([
      this.fetchAreaCodelist(),
      this.fetchRegistryFirms()
    ]).then(() => this.form)
  }

  fetchAreaCodelist() {
    return new ApiLogin().areaCodeList().then(list => {
      this.form.areaCode.list = list;
    })
  }

  fetchRegistryFirms() {
    return new ApiProduct().firminfoList().then(firmList => this.form.firmList = firmList)
  }
  showCaptchaDialog = (async () => {
    if (!this.form.mobile) {
      return Promise.reject({
        message:"请输入手机号",
        type:'warning',
      });
    } else if (!/^1\d{10}$/.test(this.form.mobile) && this.form.areaCode.value == '86') {
      return Promise.reject({
        message:"请输入正确手机号",
        type:'warning',
      });
    } 
      else if (!this.form.captcha) {
        return Promise.reject({
          message:"请输入图片验证码",
          type:'warning',
        });
      } else {
        return this.validateCaptcha();
      }
  })

  validateCaptcha() {
    const RETURN_ERR_CODE = 10007
    return new ApiLogin().validateCaptcha(this.form.loginOrRegistrySubmition)
      .then(({ data }) => {
        if (data.code == 0) {
          this.setTimer()
        } else if (data.code == RETURN_ERR_CODE || data.code == 500) {
          this.form.captcha = ''
          this.form.updateCaptchaImg()
          return Promise.reject({
            type:"error",
            message:'图片'+data.msg,
          })
        }
      })
  }

  setTimer() {
    const TIME_COUNT = 60
    this.form.codeDisabled = true
    if (!this.form.timer) {
      this.form.count = TIME_COUNT
      this.form.codeShow = false
      this.form.timer = setInterval(() => {
        if (this.form.count > 0 && this.form.count <= TIME_COUNT) {
          this.form.count--
        } else {
          this.form.codeShow = true
          clearInterval(this.form.timer)
          this.form.timer = null
          this.form.codeDisabled = false
        }
      }, 1000)
    }
  }
}