<template>
  <div class="status-code" v-if="responseCode.resCode.length > 0">
    <div class="error-code">
      <h1>响应状态码</h1>
      <hr />
      <div class="tableBox">
        <el-table
          :data="responseCode.resCode"
          header-row-class-name="table-header-style"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column prop="code" label="状态码"> </el-table-column>
          <el-table-column prop="state" label="说明"> </el-table-column>
          <el-table-column prop="handle" label="处理方法"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui'
import responseCode from '#susallwave-base/domain/finesg/data/response-code.js'
export default {
  data () {
    return {
      responseCode,
    }
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
}
</script>

<style lang="less" scoped>
.status-code {
  // width: 908px;
  background: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}
p {
  font-size: 16px;
  font-weight: 400;
  color: #0d0d0d;
  line-height: 22px;
  margin: 17px 0 18px 0;
}
h1 {
  font-size: 16px;
  font-weight: 600;
  color: #445070;
  line-height: 22px;
  margin-top: 20px;
}

hr {
  // width: 871px;
  height: 2px;
  border: 1px solid #979797;
  margin-top: 8px;
  margin-bottom: 16px;
}
.tableBox {
  /deep/.el-table {
    th {
      background-color: #287ede;
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      height: 44px;
      td {
        border: none;
      }
    }
    tr {
      text-align: center;
    }
    tr:nth-child(even) {
      background: #deeeff;
    }
    td {
      border: solid 1px #ced7ef;
    }
  }
}
</style>