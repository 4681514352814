export default {
    section: {
      firstSection: {
        title: '概述',
        firstP: "盟浪以API形式为用户提供数据服务，提供的数据包括财务（F）、创新（I）、价值准则（N）、环境（E）、社会（S）、公司治理（G）维度的原始指标数据、评分评级数据，以及专题数据。详细的数据见API列表和数据字典。",
        secondP: "通过API方式调用数据的基本流程如下："
      },
      secondSection: {
        title: '调用与返回说明',
        firstP: " 获取到Access_Key, Access_Secret后，就可以使用其来调用目标API，调用API时，您只需下载代码样例，依据API列表的说明修改输入参数后，即可调取需要的数据。",
        secondP:' 每一次调用都会得到对应的返回，当HTTP状态码是0时，表示调用成功。对于json的请求方式，返回格式形如：',
        threeP:'{"code": 0, "message": "success", "data": 数据内容}',
        fourP:'其中"code"表示查询操作的状态代码；"message"表示查询操作的返回消息。',
      },

    }
  }