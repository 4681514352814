const { LoginWay, default: Login } = require("../model/login.model");

export default class LoginPresenter{
  loginWay = LoginWay.byPhone
  goByAccount() {
    this.loginWay = LoginWay.byPhone;
  }

  static loginByPhone = {
    label: LoginWay.getLabel(LoginWay.byPhone),
    code: LoginWay.byPhone
  }
  static loginByAccount = {
    label: LoginWay.getLabel(LoginWay.byAccount),
    code: LoginWay.byAccount
  }
}