<template>
  <div v-if="detailsTable&& detailsTable.sections" class="main">
    <div class="title" v-if="false">
      {{ detailsTable.title }}
    </div>
    <div
      v-for="(s, i) in detailsTable.sections.slice(0, 1)"
      :key="s.title"
      class="itembox firstTable"
    >
      <div class="title special_title" v-if="s.title">
        {{ s.title }}
      </div>
      <el-table
        :data="s.content.data"
        :key="i"
        :header-cell-style="{ textAlign: 'left' }"
        :cell-style="cellStyle"
        fit
      >
        <el-table-column
          v-for="c in s.content.columns"
          :key="c.prop"
          :label="c.label"
          :width="c.width"
        >
          <template slot-scope="scope">
            <span v-html="scope.row[c.prop]"></span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      v-for="(s, i) in detailsTable.sections.slice(1)"
      :key="s.title"
      class="itembox moreTable"
    >
      <div class="title" v-if="s.title">
        {{ s.title }}
      </div>
      <el-table
        :data="s.content.data"
        :key="i"
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column
          v-for="c in s.content.columns"
          :key="c.prop"
          :label="c.label"
          :width="c.width"
        >
          <template slot-scope="scope">
            <span v-html="scope.row[c.prop]"></span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui'
export default {
  inject: ['tables'],
  props: {
    detailsTable: {
      type: Object,
      default: () => ({})
    },
  },
  data () {
    return {
    }
  },

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  methods: {
    cellStyle ({ columnIndex }) {
      if (columnIndex === 0) {
        return { 'color': '#333333 !important', 'font-weight': 600, 'font-size': '3.73vw' }
      } else {
        return { 'color': '#333333', 'font-weight': 400, 'font-size': '3.73vw' }
      }
    },
  }
}
</script>

<style lang="less" scoped>
.main {
  padding-left: 15px;
  margin-bottom: 65px;
  .title {
    height: 38px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #445070;
    line-height: 22px;
    border-bottom: 1px solid #979797;
    padding-bottom: 8px;
    padding-top: 8px;
    margin-bottom: 14px;
  }

  .itembox {
    overflow: scroll;
  }
  .moreTable {
    /deep/.el-table {
      border-left:1px solid #deeeff;
      border-right:1px solid #deeeff;
      th {
        background-color: #287ede;
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        td {
          border: none;
        }
      }
      tr:nth-child(even) {
        background: #deeeff;
      }
      td {
        border: solid 1px #ced7ef;
      }
      th > .cell {
        font-size: 14px;
        
      }
      
    }
  }
  .firstTable {
    margin-top: -14px;
    /deep/.el-table {
      th {
        font-size: 16px;
        font-weight: 600;
        height: 22px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #445070;
        td {
          border: none;
        }
      }
      th > .cell {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #445070;
        line-height: 22px;
      }
    }
    /deep/.el-table__row > td {
      /* 去除表格线 */
      border: none;
    }
    /deep/ .el-table th.is-leaf {
      /* 去除上边框 */
      border-bottom: 1px solid #979797;
    }
    /deep/.el-table::before {
      /* 去除下边框 */
      height: 0;
    }
  }
}
.main .itembox:nth-child(3){
  width:345px ;
  margin: auto;
}

</style>