class Env{
  MODE_SSR = '1'
  MODE_CLIENT = '2'
  mode = this.MODE_CLIENT
  get ssr() { return this.mode === this.MODE_SSR }
  get client() { return this.mode === this.MODE_CLIENT }

  supportSsr() { return this.mode = this.MODE_SSR }
}

const env = new Env();
export default env;