import Api from '../../../core/api';

export default class ApiMember extends Api{
  userInfo() {
    return this.post('/ml-member/user/userinfo/info');
  }
  verifyUserByPhone({
    mobile,
    nationalCode,
    verCode,
    verifyType
  }) {
    return this.post('/ml-member/user/userinfo/secverf', {
      mobile,
      nationalCode,
      verCode,
      verifyType
    })
  }

  sendCode2Email(form) {
    return this.post('/ml-member/user/userinfo/sendCode', form)
  }

  verifyUserByEmail({
    email,
    verCode,
    verifyType,
  }) {
    return this.post('/ml-member/user/userinfo/emailSecverf', {
      email,
      verCode,
      verifyType,
    })
  }

  resetPwd(id, pwd) {
    return this.post('/ml-member/user/userinfo/resetPwd', { id, pwd })
  }

  saveCompanyProperty(enterpriseAttributes) {
    return this.post('/ml-member/user/userinfo/pc/saveEnterpriseAttributes', { enterpriseAttributes })
  }
}