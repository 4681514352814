<template>
  <Tabs v-model="login.loginWay" stretch>
    <TabPane :label="LoginPresenter.loginByPhone.label" :name="LoginPresenter.loginByPhone.code">
      <LoginByphone :onLoginSuccessed="onLoginWithDefault"/>
    </TabPane>
    <TabPane :label="LoginPresenter.loginByAccount.label" :name="LoginPresenter.loginByAccount.code">
      <LoginByAccount 
        :goByAccount="() => login.goByAccount()"  
        :onLoginSuccessed="onLoginWithDefault"/>
    </TabPane>
  </Tabs>
</template>

<script>
import { Tabs, TabPane } from 'element-ui'
import LoginPresenter from '../presenter/login.pre'
import LoginByphone from './login-byphone.vue'
import LoginByAccount from './login-byaccount.vue'

export default {
  props: ['onLoginSuccessed'],
  data() {
    return {
      login: new LoginPresenter(),
      LoginPresenter,
    }
  },
  computed: {
    onLoginWithDefault() {
      return this.onLoginSuccessed || this.onLoginDefault;
    }
  },
  components: {
    Tabs, 
    TabPane,
    LoginByphone,
    LoginByAccount
  },
  methods: {
    onLoginDefault() {
      const navPath = this.$route.query.pre || '/'
      this.$router.replace(navPath) 
    }
  }
}
</script>
<style lang="less" scoped>
@import './style/tab.less';
@import './style/global.less';
/deep/a{
  color: #2E7DF4;
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
}
/deep/.pwd-con{
  margin-top: 2px;
  margin-bottom: 60px;
}
/deep/.re-con{
  font-size: 12px;
  color: #666666;
  line-height: 17px;
  margin-top: 25px;
  text-align: center;
  &>span{
    margin-right: 5px;
  }
}
</style>