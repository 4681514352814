<template>
  <div class="footer">
    <Tabbar :tabbars="tabbars" @change="handleChange" v-if="isShow" />
  </div>
</template>

<script>
import Tabbar from "./tabbar.vue"
export default {
  components: {
    Tabbar,
  },
  data () {
    return {
      isShowPathArr: ['/', '/consulting', '/data-connect', '/finesg-connect', '/risktracker-connect', '/sustainability-connect', '/cna-connect', '/user', '/consulting-list'],
      active: 0,
      tabbars: [
        {
          title: '首页',
          to: {
            name: 'toHome',
            path: "/"
          },
          normal: require("../../assets/foot/home.png"),
          active: require("../../assets/foot/active-home.png")
        },
        {
          title: '资讯',
          to: {
            name: 'consulting',
            path: "/consulting"
          },
          normal: require("../../assets/foot/consuslt.png"),
          active: require("../../assets/foot/active-consuslt.png")
        },
        {
          title: '产品',
          to: {
            name: "#",
            path: "#",
          },
          normal: require("../../assets/foot/product.png"),
          active: require("../../assets/foot/active-product.png"),
          isProduct: true
        },
        {
          title: '个人中心',
          to: {
            name: 'user',
            path: "/user"
          },
          normal: require("../../assets/foot/userinfo.png"),
          active: require("../../assets/foot/active-userinfo.png")
        }
      ]
    }
  },
  computed: {
    isShow: {
      get () {
        switch (this.$route.path) {
          case "/":
            return true
          case "/consulting":
            return true
          case "#":
            return true
          case "/data-connect":
            return true
          case "/finesg-connect":
            return true
          case "/risktracker-connect":
            return true
          case "/risktracker-newsList":
            return true
          case "/sustainability-connect":
            return true
          case "/cna-connect":
            return true
          case "/cfa-connect":
            return true
          case "/user":
            return true
          case "/database-connect":
            return true
          case "/database-apidocument":
            return true
          case "/data-dictionaries":
            return true
          case "/activity-page":
            return true
          default:
            break
        }
      },
      set () { }
    }
  },
  methods: {
    onChange (index) {
      this.activ = 0
      this.active = index
    },
    handleChange (x) {
      console.log('tab value:', x)
    }
  },
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.07);
  margin-top: 50px;
}
.item-icon {
  cursor: pointer;
}
</style>