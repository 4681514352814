<template>
  <div class="risk">
    <Header />
    <div class="risk-top">
      <Swiper :imageLocation="imageLocation" />
      <div v-if="!token">
        <div class="applay-try"  @click="applicance">
          申请试用避雷通
        </div>
      </div>
      <div v-else>
        <div
          class="applay-try"
          v-if="authority === 0"
          @click="applicance"
        >
          申请试用避雷通
        </div>
        <div
          v-else
          class="applay-try"
          @click="goRiskNews"
        >
          进入避雷通
        </div>
      </div>

    </div>
    <div class="negative-report">
      <div class="negative-tabs">
        <div
          class="negative-tabs-left"
          @click="checkTabs(tabs[0])"
          :class="active == tabs[0].id ? 'tabs-active' : ''"
        >
          {{ tabs[0].name }}
        </div>
        <div
          class="negative-tabs-right"
          @click="checkTabs(tabs[1])"
          :class="active == tabs[1].id ? 'tabs-active' : ''"
        >
          {{ tabs[1].name }}
        </div>
        <div :class="active == 0 ? 'triangle' : 'triangle2'">
          <img
            src="../../assets/productIntro/3-risktracker/triangle.png"
            alt=""
          />
        </div>
      </div>
      <div v-if="active == 0">
        <div class="negative-report-list">
          <List v-for="news in pageArr" :key="news.id" :news="news" />
        </div>
        <div class="pagination">
          <MyPagination
            :total="total"
            @changePage="changePage"
            :currentPage="currentPage"
            :disAdd="disAdd"
            :token="token"
            :isFakeTotal="isFakeTotal"
          />
        </div>
        <div class="gomore" @click="goMore">点击查看更多资讯</div>
      </div>
      <div v-if="active == 1">
        <RiskBillBoard :token="token" :authority="authority" />
      </div>
    </div>
    <jurisdictionDialoag
      serviceCode="SERVICE-002"
      ref="productIntro"
      :productName="productName"
      :authority="authority"
      :isHasApplay="isHasApplay"
      @updateAuthority="updateAuthority"
    />
  </div>
</template>

<script>
const riskTypeF = "F"
const riskTypeE = "E"
const riskTypeS = "S"
const riskTypeG = "G"
import Header from "../../views/components/head/index.vue"
import jurisdictionDialoag from '../../views/components/product-intro/jurisdiction-message.vue'
import MyPagination from './components/my-paganation.vue'
import { Message, Pagination } from 'element-ui'
import { DropdownMenu, DropdownItem } from 'vant'
import RiskBillBoard from './components/riskBillboard.vue'
import request from '@/untils/request'
import Swiper from "../components/Swipe/swipe.vue"
import List from './components/negative-report-list.vue'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      isHasApplay: false,
      currentPage: 1,
      isFakeTotal: true,//风险报告的变量
      token: false,
      show: true,
      active: 0,
      actives: 0,
      riskTypeF,
      riskTypeE,
      riskTypeS,
      riskTypeG,
      imageLocation: "h5-避雷通首页banner",
      pageNo: 1,
      limit: 5,
      total: null,
      pageArr: [],
      isShow: false,
      hasService: false,
      tabs: [
        { name: '负面资讯简报', id: 0 },
        { name: '风险事件统计看板', id: 1 },
      ],
      authority: -1,
      productName: '避雷通',
      requestedNewsNum: 0,
      disAdd: false,
    }
  },
  watch: {
    authority (newV, oldV) {
      if (newV == 1 || newV == 4) {
        this.hasService = true
      } else {
        this.hasService = false
      }
    }
  },
  computed: {
    ...mapGetters(['logined']),
  },
  components: {
    Header,
    jurisdictionDialoag,
    RiskBillBoard,
    Swiper,
    List,
    MyPagination,
    [Message.name]: Message,
    [Pagination.name]: Pagination,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
  mounted () {
    this.getNewsPage(1, 5)
    if (this.logined) {
      this.token = true
      this.isValid()
    }
  },
  updated () {
    if (this.logined) {
      this.token = true
      this.isValid()
    }
  },
  methods: {
    updateAuthority (val) {
      this.authority = val
    },
    applicance () {
      this.isHasApplay = false
      this.showApplicance()
    },
    showApplicance () {
      this.$refs.productIntro.showApplicance()
    },
    goRiskNews () {
      if (this.authority == 1 || this.authority == 4) {
        this.$router.push('/risktracker-newsList')
      } else {
        this.applicance()
      }
    },
    isValid () {
      request
        .post(
          '/ml-member/user/memberuserservice/getUserServiceStatusV2',
          null,
          { params: { serviceCode: 'SERVICE-002' } }
        )
        .then(({ data }) => {
          if (data.code == 0) {
            this.authority = data.data
          }
        })
    },
    getNewsPage (pageNo, limit) {
      request.post('/ml-product/lightning/lightningnewsv2/pageHomeNewsV2', {
        page: pageNo,
        limit: limit
      })
        .then(({ data }) => {
          if (data.code === 0) {
            this.pageArr = data.data.list
            this.total = data.data.total
            window.scrollTo(0, 0)
          }
        })
    },
    clearTimeFrame () {
      this.form.timeFrame = null
    },
    clearAnalystRatings () {
      this.form.analystRatings = null
    },
    search () {
      this.$refs.searchForm.validate((valid) => {
        if (valid) {
          this.getRiskTableList(1, this.pageSize)
        } else {
          this.$message({
            type: "error",
            message: '请按照填写规则填写'
          })
        }
      })
    },
    goMore () {
      if (this.authority == 1 || this.authority == 4) {
        this.$router.push('/risktracker-newsList')
      } else {
        this.isHasApplay = true
        this.showApplicance()
      }
    },
    checkTabs (row) {
      this.active = row.id
    },
    changePage (val) {
      // 分页可以没权限  没权限看50条
      if (this.token) {
        if (this.authority == 1 || this.authority == 4) {
          this.pageNo = val
          this.currentPage = val
          this.getNewsPage(this.pageNo, 5)
        } else {
          if (val < 10) {
            this.pageNo = val
            this.currentPage = val
            this.disAdd = false
            this.getNewsPage(this.pageNo, 5)
          } else if (val == 10) {
            this.pageNo = val
            this.currentPage = val
            this.getNewsPage(this.pageNo, 5)
            this.disAdd = true
          } else {
            this.disAdd = true
            this.showApplicance()
          }
        }
      } else {
        if (val < 10) {
          this.pageNo = val
          this.currentPage = val
          this.disAdd = false
          this.getNewsPage(this.pageNo, 5)
        } else if (val == 10) {
          this.pageNo = val
          this.currentPage = val
          this.getNewsPage(this.pageNo, 5)
          this.disAdd = true
        }
        else {
          this.disAdd = true
          this.showApplicance()
        }
      }
    }
  },
}
</script>
<style lang="less" scoped>
.tabs-active {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #417dff;
}
.title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  .title-icon {
    display: inline-block;
    width: 7px;
    height: 5px;
    position: relative;
    top: -2px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.risk {
  box-sizing: border-box;
  .risk-top {
    .applay-try {
      position: absolute;
      background: linear-gradient(90deg, #58c3ff, #417dff);
      color: #ffffff;
      top: 170px;
      left: 30px;
      padding: 8px;
      border-radius: 15px;
      width: 135px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      z-index: 100;
    }
  }
  .negative-report {
    .negative-tabs {
      width: 375px;
      height: 50px;
      line-height: 50px;
      display: flex;
      background: #ffffff;
      box-shadow: 0px 1px 0px 0px rgba(65, 125, 255, 0.2);
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      position: relative;
      .triangle {
        width: 10px;
        height: 8px;
        position: absolute;
        left: 98px;
        top: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .triangle2 {
        width: 10px;
        height: 8px;
        position: absolute;
        left: 276px;
        top: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .negative-tabs-left {
        margin-left: 54px;
        height: 50px;
      }
      .negative-tabs-right {
        margin-left: 66px;
      }
    }
    .negative-report-list {
      width: 375px;
      // height: 875px;
      height: auto;
      margin: auto;
    }
    /deep/.pagination {
      // width: 375px;
      margin: auto;
      text-align: center;
      // width: 286px;
      // margin: auto;
      margin-bottom: 18px;
      margin-top: 14px;
      .el-pagination {
        width: 375px;
      }
      .el-pager li:not(.disabled).active {
        background-color: #f2f3f5;
        color: #1d2129;
      }
      .el-pagination .btn-prev {
        border: 1px dashed gray;
        padding-right: 6px;
        // margin-right: 40px;
      }
      .btn-next {
        border: 1px dashed gray;
        padding-left: 6px;
      }
      .el-pager {
        margin-left: 40px;
        margin-right: 40px;
        font-size: 14px;
        li {
          margin-right: 2px;
        }
      }
    }
    .gomore {
      width: 346px;
      height: 35px;
      margin: auto;
      margin-bottom: 11px;
      border-radius: 4px;
      border: 1px solid #4d70b9;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4c70ba;
      line-height: 35px;
      text-align: center;
    }
  }
}
.arrow_down {
  width: 0;
  height: 0;
  position: absolute;
  top: 11px;
  right: 9px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #ffffff;
}
.arrow_top {
  width: 0;
  height: 0;
  position: absolute;
  bottom: 14px;
  right: 28px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #ffffff;
}
</style>