import Vue from "vue"
import {
  Col,
  Row,
  Button,
  Notify,
  Form,
  Field,
  Checkbox,
  CheckboxGroup,
  Image as VanImage,
  Dialog,
  Icon,
  DropdownMenu,
  DropdownItem,
  Popup,
  Swipe,
  SwipeItem,
  Lazyload,
  Tabbar,
  TabbarItem,
  NavBar,
  Popover,
  Tab,
  Tabs,
  PullRefresh,
  List,
  Toast,
  Pagination,
  Overlay,
  Cell,
  CellGroup
} from "vant"

Vue.use(Button)
Vue.use(Form)
Vue.use(Field)
Vue.use(Checkbox)
Vue.use(VanImage)
Vue.use(Dialog)
Vue.use(Icon)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Popup)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar)
Vue.use(Popover)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Toast)
Vue.use(Col)
Vue.use(Row)
Vue.use(CheckboxGroup)
Vue.use(Notify)
Vue.use(Pagination)
Vue.use(Overlay)
Vue.use(Cell)
Vue.use(CellGroup)


