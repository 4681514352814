export const searchLength = 50; // 现在搜索框最大输入字符

export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    return (c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8").toString(
      16
    );
  });
}

export const telSelects = [
  {
    name: "+861",
    vaule: "中国",
  },
  {
    name: "+862",
    vaule: "中国2",
  },
  {
    name: "+863",
    vaule: "中国3",
  },
];
