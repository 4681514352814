<template>
  <span>
    <FormBase ref="form" :uc="uc" />
    <!-- TODO: need forget pwd -->
    <!-- <nuxt-link to="/forget-password">忘记密码</nuxt-link> -->
    <AsyncButton
      round
      class="submit"
      :click="() => onLogin()"
    >
      登录｜注册
    </AsyncButton>
    <el-form 
      v-if="form" :model="form" 
      :rules="form.rules"
      hide-required-asterisk
      ref="formCheck"
    >
      <el-form-item label="" prop="firm">
        <div class="firm-con">
          <el-checkbox  v-model="form.firm" :checked="true"></el-checkbox>
          <div class="firms">
            <span>我已阅读并同意</span>
            <a 
              v-for="item in form.firmList" 
              :key="item.id" 
              @click.prevent="uc.viewFirm(item)"
              class="firm"
            >《{{ item.infoName }}》
            </a>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <ModalCompanyProperty :visible="form ? form.promptCompanyProperty : false" :close="onLoginSuccessed"/>
    <el-dialog
      v-if="uc.form.viewingFirm"
      :visible="true"
      width="90%"
      :before-close="uc.stopViewFirm"
      class="dialog-firm"
      append-to-body
    >
      <div class="firm-content" v-html =" uc.form.viewingFirm.content " />
    </el-dialog>
  </span>
</template>

<script>
import FormBase from './phone-verf-form.vue'
import ModalCompanyProperty from './modal-company-property.vue'
import AsyncButton from '../../../share/vue/async-button.vue'
import LoginUc from '../uc/login.uc'
import { mapActions } from "vuex";
import { Form, FormItem, Checkbox, Message,Dialog} from 'element-ui'
export default {
  props: ['onLoginSuccessed'],
  components: {
    FormBase,
    ModalCompanyProperty,
    AsyncButton,
    'el-checkbox': Checkbox,
    'el-form': Form,
    'el-form-item': FormItem,
    ['el-dialog']: Dialog,
    
  },
  data() {
    return {
      uc: new LoginUc(LoginUc.byPhone, { onLoginFinish: this.onLoginSuccessed }),
    }
  },
  computed: {
    form() {
      return this.uc.form
    }
  },
  methods: {
    ...mapActions([
      'login'
    ]),
    onLogin() {
      let phoneForm= this.$refs.form.validate()
      phoneForm.catch((err)=>{
        console.log('err',err);
      })
      let checkedForm= this.$refs.formCheck.validate()
      checkedForm.catch(()=>{
        Message({
          type:'error',
          message:'请阅读用户须知',
          center:true,
          showClose:false,
          iconClass: 'login-phone',
          customClass: 'el-message--error login-phone'
        })
      })
      return Promise.all([
        phoneForm,checkedForm
      ])
      .then(valid => {
        return this.login(this.uc)
      })
      .catch(valid => {
      })
    },
  }
}
</script>

<style lang="less" scoped>
/deep/.dialog-firm{
  .el-dialog{
    height: auto;
  }
  .el-dialog__body{
    height: auto;
  }
}
.firm-con{
  display: flex;
  font-size: 12px;
  color: #2F3234;
  line-height: 20px;
  margin-top: 25px;
  /deep/.el-checkbox{
    margin-right: 9px;
  }
}
.firm-content{
  height: 50vh;
  overflow: scroll;
}
.submit{
  margin: 0 auto;
}
/deep/.el-checkbox__input{
  margin-top: -3px;
}
</style>
<style lang="less">
.login-phone{
  top: 0 !important;
}
.login-phone:not(:last-child){
  visibility: hidden !important;
}
</style>