<template>
  <div id="app">
    <router-view />
    <Foot v-if="showFooter" />
  </div>
</template>
<script>
import Foot from './views/foot/index.vue'
import installWxSDK, { updateAppMessageShareData } from "./share";
import { ROUTER_ACCOUNT_RESULT } from "@/router";
import request from "@/untils/request";
import { mapActions, mapState } from "vuex";
(function () {
  if (
    typeof WeixinJSBridge == "object" &&
    typeof WeixinJSBridge.invoke == "function"
  ) {
    handleFontSize();
  } else {
    document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
  }
  function handleFontSize() {
    // 设置网页字体为默认大小
    WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
    // 重写设置网页字体大小的事件
    WeixinJSBridge.on("menu:setfont", function () {
      WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
    });
  }
})();

export default {
  name: "App",
  provide(){
    return {
      app:this
    }
 },
  data() {
    return {
      activeIndex:0,
    
    }
  },
  components: {
    Foot,
  },

  computed: {
    showFooter() {
      return !/^\/(login|reset\-pwd)/.test(this.$route.path)
    },
    ...mapState(['tokenInfo'])
  },
  watch: {
    $route: function (to) {
      if (to && to.name === ROUTER_ACCOUNT_RESULT) {
        updateAppMessageShareData({
          link: window.location.href,
        });
      } else {
        updateAppMessageShareData();
      }
    },
  },
  mounted() {
    installWxSDK().then(() => updateAppMessageShareData());

    this.isLogin().then((login) => {
      if (login) {
        this.getUserInfo()
      }
    })
  },
  methods: {
    ...mapActions([
      'getUserInfo',
      'isLogin'
    ]),
  }
};
</script>

<style lang="less">
.van-toast {
  width: 10vw!important;
  height: 20vw !important;
}

.el-scrollbar {
    > .el-scrollbar__bar {
        opacity: 1 !important;
    }
}

</style>
