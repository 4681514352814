<template>
  <modal-like>
    <ResetPwd class="login-h5"></ResetPwd>
  </modal-like>
</template>

<script>
import ResetPwd from '#domain-member/view/reset-pwd'
import ModalLike from '@/views/layout/modal-like.vue'

export default {
  components: { ResetPwd, ModalLike },
}
</script>

<style lang="less" scoped>
/deep/.content{
  padding-top: 0;
}
</style>