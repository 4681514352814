<template>
  <div>
    <div class="step-con">
      <span class="step-item" v-for="step, i in uc.steps" :key="step.code" :class="{ active: step.code === uc.step }">
        <span class="break-line" v-if="i" >
          ----------
        </span>
        <span class="index">{{ i + 1 }}</span>
        <span class="title">{{ step.title }}</span>
      </span>
    </div>

    <Tabs v-model="uc.currentVerifyType" stretch v-if="uc.step === ResetPwd.STEP_VERF">
      <TabPane :label="uc.getVerifyWay(Verify.byPhone).title" :name="Verify.byPhone">
        <Form ref="formByPhone" :uc="uc.byPhone" />
      </TabPane>
      <TabPane :label="uc.getVerifyWay(Verify.byEmail).title" :name="Verify.byEmail">
        <FormByEmail ref="formByEmail" :uc="uc.byEmail" />
      </TabPane>
      <async-button :click="verifyUser">
        确认
      </async-button>
    </Tabs>
    <reset-pwd-form :uc="uc" v-if="uc.step === ResetPwd.SETP_RESET"/>

  </div>
</template>

<script>
import ResetPwd, { Verify } from '../uc/reset-pwd.uc'
import { Tabs, TabPane } from 'element-ui'
import Form from './phone-verf-form.vue'
import FormByEmail from './emial-verf-form.vue'
import AsyncButton from '../../../share/vue/async-button.vue'
import ResetPwdForm from './reset-pwd-form.vue'

export default {
  data() {
    return {
      uc: new ResetPwd(),
      ResetPwd,
      Verify,
      from: null,
    }
  },
  computed: {
    activeFormRef() {
      return {
        [Verify.byPhone]: 'formByPhone',
        [Verify.byEmail]: 'formByEmail',
      }[this.uc.currentVerifyType]
    }
  },
  components: {
    Tabs, 
    TabPane,
    Form,
    FormByEmail,
    AsyncButton,
    ResetPwdForm,
  },
  methods: {
    verifyUser() {
      return this.$refs[this.activeFormRef].validate()
        .then(() => this.uc.verify() )
        .then(() => this.uc.goFinishVerify())
    }
  }
}
</script>

<style lang="less" scoped>
@import './style/tab.less';
@import './style/global.less';
.flex-center-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-con{
  height: 66px;
  background: #F6F6F6;
  border-radius: 10px 10px 0px 0px;
  .flex-center-center;
  .step-item{
    .flex-center-center;
    font-weight: 500;
    color: #666666;
    font-size: 14px;
    .index{
      .flex-center-center;
      font-size: 12px;
      width: 20px;
      height: 20px;
      line-height: 17px;
      border-radius: 100%;
      background: #D8D8D8;
      margin-right: 6px;
    }
    .break-line{
      width: 61px;
      line-height: 17px;
      height: 17px;
      text-align: center;
      padding: 0 10px;
      color: #979797;
      overflow: hidden;
    }
  }
  .step-item.active{
    color: #417DFF;
    .title{
      font-weight: 600;
    }
    .index{
      color: #2E7DF4;
      background: rgba(65, 125, 255, 0.26);
      font-weight: 600;
    }
  }
}

</style>