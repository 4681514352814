<template>
  <div>
    <van-dialog
      class="dialog-bgi"
      v-model="isShow"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <p class="title">提示</p>
      <div class="dialog-content">
        <!-- <img src="@/assets/productIntro/vip-icon.png" alt="" class="dialog-img"> -->
        <span
          >感谢您对盟浪网的支持，平台为您提供服务试用资格，各项服务均可申请试用1次,试用有效期为{{
            tryDay
          }}天。</span
        >
      </div>
      <div v-for="item in serviceCodeList" :key="item.serviceCode">
        <van-row class="list-van-row">
          <van-col :span="16">
            <van-checkbox-group v-model="checkList">
              <van-checkbox
                class="list-van-checkbox"
                shape="square"
                :name="item.serviceCode"
                :disabled="item.isTryOut == 1 ? false : true"
                >{{ item.serviceName }}
              </van-checkbox>
            </van-checkbox-group>
          </van-col>
          <van-col :span="8">
            <div>
              {{ item.isTryOut == 1 ? '可申请' : '不可申请' }}
            </div>
          </van-col>
        </van-row>
      </div>
      <div class="dialog-btn-box">
        <van-button class="submit-btn-cancel" @click="handelCancel"
          >取消</van-button
        >
        <van-button class="submit-btn" @click="confirmApplication" :loading="disabled"
          >确认申请</van-button
        >
      </div>
    </van-dialog>
    <van-dialog
      class="dialog-bgi dialog-notice"
      v-model="isShowStatic"
      :show-cancel-button="false"
      :show-confirm-button="false"
      style="height:55.333vw;opacity: 0.9;"
    >
      <p class="title">提示</p>
      <div class="dialog-content">
        <img
          src="@/assets/productIntro/vip-icon.png"
          alt=""
          class="dialog-img"
        />
        <span>请前往盟浪网www.susallwave.com使用或购买!</span>
      </div>
      <div class="dialog-btn-box">
        <!-- <van-button class="submit-btn-cancel" @click="handelCancel"
          >确认</van-button -->
        
      </div>
    </van-dialog>
    <div v-if="!userToken">
      <button class="banner-btn" @click="showApplicance">
        申请试用碳和通
      </button>
    </div>
    <div v-else>
      <button class="banner-btn" v-if="authority === 0" @click="showApplicance">
        申请试用碳和通
      </button>
      <button class="banner-btn" v-else @click="showApplicance">
        进入碳和通
      </button>
    </div>
    <Header />
    <Swiper :imageLocation="imageLocation" />
    <!-- briefintro -->
    <div class="brief-intro">
      <div class="top-left">
        <div class="top-left-text">
          <span>产品简介</span>
        </div>
        <div class="top-line"></div>
        <div class="top-radius"></div>
      </div>

      <div class="product-intro">
        <span>{{ briefInfo }}</span>
      </div>
    </div>
    <div class="calc-function">
      <div class="top-left">
        <div class="top-left-text">
          <span>五大核算功能</span>
        </div>
        <div class="top-line" style="width: 58.7%"></div>
        <div class="top-radius"></div>
      </div>
      <div v-for="item in imgList" :key="item.url" class="calc-fun-item">
        <img :src="item.url" alt="" />
        <div class="item-title">{{ item.title }}</div>
        <div class="item-describe">{{ item.describe }}</div>
      </div>
    </div>
    <div class="character-function">
      <div class="top-left">
        <div class="top-left-text">
          <span>功能特点</span>
        </div>
        <div class="top-line"></div>
        <div class="top-radius"></div>
      </div>
      <div class="character-item" v-for="item in characterItem" :key="item.url">
        <img :src="item.url" alt="" />
        <div class="character-title">{{ item.title }}</div>
        <div class="character-describe">{{ item.describe }}</div>
        <div class="character-icon"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/views/components/head/index.vue'
import Swiper from '@/views/components/Swipe/swipe.vue'
import request from '@/untils/request'
import { mapGetters } from 'vuex'
export default {
  components: {
    Header,
    Swiper
  },
  computed: {
    ...mapGetters(['logined'])
  },
  data() {
    return {
      imageLocation: 'h5-碳和通首页banner',
      briefInfo:
        '碳和通--盟浪独立设计、研发的智能碳核算平台。内嵌国际、国家及地方层面权威碳排放核算和碳减排核算标准和方法学，按照国家标准优先、国际通行接轨、地方填补空白的原则，打造五个维度的碳排放、碳减排以及其他环境影响与效益核算功能，服务金融机构、供应链核心企业、大型集团公司以及其他企业、组织的绿色低碳发展。',
      imgList: [
        {
          url: require('@/assets/productIntro/5-cna/m3_5img1.jpg'),
          title: '绿色项目环境效益核算',
          describe: '核算金融机构绿色项目的环境效益和减排量'
        },
        {
          url: require('@/assets/productIntro/5-cna/m3_5img2.jpg'),
          title: '投融资活动环境影响和效益核算',
          describe:
            '核算金融机构投融资组合产生的温室气体排放和其他环境影响与效益'
        },
        {
          url: require('@/assets/productIntro/5-cna/m3_5img3.jpg'),
          title: '供应链/子公司环境影响和效益核算',
          describe: '核算供应商或者集团子公司产生的环境影响和效益'
        },
        {
          url: require('@/assets/productIntro/5-cna/m3_5img4.jpg'),
          title: '自身组织温室气体排放核算',
          describe:
            '核算组织自身运营过程中产生的温室气体排放量，含范围一至范围三'
        },
        {
          url: require('@/assets/productIntro/5-cna/m3_5img5.jpg'),
          title: '自身组织减排效益核算',
          describe: '核算组织自身运营减排措施的减排量和环境效益'
        }
      ],
      characterItem: [
        {
          url: require('@/assets/productIntro/5-cna/m3_5img6.png'),
          title: '权威标准',
          describe: '内嵌国际、国家和地区权威核算标准及方法学'
        },
        {
          url: require('@/assets/productIntro/5-cna/m3_5img9.png'),
          title: '多维核算',
          describe: '提供多达五个维度的碳排放和其他环境影响和效益核算'
        },
        {
          url: require('@/assets/productIntro/5-cna/m3_5img10.png'),
          title: '一键转发',
          describe: '独创识别码一键转发邀请价值链条主体填报'
        },
        {
          url: require('@/assets/productIntro/5-cna/m3_5img11.png'),
          title: '灵活填报',
          describe: '实现月度、季度和年度周期灵活填报'
        },
        {
          url: require('@/assets/productIntro/5-cna/m3_5img12.png'),
          title: '即时报告',
          describe: '实时获取填报数据即时生成报告'
        },
        {
          url: require('@/assets/productIntro/5-cna/m3_5img13.png'),
          title: '360°教程',
          describe: '提供详细“线上+线下”填报教程和指南'
        }
      ],
      userToken: false,
      isShow: false,
      tryDay: 0,
      isShowStatic: false,
      serviceCodeList: [],
      isService: true,
      checkList: [],
      disabled: false,
      authority: -1
    }
  },
  methods: {
    getServiceList() {
      request
        .post('/ml-member/user/memberuserservice/serviceList')
        .then(({ data }) => {
          if (data.code === 0) {
            this.serviceCodeList = data.data.slice(-5)
            this.isService = this.serviceCodeList.some(function (item) {
              return item.isTryOut == 1
            })
          }
        })
    },

    showApplicance() {
      if (this.userToken === false) {
        this.$router.push('/login')
        return
      } else {
        let show = this.serviceCodeList.every(function (item) {
          if (item.isTryOut == 1) return true
        })
        if (show) {
          this.isShow = true
          this.checkList = []
          this.getTryDays()
        } else {
          this.isShowStatic = true
          setTimeout(()=>{
            this.isShowStatic = false
          },2000)
        }
      }
    },
    getTryDays() {
      request
        .post('/ml-member/user/memberuserservice/getTryDays')
        .then(({ data }) => {
          if (data.code === 0) {
            this.tryDay = data.data
          }
        })
    },
    confirmApplication() {
      if (!this.checkList.length) {
        return this.$notify({
          message: '请选择要试用的产品！',
          type: 'warning'
        })
      }
      this.disabled = true
      request
        .post(
          '/ml-member/user/memberuserservice/tryOutByServiceCodes',
          this.checkList
            .map(
              (code) =>
                this.serviceCodeList.find((i) => i.serviceCode === code)
                  .serviceCode
            )
            .map((code) => ({ serviceCode: code }))
        )
        .then(({ data }) => {
          if (data.code === 0) {
            this.disabled = false
            this.isShow = false
            this.$notify({
              message: '恭喜您，申请使用成功!',
              type: 'success'
            })
          }
        })
      this.isShow = false
    },
    handelCancel() {
      this.isShow = false
      this.isShowStatic = false
    },
    isValid() {
      let list = [
        'SERVICE-006',
        'SERVICE-007',
        'SERVICE-008',
        'SERVICE-009',
        'SERVICE-010'
      ]
      for (let i = 0; i < list.length; i++) {
        request
          .post(
            '/ml-member/user/memberuserservice/getUserServiceStatusV2',
            null,
            { params: { serviceCode: list[i] } }
          )
          .then(({ data }) => {
            if (data.data !== 0) {
              this.authority = -1
            } else {
              this.authority = 0
            }
          })
      }
    }
  },
  mounted() {
    if (this.logined) {
      this.userToken = true
      this.isValid()
      this.getServiceList()
    }
  }
}
</script>

<style lang="less" scoped>
.banner-btn {
  position: absolute;
  background: linear-gradient(90deg, #4ec59e, #0ec1ca);
  color: #ffffff;
  top: 170px;
  left: 30px;
  padding: 8px;
  border-radius: 15px;
  width: 135px;
  border: none;
  z-index: 100;
}
.top-left {
  display: flex;
  height: 40px;
  margin-top: 10px;
  position: relative;
  .top-line {
    height: 10px;
    width: 258px;
    border-bottom: solid #979797 1px;
    opacity: 0.6;
    margin-left: 10px;
  }
  .top-radius {
    position: absolute;
    top: 6px;
    left: 358px;
    align-items: center;
    height: 8px;
    width: 8px;
    background-color: #979797;
    opacity: 0.6;
    border-radius: 100%;
  }
  .top-left-text {
    margin-left: 15px;
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    color: #333333;
    letter-spacing: 1px;
    font-weight: 600;
  }
}
.product-intro {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  text-align: justify;
  line-height: 28px;
  font-weight: 400;
  margin: 11px 0 15px 0;

  span {
    width: 91%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.calc-fun-item {
  margin: 0 16px 15px 16px;
  // margin-bottom: 15px;
  position: relative;
  img {
    width: 100px;
    margin: 10px;
  }
  .item-title {
    position: absolute;
    top: 16px;
    left: 134px;
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    text-align: justify;
    font-weight: 600;
    line-height: 22px;
  }

  .item-describe {
    position: absolute;
    top: 63px;
    left: 134px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #7f8aa4;
    letter-spacing: 0;
    font-weight: 400;
    line-height: 20px;
    margin-right: 9px;
  }
}
.character-item {
  height: 200px;
  position: relative;
  img {
    width: 200px;
    position: absolute;
  }
  .character-title {
    position: absolute;
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #333333;
    font-weight: 600;
  }
  .character-describe {
    width: 127px;
    position: absolute;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #333333;
    line-height: 20px;
    font-weight: 400;
  }
}

.character-item:nth-child(even) {
  img {
    top: 36px;
    left: 16px;
  }
  .character-title {
    top: 66px;
    left: 225px;
  }
  .character-describe {
    top: 96px;
    left: 225px;
  }
  .character-icon {
    position: absolute;
    width: 60px;
    height: 52.94px;
    background: url('@/assets/productIntro/5-cna/m3_5img7.png') no-repeat;
    bottom: 0px;
    right: 10px;
  }
}

.character-item:nth-child(odd) {
  background-image: linear-gradient(90deg, #ecf2e4 0%, #ddeff4 99%);
  img {
    top: 35px;
    left: 159px;
  }
  .character-title {
    top: 63px;
    right: 224px;
  }
  .character-describe {
    top: 93px;
    right: 224px;
    text-align: right;
  }

  .character-icon {
    position: absolute;
    width: 60px;
    height: 181.88px;
    background: url('@/assets/productIntro/5-cna/m3_5img8.png') no-repeat;
    left: 8px;
    bottom: 0px;
  }
}

.dialog-bgi {
  width: 90%;
  height: 470px;
  background-size: cover;

  .dialog-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    img {
      margin: 0 auto;
      width: 100px;
      height: 100px;
    }
    span {
      flex-wrap: wrap;
      margin-top: 10px;
      padding-left: 20px;
      line-height: 24px;
    }
  }

  .title {
    padding: 22px 0 0 22px;
    font-size: 4vw;
    font-family: PingFang SC;
    font-weight: bold;
    color: #3080f1;
    opacity: 1;
  }

  .dialog-btn-box {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .submit-btn-cancel {
      width: 35%;
      // height: 72px;
      background: #fff;
      opacity: 1;
      border-radius: 16px;
      font-size: 16px;
      color: #3080f1;
    }
    .submit-btn {
      margin-left: 20px;
      width: 35%;
      background: #3080f1;
      color: white;
      opacity: 1;
      border-radius: 16px;
      font-size: 16px;
    }
  }
}
.dialog-notice {
  height: 270px;
}

.list-van-row {
  margin-left: 20px;

  padding-top: 15px;
}
</style>
