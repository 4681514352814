import { Message } from 'element-ui';
import env from '../core/env';

export const message = Message;

export let encrypt;
if (typeof window !== 'undefined') {
  (async () => {
    const { default: JSEncrypt } = await import('jsencrypt');

    encrypt = function (str, pubKey) {
      let encryptStr = new JSEncrypt()
      encryptStr.setPublicKey(pubKey) // 设置 加密公钥
      let data = encryptStr.encrypt(str) // 进行加密
      return data
    }
  })();
}

// console.log(env.ssr)
// if (false) {
//   encrypt = function (str, pubKey) {
//     const NodeRSA = require('node-rsa');
//     var privateKey = new NodeRSA(pubKey, 'public', { encryptionScheme: 'pkcs1' }); // specify PKCS#1 v1.5 padding
//     return privateKey.encrypt(str).toString('base64');
//   }
// } else {
// }

export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}

export function debounce(fn, wait, immediate = true) {
  let timer;
  return function () {
      if (timer) clearTimeout(timer);
      if (immediate) {
          // 如果已经执行过，不再执行
          var callNow = !timer;
          timer = setTimeout(() => {
              timer = null;
          }, wait || 500)
          if (callNow) {
              return fn.apply(this, arguments)
          }
      } else {
          timer = setTimeout(() => {
              return fn.apply(this, arguments)
          }, wait || 500);
      }
  }
}