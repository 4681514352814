<template>
  <el-form
    v-if="form" :model="form" 
    hide-required-asterisk
    ref="form"
    :rules="form.rules"
    class="form-pwd"
  >
    <el-form-item label="新密码" prop="pwd">
      <el-input
        v-model.trim="form.pwd"
        placeholder="请输入新密码"
        clearable
        show-password
      >
      </el-input>
    </el-form-item>
    <el-form-item label="确认密码" prop="checkpwd">
      <el-input
        v-model.trim="form.checkpwd"
        placeholder="请输入确认密码"
        clearable
        show-password
      >
      </el-input>
    </el-form-item>
    <AsyncButton
      :click="submit"
    >
      确认
    </AsyncButton>
  </el-form>
</template>

<script>
import { Form, FormItem, Input, Select, Option, Button } from 'element-ui'
import AsyncButton from '../../../share/vue/async-button.vue'
import { mapGetters } from 'vuex'

export default {
  props: ['uc'],
  computed: {
    form() {
      return this.uc.resetNewPwd.form
    },
    ...mapGetters(['logined']),
  },
  components: {
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-select': Select,
    'el-option': Option,
    'el-button': Button,
    AsyncButton,
  },
  methods: {
    submit() {
      return this.$refs.form.validate().then(() => {
        return this.uc.submitResetPwd(this.logined)
      }).then(() => {
        return this.$router.push('/login')
      })
    },
  }
}
</script>

<style lang="less" scoped>
@import './style/form.less';
.form-pwd{
  padding: 20px;
}
</style>