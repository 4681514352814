const env = process.env.NODE_ENV
const config = {
  development: {
    baseURL: "https://39.103.202.122",
    uuiurl: "https://39.103.202.122",
  },
  production: {
    baseURL: "https://exp.susallwave.com",
    uuiurl: "https://exp.susallwave.com",
  }
}
export default {
  baseURL: config[env].baseURL,
  uuiurl: config[env].uuiurl,
}