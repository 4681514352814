import Vue from "vue"
import VueRouter from "vue-router"
import login from "@/views/Login"
import ResetPwd from "@/views/reset-pwd"
import sign from "@/views/Sign/sign.vue"
import toHome from "@/views/Home.vue"
import consultingNews from '@/views/consulting/list/index.vue'
import user from '@/views/user/index.vue'
import editUserPassWord from '@/views/user/edit-password.vue'
import dataConnect from '@/views/data-connect/index.vue'
import finesgConnect from '@/views/finesg-connect/index.vue'
//import risktrackerConnect from '@/views/risktracker-connect/newIndex.vue'
import risktrackerConnect from '@/views/risktracker-connect/newRiskindex.vue'
import riskNewsList from '@/views/risktracker-connect/components/newslist.vue'
import sustainabilityConnect from '@/views/sustainability-connect/index.vue'
import cnaConnect from "@/views/cna-connect/index.vue"
import cfaConnect from "@/views/cfa-connect/index.vue"
import dataBaseConnect from "@/views/database-connect/index.vue"
import apiDocument from "@/views/database-connect/api-document.vue"
import dataDictionars from "@/views/database-connect/data-dictionaries.vue"
import MessageDialog from "@/views/cfa-connect/components/message-submit-dialog.vue"
import consultingNewsDetails from '@/views/consulting/details/index.vue'
import consultingNewsList from '@/views/consulting/list/index.vue'
import store from '@/store/index.js'
import activityNine from '@/views/activity/nine-activity-page/index.vue'


Vue.use(VueRouter)
export const ROUTER_ACCOUNT_RESULT = "accounting-results"

const routes = [
  {
    path: "/",
    name: "toHome",
    component: toHome,
    meta: {
      title: "首页",
      requireAuthority: false, //那个页面需要权限就要加上这个属性让他为true说明需要权限,不是true说明是白名单里
      top: 0,
    },

  },
  {
    path: "/activity-page",
    name: "activityNine",
    component: activityNine,
    meta: {
      title: "活动页",
      requireAuthority: false, //那个页面需要权限就要加上这个属性让他为true说明需要权限,不是true说明是白名单里
      top: 0,
    },

  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: "登录页",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/reset-pwd",
    name: "reset-pwd",
    component: ResetPwd,
    meta: {
      title: "修改密码",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/sign",
    name: "sign",
    component: sign,
    meta: {
      title: "注册页面",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/consulting",
    name: "consulting",
    component: consultingNews,
    meta: {
      title: "资讯",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/consulting-list",
    name: "consultinglist",
    component: consultingNewsList,
    meta: {
      title: "资讯列表",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/consulting-details",
    name: "consultingdetails",
    component: consultingNewsDetails,
    meta: {
      title: "资讯详情",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/user",
    name: "user",
    component: user,
    meta: {
      title: "个人中心",
      requireAuthority: true,
      top: 0,
    },
  },
  {
    path: "/edit-userpassword",
    name: "edit-userpassword",
    component: editUserPassWord,
    meta: {
      title: "修改登录密码",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/data-connect",
    name: "data-connect",
    component: dataConnect,
    meta: {
      title: "数据通",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/finesg-connect",
    name: "finesg-connect",
    component: finesgConnect,
    meta: {
      title: "义利通",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/risktracker-connect",
    name: "risktracker-connect",
    component: risktrackerConnect,
    meta: {
      title: "避雷通",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/risktracker-newsList",
    name: "finesg-newsList",
    component: riskNewsList,
    meta: {
      title: "避雷通新闻列表",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/sustainability-connect",
    name: "sustainability-connect",
    component: sustainabilityConnect,
    meta: {
      title: "信披通",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/cna-connect",
    name: "/cna-connect",
    component: cnaConnect,
    meta: {
      title: "碳和通",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/cfa-connect",
    name: "/cfa-connect",
    component: cfaConnect,
    meta: {
      title: "碳融通",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/cfa-connect/components/message",
    name: "/cfa-connect/components/message",
    component: MessageDialog,
    meta: {
      title: "留言咨询",
      requireAuthority: false,
      top: 0,
    },
    
  },
  {
    path: "/database-connect",
    name: "/database-connect",
    component: dataBaseConnect,
    meta: {
      title: "数据库",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/database-apidocument",
    name: "/database-apidocument",
    component: apiDocument,
    meta: {
      title: "api文档",
      requireAuthority: false,
      top: 0,
    },
  },
  {
    path: "/data-dictionaries",
    name: "/data-dictionaries",
    component: dataDictionars,
    meta: {
      title: "数据字典",
      requireAuthority: false,
      top: 0,
    },
  },
]

const router = new VueRouter({
  mode: "history",
  // base: process.env.VUE_APP_CNA_DOMAIN_NAME,
  routes,
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}
router.beforeEach((to, from, next) => {
  next()
  // if (to.meta && to.meta.requireAuthority == true) {
  //   const tokenStr = store.state.tokenInfo.token
  //   if (tokenStr) {
  //   } else {
  //     next("/login?pre=" + to.fullPath)
  //   }
  // } else {
  //   next()
  // }
})
router.afterEach((to) => {
  if (to.meta && to.meta.top == 0) {
    Vue.nextTick(function () {
      window.scrollTo(0, to.meta.top)
    })
  }
})

export default router
