<template>
  <div class="dialogBox">
    <el-dialog title="申请试用" :visible="isShow" @close="closeDialog">
      <el-form ref="form" :model="form" :rules="rules">
        <div class="bgcform">
          <el-form-item prop="name">
            <el-input v-model="form.name" placeholder="姓名*"></el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input v-model="form.phone" placeholder="联系电话*"></el-input>
          </el-form-item>
          <el-form-item prop="companyEmail">
            <el-input
              v-model="form.companyEmail"
              placeholder="公司邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item prop="companyName">
            <el-input
              v-model="form.companyName"
              placeholder="公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="position">
            <el-input v-model="form.position" placeholder="职务"></el-input>
          </el-form-item>
        </div>

        <div class="nobgc">
          <div class="title">关注领域 *（多选）</div>
          <el-form-item prop="focusAreas" class="multiple" ref="focusAreas">
            <div
              v-for="item in focusAreas"
              :key="item.dictValue"
              class="checkbox"
            >
              <el-checkbox-group v-model="form.focusAreas">
                <el-checkbox :label="item.dictValue">
                  {{ item.dictLabel }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item prop="others" class="other" ref="other">
            <el-input v-model="form.others" placeholder="其他"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit('form')">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { debounce } from "../../../untils/common-method.js"
import request from '@/untils/request'
import { Dialog, Form, FormItem, Input, Message, CheckboxGroup, Checkbox, Button } from 'element-ui'
export default {
  name: 'dialog-form',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
  },
  components: {

    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Dialog.name]: Dialog,
    [Button.name]: Button,

    [Message.name]: Message,

  },
  watch:{
    'form.focusAreas'(newV,oldV){
      if(newV.length>0){
        this.$refs.other.clearValidate('others')
      }
    }
  },
  data () {
    var validatePhone = (rule, value, callback) => {
      let reg = /^1[3456789]\d{9}$/
      if (!value) {
        return callback(new Error('请填写手机号'))
      }
      setTimeout(() => {
        if (!reg.test(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }, 100)
    }
    var validateCompanyEmail = (rule, value, callback) => {
      let mailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      if (!value) {
        return callback()
      } else {
        if (!mailReg.test(value)) {
          callback(new Error('请填写正确的邮箱格式'))
        } else {
          callback()
        }
      }
    }
    var validateFocusArea = (rule, value, callback) => {
      if (this.form.focusAreas.length === 0 && !this.form.others) {
        this.$refs.form.clearValidate('focusAreas')
        callback(new Error('请至少填写或选择一项关注领域！'))
      } else {
       callback()
      }
    }
    return {
      form: {
        name: '',
        phone: '',
        companyEmail: '',
        companyName: '',
        position: '',
        focusAreas: [],
        others: ''
      },
      // 表单验证规则
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
          { max: 50, message: '最大可输入字数长度为50', trigger: 'blur' }
        ],
        phone: [
          { required: true, type: 'number', validator: validatePhone, trigger: 'blur' }
        ],
        companyEmail: [
          { required: false, validator: validateCompanyEmail, trigger: 'blur' },
          { max: 100, message: '最大可输入字符长度为100', trigger: 'blur' }
        ],
        companyName: [
          { max: 100, message: '最大可输入字数长度为100', trigger: 'blur' }
        ],
        position: [
          { max: 100, message: '最大可输入字数长度为100', trigger: 'blur' }
        ],
        focusAreas: [
          { type: 'array', required: true, validator: validateFocusArea, trigger: 'blur' }
        ],
        others: [
          { required: true,validator: validateFocusArea, trigger: 'blur' },
          { max: 255, message: '最大可输入字数长度为255', trigger: 'blur' }
        ]
      },
      focusAreas: []
    }
  },

  methods: {
    closeDialog () {
      this.$refs.form.resetFields()
      this.$emit("close")
    },
    // debounce
    submit :debounce(function (form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          request.post('/ml-finesg/finesgConfV2/applyTrial',
            {
              name: this.form.name,
              phone: this.form.phone,
              companyEmail: this.form.companyEmail,
              companyName: this.form.companyName,
              position: this.form.position,
              focusAreas: this.form.focusAreas.toString(),
              other: this.form.others
            }).then(res => {
              const { data } = res
              if (data.code === 0) {
                Message({ message: '提交成功', type: 'success' })
              }
            })
            this.closeDialog()
        }
      })
    },200)
  },
  mounted () {
    request.post('/ml-admin/sys/dict/type/selectByDictType', { dictType: "focusAreas" })
      .then(res => {
        this.focusAreas = res.data.data.dataList
      })
  }
}
</script>

<style lang="less" scoped>
.dialogBox {
  /deep/ .el-dialog {
    width: 345px;
    margin-top: 0vw !important;
    position: fixed; 
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    .el-dialog__header {
      width: 100%;
      height: 45px;
      background: #0087ff;
      display: flex;
      align-items: center;
      padding: 0;
      padding-left: 138px;
    }
    .el-dialog__headerbtn {
      height: 45px;
      top: 0px;
      right: 28px;
      font-size: 16px;
    }
    .el-icon-close::before {
      color: #ffffff;
    }

    .el-dialog__title {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      justify-content: center;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }
    .el-dialog__title::before {
      display: none;
    }
  }
  .el-form {
    margin: 0 auto;
  }
  /deep/.el-input__inner {
    height: 35px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
  }
 /deep/ .el-input{
   height: 35px;
 }
  /deep/ .el-dialog__body {
    padding-bottom: 15px;
    padding-left: 0px;
    padding: 0px;
    padding-top: 0px;
    background: #F5F6F8;
  }

  /deep/.el-dialog__footer {
    padding-top: 0;
    padding-bottom: 21px;
  }
  .dialog-footer {
    margin: 0 auto;
    padding-top: 0px;
    .el-button {
      width: 100%;
      height: 44px;
      background: #287EDE;
      border-radius: 4px;
    }
  }
  /deep/ .el-form-item{
    margin-bottom: 19px;
  }
  /deep/.el-form-item__content {
    display: flex;
    flex-wrap: wrap;
    .checkbox {
      padding-right: 10px;
      margin-bottom: 10px;
    }
  }
}
.other{
  margin-top: -4px;
  margin-bottom: 20px;
}
.multiple{
  margin-bottom: 0px !important;
  /deep/.el-form-item__content{
    line-height: 16px;
  }
}
.nobgc{
  padding-left: 19px;
  padding-right: 18px;
  background: #FFFFFF;
  overflow: hidden;
  .title{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777982;
    line-height: 20px;
    margin-top: 11px;
    margin-bottom: 12px;
  }
}
.bgcform{
  padding-top: 19px;
  padding-left: 19px;
  padding-right: 18px;
}
</style>