<template>
    <div class="new-tabs">
			<Navbar/>
    </div>
</template>

<script>
	import Navbar from '../../components/navbar/index'
  export default {
		components: {
			Navbar,
		},
        
  }
</script>

<style lang="less" scoped>
  .new-tabs{
    margin-top: 10px;
}

</style>