<template>
  <div>
		<!-- fixed 固定到最下方, active默认显示哪一个 change切换时会触发这个事件  vant组件 tab调路由 如果用 name 那么name 是路由的name 把route去掉就可以实现 不跳转也高亮-->
    <van-tabbar fixed  v-model="activeIndex" @change="handleChange">
      <van-tabbar-item 
				v-for="(item, index) in tabbars" 
				:icon="item.name" 
				:key="index"
				@click="popUpProductList(item)" 
        >

				<van-popover
				  class="popover"
				  placement="top"
				  v-if="item.isProduct"
				  :close-on-click-outside="false"
          v-model="showPopover"
          trigger="click"
          :actions="actions"
          @select="onSelect"
          :offset=[-1,36]
          overlay
          >
          <template #reference  style="margin-top:-100px">
            <span :class="activeIndex === index ? 'active':''">{{ item.title }}</span>
          </template>
        </van-popover>
          <div v-else>
				    <span :class="activeIndex === index ? 'active':''">{{ item.title }}</span>
			    </div>
			      <template slot="icon" slot-scope="props">
              <img :src="props.active ? item.active : item.normal">
            </template>
      </van-tabbar-item>
    </van-tabbar> 
  </div>
</template>

<script>
 const fromdetailsToNews=1
 export default {
  name: 'TabBar',
  inject:['app'],
  props: {
    tabbars: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      fromdetailsToNews,
      // activeIndex:0,
      isRouteNews:false,
      currentActiveBar:[
        {
          id:0,
          title:"首页",
          path:"/"
        },
        {
          id:1,
          title:"资讯",
          path:"/consulting"
        },
        {
          id:2,
          title:"产品",
          path:"#"
        },
        {
          id:3,
          title:"个人中心",
          path:"/user"
        },

      ],
      productActivePath:[
        { id:0,text: '数据通',path:'/data-connect' }, 
        { id:1,text: '义利通',path:"/finesg-connect"}, 
        { id:2,text: '避雷通',path:"/risktracker-connect" }, 
        { id:3,text: '信披通',path:"/sustainability-connect" },
        { id:4,text: '碳和通',path:"/cna-connect" },
        { id:4,text: '碳融通',path:"/cfa-connect" }
      ],
			activeClass:false,
			showPopover: false,
      actions: [{ text: '盟浪碳融通',path:"/activity-page" },{ text: 'FIN-ESG数据库',path:'/database-connect' }],
    }
  },
   watch: {
    $route: function (to) {
      this.getActiveIndex(to)
      this.getProductActive(to)
    },
    deep:true
  },
 
  computed: {
    activeIndex:{
     get(){
        switch (this.$route.path) {
        case "/":
          return 0;
        case "/consulting":
          return 1;
        case "#":
          return 2;
        case "/data-connect":
          return 2;
        case "/finesg-connect":
          return 2;
        case "/risktracker-connect":
          return 2;
        case "/risktracker-newsList":
          return 2;
        case "/sustainability-connect":
          return 2;
        case "/cna-connect":
          return 2;
        case "/cfa-connect":
          return 2;
        case "/database-connect":
          return 2;
        case "/database-apidocument":
          return 2;
        case "/data-dictionaries":
          return 2;
        case "/activity-page":
          return 2
        case "/user":
          return 3;
        default:
          break;
      }
      },
      set(){}
    }
  },
  methods: {
    handleChange(value) {
      this.active=value
      this.$emit('change', value)
      this.currentActiveBar.forEach((item)=>{
        if(value!==2 && item.id==value){
          this.$router.push(item.path)
        }
      })
    },
		popUpProductList(item){
			if(item.isProduct){
				this.activeClass=item.isProduct
				this.showPopover=true
			} else {
				this.activeClass=false
				this.showPopover=false
			}
		},
		onSelect(action) {
      this.$router.push(action.path)
    },
    getActiveIndex(to){
      this.currentActiveBar.forEach((item,index)=>{
        if(to && String(to.fullPath).indexOf(item.path)!=-1){
          this.activeIndex=index
        }
      })
      return  this.activeIndex
    },
    getProductActive(to){
      this.productActivePath.forEach((item,index)=>{
        if(to && String(to.fullPath).indexOf(item.path)!=-1){
          this.activeIndex=2
        }
      })
      return  this.activeIndex
    },
  }
}
</script>

<style lang="less" scoped>
.active{
	color:#1989fa
}
/deep/.van-popover__action-text .van-hairline--bottom{
 width: 42px;
 font-family: PingFangSC-Regular;
font-size: 14px;
color: #333333;
letter-spacing: 0;
text-align: justify;
font-weight: 400;
}
/deep/ .van-popover__action{
  height: 31px !important;
  line-height: 31px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
/deep/ .van-popover__action-text .van-hairline--bottom{
  height: 31px !important;
}
</style>