<template>
  <el-form
    :model="form"
    :rules="form.rules"
    label-position="top"
    class="demo-ruleForm"
    v-if="form"
    ref="form"
    hide-required-asterisk
  >
    <el-form-item :label="form.getLabel('account')" prop="account">
      <el-input
        v-model.trim="form.account"
        :placeholder="form.getPlaceHolder('account')"
        class="form-i"
        :maxlength="form.maxLen"
      >
        <div slot="prefix" class="bgc-user bgc-u-1"></div>
      </el-input>
    </el-form-item>
    <el-form-item :label="form.getLabel('pwd')" prop="pwd">
      <el-input
        v-model.trim="form.pwd"
        show-password
        :placeholder="form.getPlaceHolder('pwd')"
        class="form-i"
        :maxlength="form.maxLen"
        @keyup.enter.native="onLogin"
      >
        <div slot="prefix" class="bgc-user bgc-u-2"></div>
      </el-input>
    </el-form-item>
    <div class="pwd-con">
      <a href="/reset-pwd">忘记密码</a>
    </div>
    <AsyncButton
      class="submit"
      :click="() => onLogin()"
    >
      登录
    </AsyncButton>
    <div class="re-con">
      <span>还没有账号?</span>
      <a @click="goByAccount">立即注册</a>
    </div>
    <ModalCompanyProperty :visible="form ? form.promptCompanyProperty : false" :close="onLoginSuccessed"/>
  </el-form>
</template>

<script>
import LoginUc from '../uc/login.uc'
import ModalCompanyProperty from './modal-company-property.vue'
import { Form, FormItem, Input, Select, Option, Button } from 'element-ui'
import AsyncButton from '../../../share/vue/async-button.vue'
import { mapActions } from "vuex";

export default {
  props: ['goByAccount', 'onLoginSuccessed'],
  data() {
    return {
      form: null,
      uc: new LoginUc(LoginUc.byAccount, { onLoginFinish: this.onLoginSuccessed }),
    }
  },
  components: {
    ModalCompanyProperty,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-select': Select,
    'el-option': Option,
    'el-button': Button,
    AsyncButton,
  },
  mounted() {
    this.uc.fetchForm().then(form => {
      this.form = form
    })
  },
  methods: {
    ...mapActions([
      'login'
    ]),
    onLogin() {
      return this.$refs.form.validate().then(valid => {
        return this.login(this.uc)
      })
    },
  }
}
</script>

<style lang="less" scoped>
@import './style/form.less';
</style>