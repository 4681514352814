import Api from '#susallwave-base/core/api'

export class ConsultingNews {
  static loading=true //是否开启加载更多
  // static isLoading=false
  constructor(consultNewsTabList,tabcoulmnId,newsList,pullorDownList,isLoading,loading,finished,itemDetailsNewsInfo,page){
    this.consultNewsTabList=consultNewsTabList // 这个是咨询的tabList 
    this.tabcoulmnId=tabcoulmnId, //这个是咨询tab栏的id
    this.newsList=newsList || []//咨询下面的列表
    this.pullorDownList=pullorDownList//下拉或者上拉得到的数组
    this.isLoading=isLoading || false//是否加载更多
    this.loading=loading  || true //loading 是 list 里面的是否在加载数据 
    this.finished=finished || false //是否加载完成
    this.itemDetailsNewsInfo=itemDetailsNewsInfo
    this.page=page || 1
  }
  getTabs(){
    return new Api().post('/ml-product/index/news/columnList').then((res)=>{
      this.consultNewsTabList=res
    })
  }
  getNewsList(isPullDown,page,limit,coulmnId){
    //isPullDown 是来区分是不是下拉 true是下拉
    return new Api().post('/ml-product/index/news/queryNewsPageByParam',{page:page,limit:limit,coulmnId:coulmnId}).then((res)=>{
      // this.newsList=res.list
      if(isPullDown){
        this.newsList=[...res.list,...this.newsList] //下拉的数据
      } else {
        this.newsList = [...this.newsList, ...res.list]
      }
      if( (res.total/res.pageSize)>res.pageNo ){
        let page=page+1
        if(isPullDown){
          this.newsList = [...res.list, ...this.newsList]
        } else {
          this.newsList = [ ...this.newsList,...res.list,]
      }
     } else {
        let page=page
     }
        this.loading = false
        this.isLoading = false
        if((res.total/res.pageSize)<=res.pageNo){
          return  this.finished = true
        }
    })
  }
  //初始化获取新闻列表的方法
  initNewsList(isPullDown,page,limit,coulmnId){
    this.getNewsList(isPullDown,page,limit,coulmnId)
  }
  
  //更新是否开启加载更多的方法
  updataLoading(boolean){
    ConsultingNews.loading=boolean
  }
  //根据tab索引 获取tabid
  getTabindexAcquirecoulmnId(index){
    this.consultNewsTabList.forEach((item,i)=>{
      if(i==index){
        this.tabcoulmnId=item.id
      }
    })
    return  this.tabcoulmnId
  }
  getConsultingNewsDetails(coulmnId,id){
    return new Api().post('/ml-product/index/news/queryNewsInfoById',{
      coulmnId:coulmnId || null ,
      id:id,
      page:1,
      limit:10,
    }).then((res)=>{
      this.itemDetailsNewsInfo=res || {}
    })
  }
}

