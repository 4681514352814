<template>
  <div>
    <Table :detailsTable="detailsTable"/>
  </div>
</template>

<script>
import Table from './component/tables.vue'
export default {
  name: "apiList",
  props: {
    detailsTable: {
      type: Object,
      default: ()=>({})
    },
  },
  components: {
    Table,
  },
}
</script>

<style lang="less" scoped>
</style>