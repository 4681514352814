<template>
  <div>
    <form action="/">
      <van-search
        v-model="value"
        placeholder="请输入企业名称关键词"
        @search="onSearch"
        @clear="onCancel"
        @input="showSearchList"
      >
      </van-search>
    </form>
    <div class="modal-box" v-show="isShow">
      <div v-for="item,i in isShowEnter" :key="i">
        <p class="modal-box-item" @click="enterDetails(item)">{{ item.companyName }}</p>
      </div>
    </div>
    <div v-if="Object.keys(companyForm).length>0" class="result-content">
      <div class="enterprise-box">
        <div class="box-title">
          <span class="title-icon" :class="companyForm.status == '90001' ? 'green' : (companyForm.status == '90003' ? 'may-green' : (companyForm.status == '90002' ? 'no-green' : 'no-know'))">
            {{companyForm.status == '90001' ? '绿色' : (companyForm.status == '90003' ? '可能绿' : (companyForm.status == '90002' ? '非绿' : '未判别'))}}
          </span>
          <span @click="openTitle" :class=" isOpenTitle ? 'title-name' : 'openTitle'">
            {{companyForm.companyName}}
          </span>
        </div>
        <div class="content-detail">
          <div class="detail-box">
            <p class="detail-industry detail-text">
              所在行业&nbsp;&emsp;
              <span class="line-right-detail">
                {{companyForm.industry}}
              </span>
            </p>
            <p class="detail-status">
              工商状态&nbsp;&emsp;
              <span class="detail-status-content">
                {{companyForm.businessStatus}}
              </span>
            </p>
            <div class="detail-text">
              <div style="display: flex">
                <span class="lh-all" :class="isShowAll ? 'detail-introduction' : 'detail-introduction-open'" >企业简介&nbsp;&emsp;</span>
                <span class="line-right-detail" :class="isShowAll ? 'detail-introduction' : 'detail-introduction-open'">
                  {{companyForm.introduction || '-'}}
                </span>
                <div class="arrow-box" v-if="companyForm.introduction">
                  <div v-if="isShowAll" @click="openOrCloseText" class="down">
                    <img src="../../../assets/cfa/arrow-down.svg" alt="">
                  </div>
                  <div v-else @click="openOrCloseText" class="up">
                    <img src="../../../assets/cfa/arrow-up.svg" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="detail-range detail-text">
              <div style="display: flex">
                <span class="lh-all" :class="isShowAllBusinessScope ? 'detail-introduction' : 'detail-introduction-open'" >经营范围&nbsp;&emsp;</span>
                <span class="line-right-detail" :class="isShowAllBusinessScope ? 'detail-introduction' : 'detail-introduction-open'">
                  {{companyForm.businessScope || '-'}}
                </span>
                <div class="arrow-box" v-if="companyForm.businessScope">
                  <div v-if="isShowAllBusinessScope" @click="openOrCloseBusinessScope" class="down">
                    <img src="../../../assets/cfa/arrow-down.svg" alt="">
                  </div>
                  <div v-else @click="openOrCloseBusinessScope" class="up">
                    <img src="../../../assets/cfa/arrow-up.svg" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-btn-box">
            <button @click="show" class="content-btn">
              获取联系方式
            </button>
          </div>
        </div>
      </div>
      <div class="statement">
        免责声明： <br>
        盟浪碳融通平台绿色企业识别仅供参考，数据来源以企业公开信息为主。
      </div>
    </div>
    <div v-else class="result-content data-null">
      <img src="../../../assets/cfa/bgi-cfa-search.png" alt="">
      <p class="data-null-msg">
        输入信息，免费获取智能识别结果！
      </p>
    </div>
  </div>
</template>

<script>
import { Search } from "vant";
import debounce from 'lodash/debounce'
import request from '@/untils/request'
import {getSearchData} from '#domain-cfa/cfa-tabs.js';
  export default {
    components: {
      [Search.name]: Search
    },
    data() {
      return {
        value: '',
        getSearchData,
        isShowEnter: [],
        isShow: false,
        companyForm: {},
        isShowAll: true,
        isShowAllBusinessScope: true,
        isOpenTitle: true
      }
    },
    created () {		
      
    },
    methods: {
      onSearch(val) {
        this.showSearchList(val)
      },
      onCancel() {
        this.value = ''
        this.companyForm = {}
      },
      show() {
        this.$router.push('/cfa-connect/components/message')
      },
      enterDetails(item) {
        this.value = item.companyName
        this.isShow = false
        this.companySubmit(this.value)
      },
      showSearchList: debounce (
        function (val) {
          if(!val) {
            this.isShow = false
            return
          }
          let data = {
            value: val,
            field: 'companyName',
            operator: 'like'
          }
        request.post('/ml-carfinance/cf/green/fuzzyQuery',data).then(res=> {
          this.isShowEnter = res.data.data.bizData
          if(this.isShowEnter.length > 0 ) {
            this.isShow = true
          }
        })
      },300),
      companySubmit(val) {
        this.isShowEnter.forEach(item=> {
          if(item.companyName == val)  {
            request.post('/ml-carfinance/cf/green/query',{uniformCreditCode:item.uniformCreditCode}).then(res=> {
            this.companyForm = {...this.companyForm,...item}
            this.companyForm.status = res.data.data.bizCode
          })
          }
        })
      },
      openOrCloseText() {
        this.isShowAll = !this.isShowAll
      },
      openOrCloseBusinessScope() {
        this.isShowAllBusinessScope = !this.isShowAllBusinessScope
      },
      openTitle() {
        this.isOpenTitle = !this.isOpenTitle
      }
    },
  }
</script>

<style lang="less" scoped>
  .search-btn {
    width: 60px;
    height: 28px;
    text-align: center;
    background: #417DFF;
    border-radius: 4px;
    border: none;
    color: #fff;
    line-height: 28px;
  }
  .result-content {
    min-height: 600px;
    height: 100%;
    width: 100%;
    background: #f4f4f4;
    padding: 20px 16px;
    overflow: hidden;
  }
  .data-null {
    text-align: center;
    padding-top: 100px;
    .data-null-msg {
      text-align: center;
      margin-top: 23px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  .enterprise-box {
    width: 343px;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 0 10px;
    padding-bottom: 15px;
    .box-title {
      width: 322px;
      border-bottom: 1px solid #F7F7F7;
      position: relative;
      min-height: 40px;
      padding-top: 8px;
      .title-icon {
        display: inline-block;
        height: 20px;
        border-radius: 4px;
        margin-top: 13px;
        text-align: center;
        padding: 0 3px;
        padding-top: 3px;
        margin-right: 10px;
        position: absolute;
        top: 0;
      }
      .title-name {
        display: inline-block;
        width: 192px;
        font-size: 16px;
        margin-left: 55px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 32px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .content-detail {
    padding: 0 5px;
    margin-top: 20px;
    .detail-text {
      margin-top: 20px;
      display: flex;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 12px;
    }
    .content-btn-box {
      text-align: center;
    }
    .content-btn {
      border:  none;
      color: #fff;
      margin-top: 47px;
      width: 167px;
      height: 40px;
      background: #417DFF;
      border-radius: 4px;
    }
  }
  .statement {
    width: 322px;
    height: 51px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
    margin-top: 16px;
    margin-left: 10px;
  }
  .modal-box {
    position: absolute;
    background: #fff;
    width: 100%;
    top: 97px;
    max-height: 400px;
    overflow: scroll;
    box-shadow: 0px 13px 14px 10px #edf3ff;
    border: 5px solid #fff;
    border-top: none;
    transition: all .5s;
    z-index: 9;
    .modal-box-item {
      margin: 10px 0;
      padding: 5px 10px;
      font-size: 14px;
      color: #606266;
      cursor: pointer;
    }
    .modal-box-item:hover {
      background: #edf3ff;
    }
  }
  .detail-introduction {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .detail-introduction-open {
    line-height: 20px;
  }
  .openTitle {
    display: inline-block;
    width: 192px;
    margin-left: 55px;
    line-height: 22px;
  }
  .detail-status {
    display: inline-block;
    margin-top: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 12px;
  }
  .detail-status-content {
    display: inline-block;
    width: 48px;
    height: 22px;
    background: #DFE9FF;
    border-radius: 4px;
    border: 1px solid #417DFF;
    color: #417DFFFF;
    text-align: center;
    line-height: 20px;
  }
  .green {
    color: #0EB37CFF;
    background: rgba(15,212,146,0.2400);
  }
  .may-green {
    color: #09A3C2FF;
    background: rgba(15,179,212,0.2100);
  }
  .no-green {
    color: #245FD4FF;
    background: rgba(36,95,212,0.2500);
  }
  .no-know {
    background: #E2E5EA;
    color: #858585FF;
  }
  .line-right-detail {
    color: #333;
    display: inline-block;
    width: 220px;
    vertical-align: middle;
    word-break:break-all;
    padding-top: 3px;
  }
  .arrow-box {
    margin-left: 5px;
  }
  .lh-all {
    display: inline-block;
    height: 100%;
    padding-top: 3px;
  }
</style>