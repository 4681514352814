<template>
  <div class="login-container">
    <div class="top-text-description">
      <!-- 放logo -->
      <div class="logo"></div>
      <div class="welcome-text">
        <div class="hello">您好!</div>
        <div class="textinformation">欢迎使用盟浪&nbsp;碳和通</div>
      </div>
    </div>
    <!-- 登录的表单 -->
    <div class="form">
      <van-form @submit="onSubmit" @failed="onFailed">
        <div class="form-item-border mobileArea" ref="mobileArea">
          <van-field
            class="input-height"
            :autosize="true"
            v-model="form.mobile"
            type="tel"
            placeholder="请输入手机号"
            required
            :rules="[
              { required: true, message: '请填写手机号' },
              { pattern: /^1\d{10}$/, message: '请填写正确的手机号' },
            ]"
          />
        </div>
        <div class="form-item-border">
          <van-field
            class="input-height"
            :autosize="true"
            v-model="form.companyName"
            placeholder="请输入所在单位名称"
            required
            :rules="[{ required: true, message: '请输入所在单位名称' }]"
          />
        </div>
        <div style="display: flex">
          <div class="form-item-border imgcode">
            <van-field
              class="input-height"
              v-model="form.imgCode"
              placeholder="请输入图片验证码"
              required
              :rules="[{ required: true, message: '请填写图片密码' }]"
            >
            </van-field>
          </div>

          <van-button
            class="messagecodeboxs"
            native-type="button"
            @click="getCaptcha"
          >
            <div class="imgs">
              <img @click="getCaptcha" :src="captchaUrl" />
            </div>
          </van-button>
        </div>
        <div style="display: flex">
          <div class="form-item-border imgcode">
            <van-field
              class="input-height"
              v-model="form.messageCode"
              placeholder="请输入短信验证码"
              required
              :rules="[{ required: true, message: '请填写短信密码' }]"
            >
            </van-field>
          </div>
          <van-button
            :disabled="disabledButton"
            class="messagecodebox"
            native-type="button"
            @click="acquireCountDown"
          >
            {{ messageTips ? countDown : "获取验证码" }}</van-button
          >
        </div>
        <!-- 有没有账号,记不记住账号的文字说明 -->
        <div class="accountTextdescription">
          <div class="accountTextdescription-text">
            <span @click="$router.push('/login')" class="isAccountNumber"
              >已有账号?&nbsp;<span class="atoncelogin">立即登录</span></span
            >
          </div>
        </div>
        <!-- 留言机器人 -->
        <div class="message-robot">
          <div class="customer-service">
            <div class="contact" @click="dialogShow">
              <img src="@/assets/contact.png" alt="" />
            </div>
            <div
              @click="dialogShow"
              class="contact-msg"
              v-if="isShowContactMsg"
            >
              <img src="@/assets/contactMsg.png" alt="" />
            </div>
          </div>
        </div>
        <!-- 登录 -->
        <div class="loginInformation">
          <van-button
            class="login-btn"
            round
            block
            color="linear-gradient(to right, #50D9B0, #22C29E)"
            native-type="submit"
            >注册并登录</van-button
          >
        </div>
        <div class="measureResults">登录后查看您的测算结果</div>
      </van-form>
    </div>
    <DialogModel
      :isShowDialog="isShowDialog"
      :dialogClose="dialogClose"
      :isShowTextMessage="isShowTextMessage"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { debounce } from "../../untils/common-method.js";
import { messageMobileCode, toSign } from "@/api/userInfo.js";
import { getUUID, searchLength, telSelects } from "../../untils/from-length.js";
import { Notify } from "vant";
import DialogModel from "../components/dialog.vue";
const RETURN_ERR_CODE = 10007;
export default {
  name: "ToLogin",
  components: {
    DialogModel,
  },
  data() {
    return {
      isShowContactMsg: true,
      isShowDialog: false,
      disabledButton: false,
      typeStyle: "mobile", //获取短信验证码和登录时需要的
      nationalCode: "86",
      email: "",
      templateCode: 2,
      overlay: false, //控制子组件是否显示遮罩
      telSelect: "", //选择的市区
      telSelects,
      searchLength,
      uuid: "",
      captchaUrl: "",
      timer: null,
      countDown: 60, //倒计时
      isClickcountDown: false, //有没有点击获取短信邀请码
      messageTips: false,
      form: {
        mobile: "",
        imgCode: "",
        messageCode: "",
        companyName: "",
      },
      //个人登录接口需要的参数
      accountType: 1,
    };
  },
  created() {
    this.form = {};
    this.getCaptcha();
    setTimeout(() => {
      this.isShowContactMsg = false;
    }, 5000);
  },
  computed: {
    ...mapState(["tokenInfo"]),
  },
  methods: {
    dialogShow() {
      this.isShowDialog = true;
    },
    dialogClose() {
      this.isShowDialog = false;
    },
    ...mapMutations([
      "updateTokenInfo",
      "updateUserMobile",
      "updateCompanyName",
    ]),
    // 获取短信验证码的方法
    async getMessageMobileCode() {
      const res = await messageMobileCode(
        this.form.mobile,
        this.email,
        this.uuid,
        this.form.imgCode,
        this.typeStyle,
        this.templateCode,
        this.nationalCode
      );
      if (res.data.code == RETURN_ERR_CODE) {
        Notify({
          type: "warning",
          message: "图形验证码不正确",
          duration: 3000,
        });
        this.getCaptcha();
        return false;
      }
      if (res.data.code == 0) {
        this.messageTips = true;
        this.setTimer();
        return true;
      }
      if (res.data.code == 500) {
        Notify({
          type: "warning",
          message: "验证码发送失败！",
          duration: 3000,
        });
        this.getCaptcha();
        return false;
      }
    },
    // 获取图片验证码
    getCaptcha() {
      this.uuid = getUUID();
      if (process.env.NODE_ENV == "production") {
        this.captchaUrl =
          process.env.VUE_APP_UUI_URL +
          "/" +
          "api/ml-member/user/userinfo/captcha?uuid=" +
          this.uuid;
      }
      if (process.env.NODE_ENV == "development") {
        this.captchaUrl =
          "/" + "api/ml-member/user/userinfo/captcha?uuid=" + this.uuid;
      }
    },
    acquireCountDown: debounce(function () {
      let phone = this.form.mobile;
      let imgCode = this.form.imgCode;
      this.isClickcountDown = true;
      if (this.isClickcountDown) {
        if (!phone) {
          Notify({ type: "danger ", message: "请输入手机号", duration: 3000 });
          return false;
        } else if (!/^1\d{10}$/.test(phone)) {
          Notify({
            type: "warning",
            message: "请填写正确的手机号",
            duration: 3000,
          });
          return false;
        } else if (!imgCode) {
          Notify({
            type: "warning",
            message: "请输入图片验证码",
            duration: 3000,
          });
          return false;
        } else {
          this.getMessageMobileCode();
          return true;
        }
      }
    }),
    setTimer() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.countDown = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.countDown > 0 && this.countDown <= TIME_COUNT) {
            this.disabledButton = true;
            this.countDown--;
          } else {
            clearInterval(this.timer);
            this.messageTips = false;
            this.disabledButton = false;
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 提交表单且校验通过后触发
    onSubmit() {
      this.cilickLogin();
    },
    onFailed() {
      Notify({
        type: "warning",
        message: "请按照规则填写",
        background: "red",
        duration: 3000,
      });
    },
    //点击登录的事件
    async cilickLogin() {
      const res = await toSign(
        this.form.mobile,
        this.form.messageCode,
        this.nationalCode,
        this.typeStyle,
        this.form.companyName
      );
      console.log("注册的res", res);
      if (res.data.code == 500) {
        Notify({ type: "warning", message: res.data.msg, duration: 3000 });
        this.getCaptcha();
      }
      if (res.data.code == 0) {
        //证明登录成功.存token
        this.updateTokenInfo(res.data.data.token);
        this.updateUserMobile(res.data.data.mobile);
        this.updateCompanyName(res.data.data.companyName);
        const navPath = this.$route.query.pre || "/";
        this.$router.replace(navPath);
      }
    },
  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.input-height {
  height: 80px;
  box-sizing: border-box;
}
.imgcode {
  width: 450px;
}
.login-container {
  font-size: 28px;
  // padding-top: 46px;
  background: #ffffff;
  .top-text-description {
    height: 513px;
    background-image: url("@/assets/Login/header-top-background-img.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .logo {
      margin-top: 58px;
      margin-left: 40px;
      width: 258px;
      height: 109px;
      background-image: url("@/assets/Login/logo.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .welcome-text {
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin-top: 14px;
      .hello {
        font-size: 72px;
        font-weight: 400;
        color: #ffffff;
        opacity: 1;
        margin-left: 56px;
      }
      .textinformation {
        margin-top: 16px;
        display: block;
        font-size: 40px;
        font-weight: bold;
        color: #ffffff;
        opacity: 1;
        margin-left: 56px;
      }
    }
  }
  .form {
    margin-top: 58px;
    margin-left: 40px;
    margin-right: 44px;
    .form-item-border {
      border-radius: 4px;
      border: 2px solid #0a7f7c;
      margin-bottom: 24px;
      opacity: 0.8;
      /deep/.van-cell--required::before {
        font-size: 20px;
        top: 30px;
      }
      /deep/.van-field__control {
        padding-top: 9px;
        font-size: 30px;
        padding-left: 20px;
        font-weight: 400;
        color: #000000;
      }
      /deep/.van-field__error-message {
        padding-left: 20px;
      }
    }
    .imagecodebox {
      border: 2px solid #0a7f7c;
      border-radius: 4px;
      margin-left: 8px;
      width: 200px;
      height: 84px;

      // padding: 40px 2vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .messagecodebox {
      font-size: 28px;
      margin-left: 8px;
      width: 200px;
      // height: 84px;
      padding: 40px 2vw;
      text-align: center;
      border: 2px solid #0a7f7c;
      border-radius: 4px;
      color: #676767;
    }
    .messagecodeboxs {
      display: block;
      border: 2px solid #0a7f7c;
      border-radius: 4px;
      margin-left: 8px;
      width: 200px;
      padding: 40px 2vw;
      .imgs {
        width: 196px;
        height: 80px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .accountTextdescription {
      display: flex;
      justify-content: space-between;
      .accountTextdescription-text {
        display: flex;
        .isAccountNumber {
          font-size: 24px;
          font-weight: 400;
          color: #000000;
          text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
          opacity: 1;
          .atoncelogin {
            color: #4e6ef2;
          }
        }
        .forgetpsd {
          font-size: 24px;
          font-weight: 400;
          color: #000000;
          text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
          opacity: 1;
        }
      }
    }
    // 留言机器人
    .message-robot {
      margin-top: 168px;
      position: relative;
      .contact-us {
        margin-left: 416px;
        width: 200px;
        height: 102px;
        position: absolute;
        left: -10px;
        top: 30px;
        background-image: url("@/assets/Login/contact-us.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .robot {
        margin-left: 588px;
        width: 122px;
        height: 122px;
        background-image: url("@/assets/Login/message-robot.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .loginInformation {
      width: 666px;
      margin-top: 48px;
    }
    .measureResults {
      display: block;
      text-align: center;
      margin-top: 34px;
    }
  }
}
.login-btn {
  padding: 40px 2vw;
  // height: 80px;
  font-size: 30px;
  font-weight: 400;
  color: #ffffff;
}
.customer-service {
  height: 400px;
  margin-top: 400px;
  text-align: right;
  position: fixed;
  top: 60%;
  right: 24px;
  z-index: 999;
  .contact-msg {
    width: 180px;
    position: absolute;
    right: 100px;
    top: 40px;
    img {
      width: 100%;
    }
  }
  .contact {
    width: 100px;
    position: absolute;
    right: 0;
    img {
      width: 100%;
    }
  }
}
</style>
