import Vue from "vue";
import Vuex from "vuex";
import { 
  state as stateBase,
  mutations as mutationsBase,
  actions as actionsBase,
  getters as gettersBase,
} from '#domain-member/adapter/vuex/index.ad'

import Cookies from 'js-cookie'
const token = Cookies.get('tokenfront') || ''
Vue.use(Vuex);
let initState = {
  ...stateBase,
  tokenInfo: {
    token,
  }
};
// const stateStr = localStorage.getItem("tokenInfo");
// if (stateStr !== null) {
//   initState = JSON.parse(stateStr);
// }

export default new Vuex.Store({
  state: initState,
  getters: {
    ...gettersBase,
  },
  mutations: {
    ...mutationsBase,
    updateUserMobile(state, payload) {
      state.tokenInfo.userMobile = payload;
      Cookies.removeItem("tokenInfo")
      localStorage.setItem("tokenInfo", JSON.stringify(state));
    },
    updateCompanyName(state, payload) {
      state.tokenInfo.companyName = payload;
      Cookies.removeItem("tokenInfo")
      localStorage.setItem("tokenInfo", JSON.stringify(state));
    },
    deleteToken(state, payload) {
      state.tokenInfo.token = payload;
      Cookies.removeItem("tokenInfo")
      localStorage.removeItem("tokenInfo", JSON.stringify(state));
    },
    deleteUserInfo(state, payload) {
      Cookies.removeItem("tokenInfo")
      state.tokenInfo = payload;
    },
  },
  actions: {
    ...actionsBase
  },
  modules: {},
});
