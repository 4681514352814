<template>
  <div>
    <div class="header">
      <div class="header-left">
        <img src="../../../assets/logo/head_logo.png" alt="" />
      </div>
      <div class="header-right">
        <!-- 未登录的情况 -->
        <a href="/login" class="unlogin" v-if="!user">
          <div>登录</div>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <div>注册</div>
        </a>
        <!-- 登陆后的情况 -->
        <div class="user" v-else>
          <div class="user-icon">
            <img src="../../../assets/user/top-user.png" alt="" />
          </div>
          <div class="user-info">
            {{ user.userName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState([
      'user'
    ])
  },
}
</script>

<style lang="less" scoped>
.header {
  width: 375px;
  height: 50px;
  padding-left: 17px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 7px;
  display: flex;
  justify-content: space-between;
  .header-left {
    width: 64px;
    height: 35px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .header-right {
    display: flex;
    height: 50px;
    justify-content: space-between;
    .unlogin {
      display: flex;
      line-height: 40px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0.44px;
      font-weight: 400;
    }
    .user {
      display: flex;
      justify-content: space-between;
      .user-icon {
        width: 20px;
        height: 20px;
        margin-top: 8px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .user-info {
        line-height: 38px;
        margin-left: 5px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #333333;
        letter-spacing: 0.44px;
        font-weight: 400;
      }
    }
  }
}
</style>