<template>
  <div>
    <div class="round" @click="goConsultList">
      返回
    </div>
  </div>
</template>

<script>
  const homeAgrument=0
  export default {
    data() {
      return {
        homeAgrument,
        goHome:false
      }
    },
    created () {
      if(this.$route.query.home==homeAgrument){
        this.goHome=true
      } else {
          this.goHome=false
      }
    },
    methods: {
      goConsultList(){
        if(this.goHome){
          this.$router.push('/')
        } else {
          this.$router.push({		
            path:'/consulting',		
            query:{		
              activeIndex:1	,
              coulmnId:this.$route.query.coulmnId
            }		
          })
        }
      },
     
    },
  }
</script>

<style lang="less" scoped>

  .round{
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0 3px 10px 0 rgba(5, 5, 5, 0.12);
    font-family: PingFangSC-Medium;
    font-size: 12px;
    color: #417DFF;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    opacity: 0.9;
    left: 87vw;
    bottom: 10vh;
  }
</style>