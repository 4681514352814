import request from "@/untils/request";

function installWxSDK() {
  return new Promise((resolve) => {
    return fetchWxTiket().then((ticket) => {
      const sha1 = require("sha1");
      const appId = "wxf56dfc305d8b97df";
      const timestamp = +(Date.now() + "").slice(0, 10);
      const nonceStr = randomString(16);
      const str = `jsapi_ticket=${ticket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${window.location.href.split('#')[0]}`;
      const signature = sha1(str);
      /* eslint-disable */
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature,// 必填，签名
        jsApiList: ['updateAppMessageShareData',
        'updateTimelineShareData',
        'onMenuShareAppMessage',
        'onMenuShareTimeline'], // 必填，需要使用的JS接口列表
      })
      wx.ready(() => resolve())

      function randomString (e) {
        e = e || 32
        var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
          a = t.length,
          n = ""
        for (let i = 0; i < e; i++)
          n += t.charAt(Math.floor(Math.random() * a))
        return n
      }
    })
  })
}

function fetchWxTiket () {
  return request.get(
    `/api/ml-product/weChatOfficialAccounts/getTicket`
  ).then(res => res.data.data)
}
export function updateAppMessageShareData ({ title, desc, link, imgUrl } = {}) {
  let linkDetail = link || window.location.href.split('#')[0]
  let imgUrlConfigure = `${process.env.VUE_APP_IMG_URL}/logo.png`
  wx.onMenuShareAppMessage({
    title: '盟浪网', // 分享标题
    desc: '可持续发展价值评估与增值一站式平台', // 分享描述
    link: linkDetail, // 分享链接
    imgUrl: imgUrlConfigure, // 分享图标
  })
  wx.updateAppMessageShareData({
    title: '盟浪网',
    desc: '可持续发展价值评估与增值一站式平台',
    link: linkDetail,
    imgUrl: imgUrlConfigure
  })
  wx.onMenuShareTimeline({
    title:'盟浪网',
    desc:'可持续发展价值评估与增值一站式平台',
    link:linkDetail,
    imgUrl: imgUrlConfigure
  })
  wx.updateTimelineShareData({
    title: '盟浪网',
    desc: '可持续发展价值评估与增值一站式平台',
    link: linkDetail,     // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: imgUrlConfigure
  })
}

export default installWxSDK
