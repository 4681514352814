// 防抖
export function debounce(fn, wait, immediate = true) {
  let timer;
  return function () {
    if (timer) clearTimeout(timer);
    if (immediate) {
      // 如果已经执行过，不再执行
      var callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, wait || 500);
      if (callNow) {
        return fn.apply(this, arguments);
      }
    } else {
      timer = setTimeout(() => {
        return fn.apply(this, arguments);
      }, wait || 500);
    }
  };
}
