import request from "../untils/request";

// 获取区号的api接口
export const getAreaCode = function (obj) {
  return request.post("/api/ml-admin/index/countrycode/getList", obj);
};
// 获取短信验证码的api接口
export const messageMobileCode = function (
  mobile,
  email,
  uuid,
  captcha,
  verifyType,
  templateCode,
  nationalCode
) {
  return request.post("/api/ml-member/user/userinfo/sendCode", {
    mobile: mobile,
    email: email,
    uuid: uuid,
    captcha: captcha,
    verifyType: verifyType,
    templateCode: templateCode,
    nationalCode: nationalCode,
  });
};
// 登录的api接口 accountType,
export const submitLogin = function (
  mobile,
  verCode,
  nationalCode,
  verifyType
) {
  return request.post("/api/ml-member/user/userinfo/h5/login", {
    mobile: mobile,
    verCode: verCode,
    nationalCode: nationalCode,
    verifyType: verifyType,
  });
};
//登录成功后获取用户信息的api接口
export const getUserInfo = function () {
  return request.post("/api/ml-member/user/userinfo/info");
};
//免费试用pc端碳和通的接口文档
export const freeTrial = function () {
  return request.post(
    "/api/ml-member/user/memberuserservice/tryOutCarbonByServiceCodes"
  );
};
//退出登录的api接口
export const logoOut = function () {
  return request.post("/api/ml-member/user/userinfo/logout");
};
//注册的的api接口
export const toSign = function (
  mobile,
  verCode,
  nationalCode,
  verifyType,
  companyName
) {
  return request.post("/api/ml-member/user/userinfo/h5/register", {
    mobile: mobile,
    verCode: verCode,
    nationalCode: nationalCode,
    verifyType: verifyType,
    companyName: companyName,
  });
};
//登录注册合一api接口
export const loginandSign = function (
  mobile,
  verCode,
  nationalCode,
  verifyType
) {
  return request.post("/api/ml-member/user/userinfo/h5/registerOrLogin", {
    mobile: mobile,
    verCode: verCode,
    nationalCode: nationalCode,
    verifyType: verifyType,
  });
};
//获取企业名称的api接口
export const getCompanyName = function (companyName) {
  return request.post("/api/ml-member/user/userinfo/h5/saveCompanyName", {
    companyName: companyName,
  });
};
// 获取banner图的方法
export const getBannerImg= function (imageLocation) {
  return request.post("/api/ml-admin/index/platformimage/pageList", {
    imageLocation: imageLocation,
  });
};


