export class CompanyProperty{
  constructor() {
    this.value = ''
    this.label = ''
    this.options = []
  }
}
class Timer{
  timer = null
  count = 0

  codeShow = true
  codeDisabled = false

  setTimer() {
    const TIME_COUNT = 60
    this.codeDisabled = true
    if (!this.timer) {
      this.count = TIME_COUNT
      this.codeShow = false
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--
        } else {
          this.codeShow = true
          clearInterval(this.timer)
          this.timer = null
          this.codeDisabled = false
        }
      }, 1000)
    }
  }
}
export class FormOfVerifyUserByEmail extends Timer{
  email = ''
  verCode = ''
  verifyType = 'email'
  rules = {
    email: [
      { required: true, message: '请输入邮箱地址', trigger: 'blur' },
      {
        type: 'email',
        message: '请输入正确的邮箱地址',
        trigger: ['blur', 'change']
      }
    ],
    verCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
  }
}

export class FormResetPwd{
  pwd = ''
  checkpwd = ''
  constructor() {
    this.rules = {
      pwd: [
        { validator: this.validatePass, trigger: 'blur' },
      ],
      checkpwd: [
        { validator: this.validatePass2, trigger: 'blur' },
      ]
    }
  }

  validatePass = (rule, value, callback) => {
    if(this.testPassword(value)) {
      callback()
    }else {
      callback(new Error('密码长度 8 到 14 个字符，必须包含英文字母和数字'))
    }
  }

  testPassword(val) {
    const pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,14}$/
    if(pwdReg.test(val)) {
      return true
    }else {
      return false
    }
  }

  validatePass2 = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('请再次输入密码'))
    } else if (value !== this.pwd) {
      callback(new Error('两次输入密码不一致!'))
    } else {
      callback()
    }
  }
}