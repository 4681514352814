import ApiMember from "../data/member.api"
import ApiLogin from "../data/login.api";
import { CompanyProperty, FormOfVerifyUserByEmail, FormResetPwd } from "../model/member.model";
import { encrypt, message } from "../../../utils";
import LoginUc from "./login.uc";
import ApiAdmin from "../data/admin.api";

export default class MemberUc{
  getUserInfo() {
    return new ApiMember().userInfo()
  }
}

export class selectCompanyProperty{
  model = new CompanyProperty()
  constructor() {
    this.fetchCompanyProperties().then(({ dataList }) => {
      this.model.options = dataList
    })
  }

  fetchCompanyProperties() {
    return new ApiAdmin().selectByDictType(ApiAdmin.dictTypes.companyProperties)
  }

  submit() {
    return new ApiMember().saveCompanyProperty(this.model.value)
  }
}

export function getPublickey(pwd) {
  return new ApiLogin().getPubkey().then((publickey) => {
    return encrypt(pwd, publickey)
  })
}

export class InputVerifyUserByEmailForm{
  constructor() {
    this.form = new FormOfVerifyUserByEmail();
  }
  sendCode() {
    return new ApiMember().sendCode2Email(this.form).then(() => {
      return this.form.setTimer()
    })
  }
}

export class ResetNewPwd{
  constructor() {
    this.form = new FormResetPwd();
  }
  submit(verifyId, hasLogin) {
    return getPublickey(this.form.pwd).then(pwd => {
      return new ApiMember().resetPwd(verifyId, pwd);
    }).then(() => {
      message.success('修改成功, 请重新登录')
      if (hasLogin) {
        return new LoginUc().logout();
      } else {
        return true;
      }
    })
  }
}