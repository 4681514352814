var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card-box"},_vm._l((_vm.accessType),function(item,index){return _c('div',{key:index,staticClass:"card"},[_c('div',{staticClass:"item-card"},[_c('div',{staticClass:"item-card-title",class:item.type == _vm.I
              ? 'bgci'
              : item.type == _vm.N
              ? 'bgcN'
              : item.type == _vm.E
              ? 'bgcE'
              : item.type == _vm.S
              ? 'bgcS'
              : item.type == _vm.G
              ? 'bgcG'
              : ''},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"item-cart-content-box",class:item.type == _vm.I
              ? 'bgcIcontent'
              : item.type == _vm.N
              ? 'bgcNcontent'
              : item.type == _vm.E
              ? 'bgcEcontent'
              : item.type == _vm.S
              ? 'bgcScontent'
              : item.type == _vm.G
              ? 'bgcGcontent'
              : ''},_vm._l((item.content),function(itemDesc){return _c('div',{key:itemDesc.id,staticClass:"item-cart-content"},[_c('div',{staticClass:"item-cart-img"},[(item.type == _vm.F)?_c('img',{attrs:{"src":require("@/assets/productIntro/6-database/F.png"),"alt":""}}):_vm._e(),(item.type == _vm.I)?_c('img',{attrs:{"src":require("@/assets/productIntro/6-database/I.png"),"alt":""}}):_vm._e(),(item.type == _vm.N)?_c('img',{attrs:{"src":require("@/assets/productIntro/6-database/N.png"),"alt":""}}):_vm._e(),(item.type == _vm.E)?_c('img',{attrs:{"src":require("@/assets/productIntro/6-database/E.png"),"alt":""}}):_vm._e(),(item.type == _vm.S)?_c('img',{attrs:{"src":require("@/assets/productIntro/6-database/S.png"),"alt":""}}):_vm._e(),(item.type == _vm.G)?_c('img',{attrs:{"src":require("@/assets/productIntro/6-database/G.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"item-cart-desc",class:item.type == _vm.I
                  ? 'text_i'
                  : item.type == _vm.N
                  ? 'text_n'
                  : item.type == _vm.E
                  ? 'text_e'
                  : item.type == _vm.S
                  ? 'text_s'
                  : item.type == _vm.G
                  ? 'text_g'
                  : ''},[_vm._v(" "+_vm._s(itemDesc.text)+" ")])])}),0)])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }