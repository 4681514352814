import LoginUc from "../../uc/login.uc"
import MemberUc from "../../uc/member.uc"
import Cookies from 'js-cookie'

export const state = {
  user: null,
  logined: null,
  tokenInfo: {
    token: "",
    userMobile: "",
    companyName: "",
  },
}

export const actions = {
  login: ({ commit, dispatch, state }, ucLogin) => {
    return ucLogin.login()
    .then((tokenWrapper) => {
      const token = Cookies.get('tokenfront') || ''
      state.tokenInfo = {
        token,
      }
      return dispatch('getUserInfo').then(() => tokenWrapper)
    })
    .then(tokenWrapper => ucLogin.handleCompanyProperty(tokenWrapper))
  },
  getUserInfo ({ commit }) {
    return new MemberUc().getUserInfo().then((user) => {
      commit('SET_USERINFO', user)
    })
  },
  logout ({ commit }) {
    new LoginUc().logout().then(() => {
      commit('SET_USERINFO', null)
      commit('SET_LOGINED', null)
    })
  },
  isLogin ({ commit }) {
    return new LoginUc().isLogin().then((logined) => {
      commit('SET_LOGINED', logined)
      return logined
    })
  }
}

export const mutations = {
  SET_USERINFO(state, user) {
    state.user = user;
  },
  SET_LOGINED(s, d) {
    s.logined = d
    if (!d) {
      s.tokenInfo = {}
      Cookies.remove("tokenInfo")
    }
  }
}

export const getters = {
  logined(s) {
    return s.logined;
  }
}