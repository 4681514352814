<template>
    <div>
        <div class="top-left">
            <div class="top-left-img">
                <img :src="imgIcon" alt="">
            </div>
            <div class="top-left-text">
              <span>{{titleText}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'ProductCharacter',
    props:{
        imgIcon:{
            type:String
        },
        titleText:{
            type:String,
            default: "功能特点"
        }
    },
}
</script>

<style lang="less" scoped>
    .top-left{
    display: flex;  
    height: 40px;
    box-shadow: 0 1px 0 0 #D8E2F9;
        .top-left-img{
            width: 15px;
            height: 11px;
            margin-left: 15px;
            margin-right: 10px;
            img{
            width: 100%;
            height: 100%;
            }
        }
        .top-left-text{
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
        }
    }
</style>
   