<template>
  <div class="productIntro">
    <!-- <h1>这里是产品介绍页</h1> -->
    <div class="protop-img"></div>
    <!-- 文字介绍 -->
    <div class="bgc-text">
      <p>
        随着全球积极推行可持续发展战略，我国迎来了双碳经济和ESG（环境、社会、公司治理）投资的澎湃发展，金融市场也紧跟国家战略，将企业的ESG因素纳入到了研究和投资决策当中。这使得企业经营的可持续性受到了更多关注，ESG数据成为了投研中不可或缺的重要参考指标。金融市场迫切地需要一份高质量的ESG数据，来提高投融决策效率。
      </p>
      <p>
        盟浪结合尖端技术和专有研究，自主开发了盟浪ESG数据库，涵盖独立客观的企业ESG评分评级体系、ESG底层基础数据及各类专题数据，助力投资者、研究者和实业者，去洞察、转化和变现可持续发展价值。
      </p>
    </div>
    <div class="protop-bot"></div>
    <!-- 四个圆 -->
    <div class="fourCircle">
      <div class="item-circle" v-for="item in fourCircle" :key="item.id">
        <div class="circle">
          <div class="circle-content">
            <div class="num">{{ item.num }}</div>
            <div class="unit">{{ item.unit }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 四个文字 -->
    <div class="four-textintro">
      <div class="item-textintro" v-for="item in textIntro" :key="item.text">
        {{ item.text }}
      </div>
    </div>
    <!-- 优势 -->
    <div class="advantage">
      <div class="item-advantage" v-for="item in advantage" :key="item.id">
        <div class="item-advantage-left">
          <img :src="item.url" alt="" />
        </div>
        <div class="item-advantage-right">
          <div class="item-advantage-right-title">{{ item.title }}</div>
          <div class="item-advantage-right-desc" v-html="item.desc"></div>
        </div>
      </div>
    </div>
    <!-- 进程 -->
    <div class="process">
      <div class="process-left"></div>
      <div class="bigtitle">发展历程</div>
      <div class="yearsSeven">
        <div class="process-top">2017年起</div>
        <div class="process-bottom">
          发布《发现中国义利99-A股上市公司可持续发展价值评估报告》榜单、评级、蓝皮书、论坛和奖项
        </div>
      </div>
      <div class="yearsEight">
        <div class="process-top">2018年9月</div>
        <div class="bot">在Wind数据库发布可持续发展价值评级数据</div>
      </div>
      <div class="yearsNine">
        <div class="process-top">2019年10月</div>
        <div class="process-bottom">
          与中证指数公司合作发布全球首只可持续发展指数-中证可持续100
        </div>
      </div>
      <div class="yearsTen">
        <div class="process-top">2020年2月</div>
        <div class="process-bottom">
          与博时基金联合发行了全球首只可持续发展基金-博时可持续发展100ETF，上交所挂牌
        </div>
      </div>
    </div>

    <!-- 基础数据库详情 -->
    <div class="base-data-details">
      <div class="bigTItitle">FIN-ESG基础数据</div>
      <div class="basedata-details">
        盟浪从公开披露的信息渠道中摘录了六大维度的原始数据信息，汇整成了FIN-ESG基础数据。
      </div>

      <div class="basedata-range">
        <div class="basedata-range-content">
          <div class="top">
            <span>数据范围&nbsp;:</span>
            <span class="raneg-top-left">A股全覆盖</span>
            <span class="left-indtance">更新频率&nbsp;:</span>
            <span class="left-indtance">季度更新</span>
          </div>
          <div class="bottom">
            <span>历史长度&nbsp;:</span>
            <span class="raneg-top-left bottom-left">2016年至今</span>
            <span class="left-indtance">数据点数量&nbsp;:</span>
            <span class="left-indtance">340+</span>
          </div>
        </div>
      </div>
      <!-- table -->
      <div class="table">
        <div class="item-table" v-for="item in table" :key="item.id">
          <div class="item-row-box">
            <!--证券简称  -->
            <span class="item-width">{{ item.security }}</span>
            <!--  数据标签-->
            <span class="item-width">{{ item.dataLabel }}</span>
            <!-- 数据点 -->
            <span class="item-width">{{ item.dataPoint }}</span>
            <!-- 数据时间 -->
            <span class="item-width">{{ item.dataTime }}</span>
            <!-- 数据 -->
            <span class="item-width data-width">{{ item.data }}</span>
          </div>
        </div>
        <!-- table_icon -->
        <div class="table-icon">
          <div class="table-icon—content">案 例</div>
        </div>
      </div>
      <!-- 注释 -->
      <div class="table-desc">
        注：六大维度分别是财务(F)、创新(I)、价值准则(N)、环境(E)、社会(S)和公司治理(G)六大维度的细分数据点。
      </div>
    </div>

    <!-- 评级数据库 -->
    <div class="grate-data">
      <div class="grate-data-title">FIN-ESG评级数据</div>
      <div class="grate-data-desc">
        <p>盟浪基于自研的FIN-ESG评估模型，</p>
        <p>将国际ESG评估标准与中国特色相结合，</p>
        <p>致力于识别上市公司所面临的ESG风险与机遇。</p>
      </div>
      <!-- 范围 -->
      <div class="grate-data-range">
        <div class="grate-data-range-content">
          <div class="grate-data-range-content-top">
            <!-- 评估范围： 中证800 I 更新频率： 季度更新 历史长度： 2014年至今 -->
            <span>评估范围&nbsp;:</span>
            <span class="grade-line">A股全覆盖</span>
            <span class="grade-ml">更新频率&nbsp;:</span>
            <span class="grade-ml">季度更新</span>
          </div>
          <div class="grate-data-range-content-bot">
            <span>历史长度&nbsp;:</span>
            <span class="grade-ml">2016年至今</span>
          </div>
        </div>
      </div>

      <!-- 评估模型 -->
      <div class="access-model-box">
        <div class="access-model">
          <div class="access-model-title">FIN-ESG评估模型</div>
          <div class="access-model-desc">
            一级指标6个，二级指标30+，三级指标90+
          </div>
        </div>
        <!-- Card -->
        <div class="card">
          <Card :accessType="accessType" />
        </div>
        <!-- 评级说明 -->
        <div class="access-text">
          <div class="access-text-title">FIN-ESG评级说明</div>
          <!-- 详情 -->
          <div class="access-text-desc">
            <p>FIN-ESG评级共9个基础等级，详细分为19个增强等</p>
            <p>级。评级由强到弱依次为AAA、AA+、AA、AA-、A+、</p>
            <p>A、A-、BBB+、BBB、BBB-、BB+、BB、BB-</p>
            <p>、B+、B、B-、CCC、CC、C。</p>
          </div>

          <!-- 进度条 -->
          <div class="access-progress"></div>
          <!-- 文字 -->
          <div class="access-progress-text">
            <span class="three-a">AAA</span>
            <span class="two-a"> AA</span>
            <span class="one-a">A</span>
            <span class="three-b">BBB</span>
            <span class="two-b">BB</span>
            <span class="one-b">B</span>
            <span class="three-c">CCC</span>
            <span class="two-c">CC</span>
            <span class="one-c">C</span>
          </div>
        </div>
        <!-- 三大优势 -->
        <div class="access-three-advantage-box">
          <div
            class="access-three-advantage"
            v-for="item in accessThreeAverge"
            :key="item.id"
          >
            <div class="item-advantages">
              <div class="item-advantage-img">
                <img :src="item.url" alt="" />
              </div>
              <div class="item-advantage-text">{{ item.title }}</div>
            </div>

            <div class="item-advantages-content">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 专题数据 -->
    <div class="thematic-data-box">
      <div class="thematic-data-title">专题数据</div>
      <div class="thematic-data-desc">
        收录认证认可数据、专利、碳排放数据等大量珍贵的第一手资料，助力全方位洞察企业可持续发展表现。
      </div>

      <div class="thematic-data-bgi">
        <div class="thematic-data-bgi-text">
          <div>专题</div>
          <div>数据</div>
        </div>
        <div class="item-thematic">
          <div class="auth">
            <div class="thematic-img">
              <img src="../../assets/productIntro/6-database/auth.png" alt="" />
            </div>
          </div>
          <div class="auth-text">认证数据</div>
        </div>
        <div class="item-thematic thematic-patents">
          <div class="auth">
            <div class="thematic-img">
              <img
                src="../../assets/productIntro/6-database/patents.png"
                alt=""
              />
            </div>
          </div>
          <div class="auth-text">专利数据</div>
        </div>
        <div class="item-thematic thematic-cna">
          <div class="auth">
            <div class="thematic-img">
              <img src="../../assets/productIntro/6-database/cna.png" alt="" />
            </div>
          </div>
          <div class="auth-text">碳数据</div>
        </div>
        <div class="item-thematic thematic-industry">
          <div class="auth">
            <div class="thematic-img">
              <img src="../../assets/productIntro/6-database/industry.png" alt="" />
            </div>
          </div>
          <div class="auth-text">绿色识别数据</div>
        </div>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="application-scenarios">
      <div class="application-scenarios-title">应用场景</div>
      <!-- 卡片 -->
      <div class="application-card">
        <div
          class="application-card-item"
          v-for="item in useSecnarios"
          :key="item.id"
        >
          <div class="application-card-item-top">
            <div class="application-card-item-top-img">
              <img :src="item.url" alt="" />
            </div>
            <div class="application-card-item-top-title">
              {{ item.title }}
            </div>
          </div>
          <div class="application-card-item-bot" v-html="item.desc"></div>
        </div>
      </div>
    </div>

    <!-- 体验 -->
    <div class="experience">
      <div class="experience-title">
        <p>即刻致电 免费体验盟浪</p>
        <p>FIN-ESG数据</p>
      </div>

      <div class="content">
        <div class="content-left">
          <div class="text mobile">联系电话：0755-21615801</div>
          <div class="time text">服务时间：9：30-18：30</div>
          <div class="address-sz text">
            深圳办公室：广东省深圳市南山区南头街道南联社区北环大道11008号豪方天际广场写字楼21层
          </div>
          <div class="address-bj text">
            北京办公室：北京市朝阳区大望路和乔大厦C座902
          </div>
        </div>
        <div class="content-right">
          <div class="content-right-title">官方微信</div>
          <div class="content-right-img">
            <img src="@/assets/productIntro/6-database/qrcode.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from './component/card.vue'
export default {
  components: {
    Card,
  },
  data () {
    return {
      fourCircle: [
        { num: 1800, unit: "万条+", id: 0 },
        { num: 340, unit: "个+", id: 1 },
        { num: 4500, unit: "家+", id: 2 },
        { num: 7, unit: "年+", id: 3 },
      ],
      textIntro: [
        { text: 'ESG底层数据量', id: 0 },
        { text: 'ESG数据点', id: 1 },
        { text: 'A股上市公司', id: 2 },
        { text: 'ESG数据沉淀', id: 3 },

      ],
      advantage: [
        { url: require("@/assets/productIntro/6-database/frequency.png"), title: "数据频率", desc: '事实数据-实时更新、评估数据-季度更新', id: 0 },
        { url: require("@/assets/productIntro/6-database/quality.png"), title: "数据质量（双重验证）", desc: '智能采集、人工复核', id: 1 },
        { url: require("@/assets/productIntro/6-database/model.png"), title: "评估模型", desc: '独立研发，经过市场验证', id: 2 },
        { url: require("@/assets/productIntro/6-database/delivery.png"), title: "数据交付", desc: 'API、数据库同步、Excel', id: 3 },
        { url: require("@/assets/productIntro/6-database/source.png"), title: "数据来源", desc: '上市公司信息披露、监管部门信息验证、第三方<br/>权威数据', id: 4 },
        { url: require("@/assets/productIntro/6-database/safety.png"), title: "数据安全", desc: '数据加密技术、安全管理制度、风险监测机制', id: 5 },
      ],
      table: [
        { security: "证券简称", dataLabel: "数据标签", dataPoint: '数据点', dataTime: "数据时间", data: "数据", id: 0 },
        { security: "顺丰控股", dataLabel: "E", dataPoint: '环保投入', dataTime: "2021/12", data: "5500万元", id: 1 },
        { security: "比亚迪", dataLabel: "S", dataPoint: '对外捐赠', dataTime: "2021/12", data: "4755.2万元", id: 2 },
        { security: "招商轮船", dataLabel: "G", dataPoint: '高管离任数量', dataTime: "2021/12", data: "1人", id: 3 }
      ],
      accessType: [
        {
          title: "财务（F）", content: [
            { text: "盈利能力", id: 0 },
            { text: "运营效率", id: 1 },
            { text: "资本业务结构", id: 2 },
            { text: "……", id: 3 },
          ],
          type: "F"
        },
        {
          title: "创新（I）", content: [
            { text: "研发能力", id: 0 },
            { text: "产品服务", id: 1 },
            { text: "商业模式", id: 2 },
            { text: "……", id: 3 },
          ],
          type: "I"
        },
        {
          title: "价值准则（N）", content: [
            { text: "价值驱动", id: 0 },
            { text: "战略驱动", id: 1 },
            { text: "业务驱动", id: 2 },
            { text: "……", id: 3 },
          ],
          type: "N"
        },
        {
          title: "环境（E）", content: [
            { text: "环境管理", id: 0 },
            { text: "资源利用", id: 1 },
            { text: "生态气候", id: 2 },
            { text: "……", id: 3 },
          ],
          type: "E"
        },
        {
          title: "社会（S）", content: [
            { text: "客户价值", id: 0 },
            { text: "员工利益", id: 1 },
            { text: "合作伙伴", id: 2 },
            { text: "……", id: 3 },
          ],
          type: "S"
        },
        {
          title: "公司治理（G）", content: [
            { text: "治理结构", id: 0 },
            { text: "利益相关方", id: 1 },
            { text: "信息披露", id: 2 },
            { text: "……", id: 3 },
          ],
          type: "G"
        },
      ],
      accessThreeAverge: [
        { url: require("@/assets/productIntro/6-database/belead.png"), title: "领先", desc: '处于该区间的公司，创造FIN-ESG的综合价值能力强，受不良形势或周期性因素影响弱，可持续发展风险低。', id: 0 },
        { url: require("@/assets/productIntro/6-database/average.png"), title: "平均", desc: '处于该区间的公司，创造FIN-ESG的综合价值能力一般，容易受不良形势或周期性因素影响，存在一定的可持续发展风险。', id: 1 },
        { url: require("@/assets/productIntro/6-database/behind.png"), title: "落后", desc: '处于该区间的公司，创造FIN-ESG的综合价值能力较差，受到不良形势或周期性因素影响大，存在较大可持续发展风险。', id: 2 },
      ],
      useSecnarios: [
        { url: require('@/assets/productIntro/6-database/model_building.png'), title: '模型构建', desc: "投资者用于ESG战略、<br/>ESG投资研究，寻找正负面有效ESG因子，用于开发自身ESG数据模型或评级模型。", id: 0 },
        { url: require('@/assets/productIntro/6-database/study.png'), title: '投资研究', desc: "ESG报告，洞察行业企业ESG表现；<br/>ESG评级，选取ESG表现好的投资标的；<br/>ESG因子分析，优化投资组合。", id: 1 },
        { url: require('@/assets/productIntro/6-database/risk.png'), title: '风险控制', desc: "风险数据，规避投资组合ESG风险（禁选，剔除，调仓）。", id: 2 },
        { url: require('@/assets/productIntro/6-database/dev.png'), title: '产品开发', desc: "践行可持续投资，构建ESG主题或策略金融产品。", id: 3 },
      ]
    }
  },

}
</script>

<style lang="less" scoped>
.protop-img {
  width: 343px;
  height: 108px;
  margin: auto;
  margin-top: 12px;
  background-image: url('@/assets/productIntro/6-database/home_top.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.bgc-text {
  // width: 343px;
  // height: 440px;
  padding-left: 17px;
  padding-right:15px;
  margin: auto;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.protop-bot {
  width: 343px;
  height: 108px;
  margin: auto;
  margin-top: 12px;
  margin-bottom: 26px;
  background-image: url('@/assets/productIntro/6-database/home_bot.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.fourCircle {
  width: 344px;
  height: 77px;
  margin: auto;
  display: flex;
  justify-content: space-around;

  .circle {
    width: 77px;
    height: 77px;
    background-image: url('@/assets/productIntro/6-database/circle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
  }
  .circle-content {
    width: 77px;
    height: 77px;
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.num {
  font-size: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #39bbb7;
  line-height: 30px;
}
.unit {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9d9d9d;
  line-height: 17px;
}
.four-textintro {
  width: 343px;
  margin: auto;
  margin-top: 7px;
  display: flex;
  justify-content: space-around;

  .item-textintro {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9a8849;
    line-height: 17px;
    background: linear-gradient(
      90deg,
      rgba(239, 218, 164, 0) 0%,
      #f8f0da 33%,
      #f9eac4 53%,
      #faedd7 71%,
      rgba(240, 219, 167, 0) 100%
    );
  }
}
.advantage {
  margin-top: 27px;
  .item-advantage {
    width: 359px;
    height: auto;
    border-bottom: 1px solid #ececec;
    display: flex;
    .item-advantage-left {
      width: 21px;
      height: 21px;
      margin-left: 19px;
      margin-top: 14px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-advantage-right {
      padding-top: 12px;
      padding-bottom: 12px;

      .item-advantage-right-title {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 24px;
      }
      .item-advantage-right-desc {
        margin-top: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 22px;
      }
    }
  }
}
.process {
  width: 375px;
  height: 485px;
  margin-top: 15px;
  background-image: url('@/assets/productIntro/6-database/process.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  .process-left {
    width: 71px;
    height: 454px;
    margin-top: 15px;
    background-image: url('@/assets/productIntro/6-database/process_line.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-left: 10px;
    margin-top: 16px;
  }
  .bigtitle {
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 33px;
    position: absolute;
    left: 137px;
    top: 17px;
  }
  .yearsSeven {
    height: 84px;
    position: absolute;
    left: 50px;
    top: 73px;
  }
  .yearsEight {
    height: 84px;
    position: absolute;
    left: 50px;
    top: 177px;
    .bot {
      height: 28px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      margin-top: 6px;
    }
  }
  .yearsNine {
    height: 84px;
    position: absolute;
    left: 50px;
    top: 256px;
  }
  .yearsTen {
    height: 84px;
    position: absolute;
    left: 50px;
    top: 363px;
  }
}
.process-top {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 22px;
}
.process-bottom {
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  margin-top: 6px;
}
.base-data-details {
  margin-top: 23px;
  .bigTItitle {
    text-align: center;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 33px;
  }
  .basedata-details {
    padding-left: 17px;
    padding-right: 15px;
    height: 52px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    margin-bottom: 14px;
  }
  .basedata-range {
    width: 343px;
    height: 54px;
    margin: auto;
    padding: 5px 40px 5px 40px;
    background: #0084f5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    .basedata-range-content {
      // width: 263px;
      height: 44px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      .raneg-top-left {
        // width: 76px;
        border-right: 2px solid #d2f2ff;
        padding-right: 19px;
        padding-left: 10px;
      }
      .left-indtance {
        padding-left: 9px;
      }
      .bottom-left {
        padding-right: 11px;
      }
    }
  }
  .table {
    width: 343px;
    height: 212px;
    margin: auto;
    margin-top: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(205, 205, 205, 0.5);
    border-radius: 4px;
    opacity: 0.81;
    position: relative;
    .item-table {
      width: 343px;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      // padding-right: 10px;
      .item-row-box {
        border-top: 1px dashed #979797;
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        .item-width {
          width: 64px;
          height: 50px;
          display: inline-block;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          line-height: 20px;
        }
        .data-width {
          width: 55px;
          display: inline-block;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          text-align: center;
        }
      }
    }
    .table-icon {
      width: 38px;
      height: 38px;
      background-image: url('@/assets/productIntro/6-database/table_icon.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 0;
      top: 0;
      img {
        width: 100%;
        height: 100%;
      }
      .table-icon—content {
        font-size: 10px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 16px;
        transform: rotate(-45deg);
      }
    }
  }
  .table-desc {
    width: 344px;
    height: 44px;
    margin: auto;
    margin-top: 11px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0084f5;
    line-height: 22px;
    text-align: center;
  }
}
.item-table:nth-child(1) {
  .item-row-box {
    height: 56px !important;
    border-top: none !important;
    border-bottom: 1px solid #979797;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    .item-width:nth-child(1) {
      margin-right: 5px;
    }
    .item-width:nth-child(3) {
      justify-content: flex-start;
      margin-left: 7px;
    }
    .item-width:nth-child(4) {
      justify-content: flex-start;
      margin-left: -7px;
    }
  }
}
.item-table:nth-child(2) {
  .item-row-box {
    height: 56px !important;
    border-top: none !important;
    .item-width:nth-last-child(1) {
      width: 45px;
      margin-left: 10px;
    }
  }
}
.item-table:nth-child(3) {
  .item-row-box {
    .item-width:nth-last-child(1) {
      margin-left: 6px;
    }
  }
}
.item-table:nth-child(4) {
  .item-row-box {
    height: 56px !important;
    .item-width:nth-last-child(1) {
      margin-left: 5px;
    }
  }
}
.grate-data {
  width: 375px;
  // height: 1039px;
  background: #deeeff;
  padding-bottom: 33px;
  border-radius: 4px;
  margin-top: 16px;
  overflow: hidden;
  .grate-data-title {
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 33px;
    display: flex;
    justify-content: center;
    margin-top: 13px;
  }
  .grate-data-desc {
    width: 342px;
    // height: 78px;
    margin: auto;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    text-align: center;
    margin-top: 2px;
  }
  .grate-data-range {
    width: 343px;
    height: 54px;
    margin: auto;
    background: #0084f5;
    border-radius: 4px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    .grate-data-range-content {
      width: 256px;
      height: 44px;
      margin: auto;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      .grade-ml {
        padding-left: 8px;
      }
      .grade-line {
        padding-left: 10px;
        padding-right: 10px;
        border-right: 2px solid #d2f2ff;
      }
    }
  }
  .access-model {
    width: 343px;
    height: 69px;
    margin: auto;
    margin-top: 30px;

    .access-model-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 29px;
      background: linear-gradient(270deg, #8290b7 0%, #445070 100%);
      border-radius: 4px 4px 0px 0px;
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
    .access-model-desc {
      width: 343px;
      height: 40px;
      background: #eaf0ff;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4b5778;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .access-text {
    margin-top: 16px;
    .access-text-title {
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .access-text-desc {
      height: 88px;
      margin-top: 5px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      text-align: center;
    }
    .access-progress {
      width: 336px;
      height: 13px;
      margin: auto;
      margin-top: 10px;
      background-image: url('@/assets/productIntro/6-database/progressbar.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .access-progress-text {
      margin-top: 2px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0a4572;
      line-height: 20px;
      position: relative;
      .three-a {
        position: absolute;
        left: 2%;
      }
      .two-a {
        position: absolute;
        left: 13%;
      }
      .one-a {
        position: absolute;
        left: 24.6%;
      }
      .three-b {
        position: absolute;
        left: 32.5%;
      }
      .two-b {
        position: absolute;
        left: 44.3%;
      }
      .one-b {
        position: absolute;
        left: 56.5%;
      }
      .three-c {
        position: absolute;
        left: 65%;
      }
      .two-c {
        position: absolute;
        left: 77%;
      }
      .one-c {
        position: absolute;
        left: 87.5%;
      }
    }
  }
  .access-three-advantage-box {
    width: 354px;
    height: 265px;
    margin: auto;
    margin-top: 15px;
    display: flex;
  }
  .access-three-advantage {
    .item-advantages {
      width: 118px;
      height: 32px;
      display: flex;
      align-items: center;
      overflow: hidden;
      .item-vantage-img {
        width: 30px;
        height: 32px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-advantage-text {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1e446f;
        line-height: 22px;
        margin-top: -7px;
      }
    }
    .item-advantages-content {
      width: 118px;
      height: 233px;
      padding-left: 8px;
      padding-right: 8px;
      margin-top: 12px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555555;
      line-height: 28px;
      border-right: 1px dashed #0983eb;
    }
  }
}
.access-three-advantage-box .access-three-advantage:nth-last-child(1) {
  .item-advantages-content {
    border-right: none;
    height: 220px;
    border-left: 1px dashed #0983eb;
  }
}
.access-three-advantage-box .access-three-advantage:nth-child(2) {
  .item-advantages-content {
    border-right: none;
    height: 220px;
    padding-left: 10px;
  }
}
.thematic-data-box {
  .thematic-data-title {
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    height: 33px;
    line-height: 33px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 4px;
  }
  .thematic-data-desc {
    width: 348px;
    height: 52px;
    margin: auto;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
  }
  .thematic-data-bgi {
    width: 375px;
    height: 186px;
    margin-top: 9px;
    background-image: url('@/assets/productIntro/6-database/thematic_data.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    .thematic-data-bgi-text {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 17px;
      position: absolute;
      left: 170px;
      top: 76px;
    }
    .item-thematic {
      position: absolute;
      left: 41px;
      top: 22px;
      .auth {
        width: 30px;
        height: 29px;
        background-image: url('@/assets/productIntro/6-database/oval.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        .thematic-img {
          width: 16px;
          height: 13px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .auth-text {
        height: 32px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 32px;
      }
    }
    .thematic-patents {
      left: 108px;
      top: 4px;
    }
    .thematic-cna {
      left: 213px;
      top: 4px;
    }
    .thematic-industry {
      left: 275px;
      top: 19px;
    }
  }
}
.application-scenarios {
  width: 375px;
  height: 547px;
  margin-top: 17px;
  background: #deeeff;
  border-radius: 4px;
  overflow: hidden;
  .application-scenarios-title {
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 33px;
    margin-top: 15px;
    text-align: center;
  }
  .application-card {
    width: 343px;
    margin: auto;
    margin-top: 28px;
    display: flex;
    flex-wrap: wrap;
    .application-card-item {
      width: 168px;
      height: 223px;
      overflow: hidden;
      background: #ffffff;
      box-shadow: 0px 2px 21px 0px rgba(116, 130, 167, 0.09);
      border-radius: 4px;
      opacity: 0.85;
      // margin-right: 3px;
      margin-left: 7px;
      margin-bottom: 6px;
      .application-card-item-top {
        height: 31px;
        display: flex;
        align-items: center;
        margin-left: 4px;
        margin-top: 12px;
        .application-card-item-top-img {
          width: 31px;
          height: 27px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .application-card-item-top-title {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          display: flex;
          align-items: center;
          margin-left: 6px;
        }
      }
      .application-card-item-bot {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        padding-left: 8px;
        padding-right: 4px;
        margin-top: 9px;
      }
    }
  }
}
.application-card .application-card-item:nth-child(1) {
  margin-left: 0px !important;
}
.application-card .application-card-item:nth-child(3) {
  margin-left: 0px !important;
}
.experience {
  height: 293px;
  .experience-title {
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 33px;
    text-align: center;
    margin: auto;
    margin-top: 12px;
  }
  .content {
    width: 343px;
    height: auto;
      padding-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #287ede;
    margin: auto;
    margin-top: 19px;
    display: flex;
    .content-left {
      width: 244px;
      height: auto;
      padding-left: 9px;
      margin-top: 14px;
      border-right: 2px solid #287ede;
      .text {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 17px;
        margin-bottom: 12px;
      }
    }
    .content-right {
      width: 131px;
      height: 134px;
      margin-top: 31px;

      .content-right-title {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 17px;
        text-align: center;
      }
      .content-right-img {
        width: 82px;
        height: 82px;
        margin: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>