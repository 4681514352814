import Api from '../../../core/api';

export const getNewsList= function (page,limit,coulmnId) {
  return new Api().plainPost("/api/ml-product/index/news/queryNewsPageByParam", {
    page:page,
    limit:limit,
    coulmnId:coulmnId
  });

};

export default class ApiProduct extends Api{
  firminfoList() {
    return this.post('/ml-product/index/firminfo/firminfoList',{firmType: 2,treatyType: [1]})
  }
}