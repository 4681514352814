import axios from "axios";
import { Toast } from "vant";
import store from "@/store/index.js";
import router from '@/router/index.js'
import { Notify } from 'vant';
import Cookies from 'js-cookie'
const noLoadingRouter ="/consulting";//咨询的路由name
const noRiskLoding='/risktracker-connect'
let baseURL = process.env.VUE_APP_LOCAL ? 'https://test.susallwave.com' : process.env.VUE_APP_URL
const request = axios.create({
  baseURL,
  withCredentials: true // send cookies when cross-domain requests
});
request.interceptors.request.use(
  function (config) {
    if(window.location.pathname!=noLoadingRouter && window.location.pathname!= noRiskLoding){
      Toast.loading({
        message: `<div style="font-size:4vw;text-align:center">加载中...<div/>`,
        // 防止自动关闭
        duration: 0,
        type:"html"
      });
    }
    if (!/^\/api/.test(config.url)) {
      config.url = '/api' + config.url
    }

    // 获取token,设置请求头
    const token = Cookies.get('tokenfront') || ''
    if (token !== '') {
      config.headers.token = token;
    }
    // if (store.state.tokenInfo) {
    // const tokenStr = store.state.tokenInfo.token;
    // if (tokenStr) {
    // }
    // }

    return config;
  },
  function (error) {
    // 隐藏 loading 效果
    Toast.clear();
    return Promise.reject(error);
  }
);
// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    Toast.clear();
    if (response.data.code == 401) {
      store.commit("updateTokenInfo", null);
      router.replace("/login?pre=" + router.currentRoute.fullPath)
    } else if (response.data.code === 500) {
      Notify({ 
        background: '#fef0f0',
        color: '#F56C6C',
        message: response.data.msg 
        // border-color: #fde2e2,
      })
    } else if(response.data.code === 10057){
      Notify({ 
        background: '#fef0f0',
        color: '#F56C6C',
        message: response.data.msg 
      })
    }
    return response;
  },
  async function (error) {
    Toast.clear();
    return Promise.reject(error);
  }
);

// eslint-disable-next-line eol-last
export default request;
