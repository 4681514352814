export default {
  section: {
    firstSection: {
      title: '代码样例',
      firstP: "API 返回HTTP JSON 格式数据，任何语言都可以直接调用HTTP API，以获得数据。由于API使用安全性高的https协议传输，程序访问必须使用token作为请求头。",
      secondP: {
        title: "Example List",
        firstP: '·Python Example',
        secondP: '·Java Example'
      }
    },
    secondSection: {
      title: 'Python Example',
      firstP: "demo.py调用前，需要将accessKey、accessSecret与url改为您需要的参数。",
      secondTitle: "demo.py"
    },
    threeSection: {
      title: 'Java Example',
      firstP: "调用程序前，需要将accessKey、accessSecret与url改为您需要的参数。token未过期可以重复使用，无需再次调用。",
      secondTitle: "test.java"
    }
  }
}