import Api from '../../../core/api';

export default class ApiLogin extends Api{
  isLogin() {
    return this.get('/ml-member/user/userinfo/loginUp')
  }
  logout() {
    return this.post('/ml-member/user/userinfo/logout')
  }
  getPubkey() {
    return this.get('/ml-member/user/userinfo/getPubkey')
  }
  login(userForm) {
    return this.post('/ml-member/user/userinfo/login', userForm)
  }
  areaCodeList() {
    return this.post('/ml-admin/index/countrycode/getList', { countryNameZh: '' })
  }

  validateCaptcha(form) {
    return this.plainPost('/ml-member/user/userinfo/sendCode', { 
      ...form, 
      nationalCode: form.areaCode.value 
    })
  }
  loginOrRegistry(parmas) {
    return this.post('/ml-member/user/userinfo/pc/registerOrLogin', parmas)
  }
}