<template>
  <div class="dialogBox">
    <el-dialog :visible="isShow" :before-close="closeDialog">
      <div class="top">
        <div class="line"></div>
        <div class="title-text">提示</div>
      </div>
      <div class="content">
        <div class="hint">
          <img src="@/assets/productIntro/3-risktracker/hint.png" alt="" />
        </div>

        <div class="text">该服务需要登录，请先前往登录</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="btn">
          <div class="cancle" @click="closeDialog">取消</div>
          <div class="confirm" @click="goLogin">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Dialog, Message, Button } from 'element-ui'
export default {
  name: 'dialog-form',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
  },
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Message.name]: Message,
  },
  data () {
    return {

    }
  },

  methods: {
    closeDialog () {
      this.$emit('close')
    },
    goLogin () {
      this.$router.push( '/login?pre=' + this.$route.fullPath)
    }
  },
}
</script>

<style lang="less" scoped>
.dialogBox {
  /deep/ .el-dialog {
    width: 320px;
  }
  /deep/.el-dialog__headerbtn {
    display: none;
  }
  /deep/.el-dialog__header {
    display: none;
  }

  /deep/ .el-dialog__body {
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 0;
  }

  /deep/.el-dialog__footer {
    padding-top: 0;
    padding-left: 0;
  }
  .dialog-footer {
    .btn {
      display: flex;
      margin-top: 41px;
      margin-left: 163px;
      .cancle {
        width: 61px;
        height: 26px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #999999;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .confirm {
        width: 61px;
        height: 26px;
        margin-left: 14px;
        background: #417dff;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.top {
  padding-top: 17px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  .line {
    width: 3px;
    height: 15px;
    background: #0052d9;
    border-radius: 2px;
  }
  .title-text {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 22px;
    margin-left: 8px;
  }
}
.content {
  margin-left: 22px;
  margin-top: 27px;
  height: 20px;
  display: flex;
  align-items: center;
  .hint {
    width: 18px;
    height: 18px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .text {
    margin-left: 7px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}
</style>