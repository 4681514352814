<template>
  <div v-if="menus" class="menubox">
    <van-tabs v-model="active" color="#0052D9" class="menu-nav" @change="change">
      <!-- 主要数据库 -->
      <van-tab v-for="itemMenu,i in menus" :key="itemMenu.id">
        <template #title>
          <div class="majormenu">
            <van-dropdown-menu active-color="#0052D9">
              <van-dropdown-item
                :title="itemMenu.title"
                ref="majorbase"
                class="major"
              >
                <div class="cell">
                  <van-cell
                    center
                    v-for="(item, index) in treeToArray(itemMenu.children )"
                    :key="item.id"
                    @click="goMajor(item, index,i)"
                  >
                    <template>
                      <div
                        class="text"
                        :class="
                          item.isDoc == false
                            ? 'title-text'
                            : activiteIndex == index
                            ? 'color-text'
                            : 'title-text'
                        "
                      >
                        {{ item.title }}
                      </div>
                    </template>
                  </van-cell>
                </div>
              </van-dropdown-item>
            </van-dropdown-menu>
          </div>
        </template>
      </van-tab>
    </van-tabs>
    <apiList :detailsTable="detailsTable" />
  </div>
</template>

<script>
const majardatabaseType=0
const finesgdatabaseType=1
const thematicdataType=2
const thematicApprove="aebeb1424107435ab09de157616898b1"
const finesgIndexdata='4a1e6d6633054405ad2b854784156166'
const stockList = "a2eb3da0d03a490696b889268d189d54"

import apiList from './api-list.vue'
import axios from 'axios'

export default {
  provide () {
    return {
      tables: this
    }
  },
  data () {
    return {
      thematicApprove,
      finesgIndexdata,
      majardatabaseType,
      finesgdatabaseType,
      thematicdataType,
      stockList,
      active: 0,
      menus: [],//nav 包含了他下面的信息包含三个
      detailsTable: {},
      activiteIndex: 0,
    }
  },
  components: {
    apiList,
  },
  mounted () {
    this.getApiList()
    this.fetchDocDetail(String(stockList))
  },
  methods: {
    treeToArray (tree) {
      const obj = []
      tree.forEach((item) => {
        if (item.children) {
          obj.push(item, ...item.children)
          // ES6新增的 删除对象的属性 Reflect.deleteProperty(对象，属性名)
          Reflect.deleteProperty(item, 'children')
          this.treeToArray(obj)
        } else {
          obj.push(item)
        }
      })
      return obj
    },
    change(val){
      this.activiteIndex=0
      this.detailsTable={}
      if(val==majardatabaseType){
        this.fetchDocDetail(String(stockList))
      }
      if(val==finesgdatabaseType){
        this.fetchDocDetail(String(finesgIndexdata))
      }  
      if(val==thematicdataType){
        this.fetchDocDetail(String(thematicApprove))
      }
    },
    getApiList () {
      axios.get('/doc-finesg-db/table/dbTables.json').then((res) => {
        this.menus = res.data
      })
    },
    fetchDocDetail (id) {
      axios.get(`/doc-finesg-db/table/tableDetails/${id}.json`).then(r => {
        this.enhanceDocDetail(r.data)
        this.detailsTable = r.data
        return this.detailsTable
      })
    },
    enhanceDocDetail (doc) {
      if (doc) {
        doc.sections.forEach(section => {
          const mapWidths = new Map([
            ['通用数据', [221]],
            ['输入参数', [221, 80, 120, 110, 80]],
          ])
          if (mapWidths.has(section.title)) {
            section.content.columns.forEach((c, i) => {
              c.width = mapWidths.get(section.title)[i]
            })
          }
        })
      }
      return doc
    },
    goMajor (val, index,i) {
      if (val.isDoc) {
        this.activiteIndex = index
        this.$refs.majorbase[i].toggle()  //vant 内置方法关闭遮罩
        this.fetchDocDetail(val.id)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.menubox {
  width: 375px;
  /deep/.van-dropdown-menu__title--active::after {
    content: '';
  }
  /deep/.van-tab--active {
    color: #0052d9;
  }
  /deep/.van-tabs__line {
    width: 65px;
    bottom: 5vw;
    left: -5px;
    z-index: 2029;
  }
}

/deep/.van-tab {
  padding-left: 0px;
  padding-right: 0px;
  font-size: 14px !important;
}
/deep/.van-dropdown-menu__title {
  padding: 0;
  padding-right: 5px;
}
.text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.title-text {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
}
.cell {
  padding-left: 12px;
  max-height: 400px;
}
.color-text {
  color: #0052d9;
}
/deep/ .menu-nav  {
  .van-tab:nth-child(1){
    margin-left: -5px;
    .van-dropdown-menu__item{
     width: 90px;
    }
  }
  .van-tab:nth-child(2){
    margin-left: -5px;
    .van-dropdown-menu__item{
      width: 40vw;
    }
  }
  .van-tab:nth-child(3){
    margin-left: -5px;
    .van-dropdown-menu__item{
      width: 90px;
    }
  }

}
</style>