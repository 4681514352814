import Api from '../../../core/api';

export default class ApiAdmin extends Api{
  selectByDictType(dictType) {
    return this.post('/ml-admin/sys/dict/type/selectByDictType', { dictType })
  }

  static dictTypes = {
    companyProperties: 'QYSX'
  }
}