<template>
    <div>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" @change="onChange">
  	   	<van-swipe-item v-for="item in images" :key="item.id">
    			<img  v-lazy="item.imageUrl" :src="item.imageUrl" class="swipe-img" @click="bannerToLink(item.linkAddress)" />
  			</van-swipe-item>
				<template #indicator >
        	<ul class="indicators indicator" v-if="images.length>1" >
          	<li
            	v-for="(item, index) in images" :key="index"
            	:class="{active: checkActive(index)}">
						</li>
        	</ul>
      	</template>
			</van-swipe>
  	</div>
</template>
<script>
	import { getBannerImg} from "@/api/userInfo.js";
    export default {
			data() {
				return {
				 images:[],
				 current: 0,
				}
			},
			props: {
				imageLocation: {
					type: String,
					default:"h5-首页banner" 
				},
			},
			created () {
				this.getBanner()
			},
			methods: {
				checkActive(index) {
          return index === this.current;// current为监听的轮播index
        },
				onChange(index) {
      		this.current = index;
    		},
				async	getBanner(){
					const {data} =await getBannerImg(this.imageLocation)
					this.images=data.data
    		},
				bannerToLink(item){
					this.$router.push(item)
				}
			},
    }
</script>

<style lang="less" scoped>
	.my-swipe .van-swipe-item {
    width: 100%;
		height: 100%;
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
	// 设置每一个图片的大小
	.swipe-img{
		width: 375px;
		height: 205px;
	}
	// 指示器的位置居中
	.indicators {
  	position: absolute;
  	bottom: 10px;
  	left: 50%;
  	display: flex;
  	transform: translateX(-50%);
	}
 //每一个指示器的大小样式
.indicator li {
  display:inline;
  width: 22px;
  height: 2px;
  background-color: white;
  opacity: 0.3;
  transition: opacity 0.5s,//@animation-duration-fast
  background-color 0.5s;//@animation-duration-fast
	&:not(:last-child) {
    margin-right: 6px;
  }
	// 当前的是白色的
  &.active {
    background-color: white;
    opacity: 1;
  }
}
</style>