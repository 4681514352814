<template>
  <div class="code">
    <div class="line"></div>
    <div class="example-top">
      <div class="title-text">
        {{ apiDocSummarize.section.firstSection.title }}
      </div>
      <div class="example-top0line"></div>
      <div class="example-top-content">
        {{ apiDocSummarize.section.firstSection.firstP }}
      </div>
      <div class="example-top-example">
        <div class="example-top-example-title">
          {{ apiDocSummarize.section.firstSection.secondP.title }}
        </div>
        <div class="example-top-example-text">
          {{ apiDocSummarize.section.firstSection.secondP.firstP }}
        </div>
        <div class="example-top-example-text">
          {{ apiDocSummarize.section.firstSection.secondP.secondP }}
        </div>
      </div>
    </div>
    <div class="python-example">
      <div class="title-text python-example-title">
        {{ apiDocSummarize.section.secondSection.title }}
      </div>
      <div class="python-example-content">
        {{ apiDocSummarize.section.secondSection.firstP }}
      </div>

      <div class="python-example-table">
        <div class="python-example-table-top">
          {{ apiDocSummarize.section.secondSection.secondTitle }}
        </div>
        <pre>{{ this.pythoncode }}</pre>
      </div>
    </div>

    <div class="python-example">
      <div class="title-text python-example-title">
        {{ apiDocSummarize.section.threeSection.title }}
      </div>
      <div class="python-example-content">
        {{ apiDocSummarize.section.threeSection.firstP }}
      </div>

      <div class="python-example-table">
        <div class="python-example-table-top">
          {{ apiDocSummarize.section.threeSection.secondTitle }}
        </div>
        <pre>{{ this.javacode }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
const  javacodePath="/code-example/MengLangFinesgUtils.java"
const  pythoncodePath="/code-example/demo.py"
import axios from 'axios'
import apiDocSummarize from '#susallwave-base/domain/finesg/data/code-example.js'
export default {
  name: 'apiDocStatusCode',
  data () {
    return {
      apiDocSummarize,
      javacodePath,
      pythoncodePath,
      javacode: "",
      pythoncode: "",

    }
  },
  mounted () {
    this.getJavacodeFile(javacodePath)
    this.getPythoncode(pythoncodePath)
  },
  methods: {
    getJavacodeFile(filePath){
      axios.get(filePath).then((res)=>{
      this.javacode=res.data
    })
    },
    getPythoncode(filePath){
      axios.get(filePath).then((res)=>{
      this.pythoncode=res.data
      })
    }
  },

}
</script>

<style lang="less" scoped>
.code {
  margin-bottom: 67px;
}
.line {
  height: 1px;
  background-color: #e8e8e8;
}
.example-top {
  margin-top: 13px;
  padding-left: 15px;
  .example-top0line {
    width: 360px;
    height: 2px;
    background-color: #979797;
    margin-top: 8px;
  }
  .example-top-content {
    height: 84px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    margin-top: 10px;
  }
  .example-top-example {
    width: 343px;
    height: 112px;
    background: #deeeff;
    margin-top: 13px;
    overflow: hidden;
    .example-top-example-title {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      margin-left: 13px;
      margin-top: 14px;
    }
    .example-top-example-text {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      margin-left: 22px;
      margin-top: 13px;
    }
  }
}
.python-example {
  display: block;
  .python-example-title {
    width: 360px;
    margin-left: 15px;
    padding-left: 6px;
    padding-bottom: 8px;
    padding-top: 12px;
    border-bottom: 2px solid #979797;
  }
  .python-example-content {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    padding-left: 18px;
    padding-right: 16px;
    margin-top: 16px;
  }
}
.title-text {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #445070;
  line-height: 22px;
}
.python-example-table {
  width: 343px;
  margin: auto;
  height: 100%;
  border: 1px solid #7b89af;
  .python-example-table-top {
    height: 44px;
    background: #287ede;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    display: flex;
    align-items: center;
    padding-left: 6px;
  }
  pre {
    padding-left: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    white-space: break-spaces;
    word-wrap: break-word;
    word-break: normal;
    word-break: break-all;
  }
}
</style>