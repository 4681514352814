<template>
  <div>
    <div class="tabs">
      <van-tabs
        v-model="active"
        @change="onTabsChange(active)"
        line-width="68px"
        line-height="2px"
        title-active-color="#417DFF"
        color="#417DFF"
        class="mytabs"
      >
        <van-tab
          v-for="item in riskNewsTab"
          :key="item.id"
          :title="item.coulmnName"
        >
          <div class="search-box" v-if="active == 0">
            <div class="nosearch" v-if="isShowSearch == false">
              <div class="reset" @click="reset">重置</div>
              <div class="search" @click="openOverlay">
                搜索
                <span class="arrow_down" @click="openOverlay"></span>
              </div>
            </div>
            <div class="hassearch" v-else>
              <div class="search-form">
                <el-form :label-position="labelPosition">
                  <div class="search-form-row">
                    <el-form-item label="时间段:">
                      <el-select
                        v-model="form.timeFrame"
                        placeholder="请选择时间段"
                        class="search"
                        filterable
                        collapse-tags
                        @change="changeTime"
                      >
                        <el-option
                          v-for="item in timeFrameList"
                          :key="item.dictValue"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item label="公司代码:">
                      <el-input
                        @keyup.enter.native="addCompanyCodes"
                        v-model.trim="form.companyCodes"
                        placeholder="请输入公司代码"
                        @clear="clearInput"
                        clearable
                      ></el-input>
                    </el-form-item>

                    <el-form-item label="公司组合:" class="mr" v-if="false">
                      <treeselect
                        placeholder="请选择公司组合"
                        :options="companyGroupList"
                        :disable-branch-nodes="true"
                        :show-count="true"
                        v-model="companyGroup"
                        :searchable="true"
                        @select="lookGroup"
                        :normalizer="normalizerCompany"
                        @input="clearCompanyGroup"
                        :clear-on-select="true"
                        noOptionsText="无匹配数据"
                        noResultsText="无匹配数据"
                      />
                    </el-form-item>
                  </div>
                  <div class="search-form-row">
                    <el-form-item label="风险类型:" class="riskType"  >
                      <el-select
                        v-model="form.riskTypes"
                        placeholder="请选择风险类型"
                        class="search"
                        filterable
                        multiple
                        collapse-tags
                      >
                        <el-option
                          v-for="item in riskTypeList"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="风险等级:">
                      <el-select
                        v-model="form.analystRatings"
                        placeholder="请选择风险等级"
                        class="search"
                        filterable
                        multiple
                        collapse-tags
                        @clear="clearAnalystRatings"
                      >
                        <el-option
                          v-for="item in analystRatingsList"
                          :key="item.dictValue"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item class="mr" label="行业类型:" v-if="false">
                      <treeselect
                        placeholder="请选择行业"
                        :multiple="true"
                        :limit="5"
                        :options="induustryList"
                        :disable-branch-nodes="true"
                        v-model="form.industryIds"
                        :searchable="true"
                        @select="logVal"
                        :normalizer="normalizer"
                        @input="clearTree"
                        :clear-on-select="true"
                        noOptionsText="无匹配数据"
                        noResultsText="无匹配数据"
                      />
                    </el-form-item>
                  </div>
                </el-form>
              </div>
              <div class="filter-condition">
                <div class="filter-condition-left">
                  <div class="filter-condition-text">筛选条件：</div>
                  <div class="filter-condition-text-box">
                    <!-- 时间段 -->
                    <div class="filter-condition-title" v-if="timeFrameLabel">
                      <div class="filter-condition-title-text">
                        {{ timeFrameLabel }}
                      </div>
                      <div
                        class="filter-condition-title-icon"
                        @click="delTimeFrameTime"
                      >
                        <i class="el-icon-close radios"></i>
                      </div>
                    </div>
                    <!-- 风险类型 -->
                    <div v-for="item in riskTypesLabel" :key="item">
                      <div class="filter-condition-title">
                        <div class="filter-condition-title-text">
                          {{ item }}
                        </div>
                        <div
                          class="filter-condition-title-icon"
                          @click="delRiskTypes(item)"
                        >
                          <i class="el-icon-close radios"></i>
                        </div>
                      </div>
                    </div>
                    <!-- 看看公司代码 -->
                    <div
                      v-for="(item, index) in companyCodesLabel"
                      :key="index"
                    >
                      <div class="filter-condition-title">
                        <div class="filter-condition-title-text">
                          {{ item }}
                        </div>
                        <div
                          class="filter-condition-title-icon"
                          @click="delCompanyCode(item, index)"
                        >
                          <i class="el-icon-close radios"></i>
                        </div>
                      </div>
                    </div>
                    <!-- 风险等级 -->
                    <div
                      v-for="(item, index) in analystRatingsLabel"
                      :key="index"
                    >
                      <div class="filter-condition-title">
                        <div class="filter-condition-title-text">
                          {{ item }}
                        </div>
                        <div
                          class="filter-condition-title-icon"
                          @click="delAnalystRatings(item, index)"
                        >
                          <i class="el-icon-close radios"></i>
                        </div>
                      </div>
                    </div>
                    <!-- 公司组合 -->
                    <div
                      v-for="(item, index) in companyGroupListLabel"
                      :key="index"
                    >
                      <div class="filter-condition-title">
                        <div class="filter-condition-title-text">
                          {{ item }}
                        </div>
                        <div
                          class="filter-condition-title-icon"
                          @click="delCompany(item, index)"
                        >
                          <i class="el-icon-close radios"></i>
                        </div>
                      </div>
                    </div>

                    <!-- 行业类型 -->
                    <div
                      v-for="(item, index) in induustryListLabel"
                      :key="item.id"
                    >
                      <div class="filter-condition-title">
                        <div class="filter-condition-title-text">
                          {{ item }}
                        </div>
                        <div
                          class="filter-condition-title-icon"
                          @click="delInduustryListLabel(item, index)"
                        >
                          <i class="el-icon-close radios"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="filter-condition-btn">
                <div class="filter-condition-btn-reset" @click="reset">
                  重置
                </div>
                <div class="filter-condition-btn-search" @click="search">
                  搜索
                  <span class="arrow_top" @click="closeOverlay"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="mask">
            <van-overlay :show="showOverlay" />
          </div>
          <riskNewsList
            v-for="news in newsList"
            :key="news.id"
            :news="news"
            :isCollection="isCollection"
            class="newslist"
          />
          <div class="pagination">
            <MyPagination
              :total="total"
              @changePage="changePage"
              :currentPage="currentPage"
            />
          </div>
        </van-tab>
      </van-tabs>
      <jurisdictionDialoag
        ref="productIntro"
        :productName="productName"
        serviceCode="SERVICE-002"
        :authority="authority"
      />
    </div>
  </div>
</template>

<script>
import jurisdictionDialoag from '../../../views/components/product-intro/jurisdiction-message.vue'
import { mapGetters } from 'vuex'
import MyPagination from './my-paganation.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import riskNewsList from './negative-report-list.vue'
import { Form, FormItem, Input, Select, Option, Message, Icon } from 'element-ui'
import request from '@/untils/request'
import { getTimeFrameList, getRiskLevelList, getRiskTypeList } from '../../../api/risk.js'
export default {
  data () {
    return {
      normalizer (node) {
        if (!node.childIndustryList) {
          delete node.childIndustryList
        }
        if (node.childIndustryList && !node.childIndustryList.length) {
          delete node.childIndustryList
        }
        return {
          id: node.id,
          label: node.industryName,
          children: node.childIndustryList
        }
      },
      normalizerCompany (node) {
        if (!node.childData) {
          delete node.childData
        }
        if (node.childData && !node.childData.length) {
          delete node.childData
        }
        return {
          id: node.id,
          label: node.name,
          children: node.childData
        }
      },
      token: false,
      isShowoverlay: true,
      labelPosition: 'right',
      showOverlay: false,//是否显示遮罩
      isShowSearch: false,
      riskNewsTab: [
        {
          coulmnName: '实时新闻',
          id: 0
        },
        {
          coulmnName: '合集新闻',
          id: 1
        },
      ],
      productName: '避雷通',
      currentPage: 1,
      active: 0,
      page: 1,
      pageSize: 5,
      total: null,
      newsList: [],
      timeFrameLabel: "",//时间的中文
      riskTypesLabel: [],//风险类型的中文
      companyCodesLabel: [],//公司代码
      analystRatingsLabel: [],//风险等级 只要lable
      flatInduustryList: [],//扁平化行业数组
      induustryListLabel: [],//行业类型中文的数组
      isCollection: false,//区分时事新闻和合计新闻的参数
      form: {
        timeFrame: "",//时间范围
        companyCodes: null,//公司代码
        riskTypes: null,//风险类型
        analystRatings: null,//风险等级
        industryIds: null,//行业类型Id
      },
      isCollection: false,//区分时事新闻和合计新闻的参数
      analystRatingsList: [], //风险等级列表
      timeFrameList: [],//时间范围列表
      riskTypeList: [],//风险类型列表
      induustryList: [],//行业类型列表
      newsList: [],//新闻列表
      companyGroupList: [],//公司组合的数组
      companyGroup: null,
      combinationId: null,//公司组合的id
      level: null,
      securType: null,
      companyGroupListLabel: [],
      noRiskType: true,//是否显示风险类型的字段
      spliceIndex: null,
      authority: -1,
    }
  },
  watch: {
    form: { // 深度监听
      handler (val, oldVal) {
        if (val.timeFrame) {
          this.timeFrameList.forEach((item) => {
            if (item.dictValue == val.timeFrame) {
              this.timeFrameLabel = item.dictLabel
            }
          })
        } else {
          this.timeFrameLabel = null
        }
        if (val.riskTypes) {
          this.riskTypesLabel = val.riskTypes
        }
        if (val.analystRatings) {
          this.analystRatingsLabel = []
          this.analystRatingsList.forEach((item) => {
            val.analystRatings.forEach((itemRiskLevel) => {
              if (itemRiskLevel == item.dictValue) {
                this.analystRatingsLabel.push(item.dictLabel)
              }
            })
          })
          this.analystRatingsLabel = Array.from(new Set(this.analystRatingsLabel))
        }
        if (val.industryIds) {
          this.induustryListLabel = []
          this.flatInduustryList.forEach((item) => {
            val.industryIds.forEach((itemIndustry) => {
              if (itemIndustry == item.id) {
                this.induustryListLabel.push(item.industryName)
              }
            })
          })
          this.induustryListLabel = Array.from(new Set(this.induustryListLabel))
        }
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters(['logined']),
  },
  mounted () {
    // this.getAllNews(this.page, this.pageSize)
    this.getTimeOption()
    this.getRiskLevelOption()
    this.getRiskTypeOption()
    this.getCompanyGroup()
    this.getIndustryIDList()
    this.getNewsList(1, 5)
    this.isValid()
    if (this.logined) {
      this.token = true
    }
  },
  components: {
    jurisdictionDialoag,
    MyPagination,
    Treeselect,
    riskNewsList,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Message.name]: Message,
    [Icon.name]: Icon
  },
  methods: {
    showApplicance () {
      this.$refs.productIntro.showApplicance()
    },
    isValid () {
      request
        .post(
          '/ml-member/user/memberuserservice/getUserServiceStatusV2',
          null,
          { params: { serviceCode: 'SERVICE-002' } }
        )
        .then(({ data }) => {
          if (data.code == 0) {
            this.authority = data.data
          }
        })
    },
    onTabsChange (active) {
      if (active == 1) {
        this.showOverlay = false
        this.isShowSearch = false
        this.isCollection = true
        this.page = 1
        this.total = null,
          this.newsList = []
        this.getNewsList(1, 5)
      }
      if (active == 0) {
        this.isCollection = false
        this.page = 1,
          this.total = null,
          this.newsList = []
        this.getNewsList(1, 5)
      }
    },
    //获取全部新闻和实时新闻的api
    getAllNews (page, pageSize) {
      request.post('/ml-product/lightning/lightningnewsv2/pageNews', {
        ...this.form,
        isCollection: this.isCollection,
        companyCodes: [],
        page: page,
        limit: pageSize,
        isCollection: this.isCollection,
        combinationId: null,
      }).then(({ data }) => {
        if (data.code == 0) {
          this.newsList = data.data.list
        } else {
          Message({
            type: 'error',
            message: data.msg
          })
        }

      })
    },
    openOverlay () {
      this.showOverlay = true
      this.isShowSearch = true
    },
    closeOverlay () {
      this.showOverlay = false
      this.isShowSearch = false
    },
    async getTimeOption () {
      const { data } = await getTimeFrameList()
      this.timeFrameList = data.data.dataList
    },
    async getRiskLevelOption () {
      const { data } = await getRiskLevelList()
      this.analystRatingsList = data.data.dataList
    },
    async getRiskTypeOption () {
      const { data } = await getRiskTypeList()
      this.riskTypeList = data.data
    },
    changeTime () {

    },
    clearInput () {
      this.companyCodesLabel = []
    },
    lookGroup (val) {
      this.combinationId = val.id
      this.companyGroupListLabel = []
      this.companyGroupListLabel.push(val.name)
    },
    clearCompanyGroup (value, instanceId) {
      if (value == undefined) {
        this.companyGroupListLabel = []
      }
    },
    clearAnalystRatings () {
      this.analystRatingsLabel = []
    },
    logVal (val) {
      console.log(val)
      console.log(this.form.industryIds)
    },
    clearTree (value, instanceId) {
      if (value.length <= 0) {
        this.induustryListLabel = []
      }
    },
    delTimeFrameTime () {
      this.form.timeFrame = null
      this.getNewsList(1, 5)
    },
    delRiskTypes (item) {
      this.riskTypesLabel.splice(this.riskTypesLabel.indexOf(item), 1)
      this.getNewsList(1, 5)

    },
    delCompanyCode (item, index) {
      this.companyCodesLabel.splice(index, 1)
      this.spliceIndex = index
      if (this.companyCodesLabel.length == 0) {
        this.form.companyCodes = null
      }
      this.getNewsList(1, 5)
    },
    delAnalystRatings (item, index) {
      this.analystRatingsLabel.splice(index, 1)
      this.form.analystRatings.splice(index, 1)
      this.getNewsList(1, 5)
    },
    delCompany () {
      this.resetCompanyRelatedInfo()
      this.companyGroupListLabel = []
      this.form.companyCodes = null
      this.companyGroup = null
      this.getNewsList(1, 5)
    },
    delInduustryListLabel (item, index) {
      this.form.industryIds.splice(index, 1)
      this.induustryListLabel.splice(index, 1)
      this.getNewsList(1, 5)
    },
    reset () {
      this.form = {}
      this.resetCompanyRelatedInfo()
      this.companyGroup = null,
        this.timeFrameLabel = null,
        this.riskTypesLabel = [],
        this.companyCodesLabel = [],
        this.analystRatingsLabel = [],
        this.companyGroupListLabel = [],
        this.induustryListLabel = [],
        this.getNewsList(1, 5)
      this.showOverlay = false
      this.isShowSearch = false
    },
    search () {
      if (this.token) {
        if (this.authority == 1 || this.authority == 4) {
          this.getNewsList(1, this.pageSize)
          this.showOverlay = false
          this.isShowSearch = false
        } else {
          this.showApplicance()
        }
      } else {
        this.$router.push('/login')
      }
    },
    addCompanyCodes () {
      if (this.form.companyCodes) {
        if (this.companyCodesLabel.some(item => item == this.form.companyCodes) == false) {
          this.companyCodesLabel.push(this.form.companyCodes)
        }
      }
    },
    getCompanyGroup () {
      request.post('/ml-product/index/sharespool/esgExponentialPhaseTreeList').then(({ data }) => {
        if (data.code == 0) {
          this.companyGroupList = data.data
        }
      })
    },
    getIndustryIDList () {
      request.post('/ml-product/basics/industry/getIndustryTree', { obj: {} }).then(({ data }) => {
        if (data.code == 0) {
          this.induustryList = data.data
          this.flatten(this.induustryList)
        }
      })
    },
    flatten (tree, arr = []) {
      tree.forEach(item => {
        const { childIndustryList, ...props } = item
        // 添加除了children的属性
        arr.push(props)
        if (childIndustryList) {
          // 递归将所有节点加入到结果集中
          this.flatten(childIndustryList, arr)
        }
      })
      this.flatInduustryList = [...arr]
      return this.flatInduustryList
    },
    getNewsList (page, pageSize) {
      if (this.active == 1) {
        this.form = {}
        this.page = 1
        this.companyCodesLabel = [],//清空公司代码
          this.analystRatingsLabel = [],//清空风险等级
          this.companyGroupListLabel = [],//清空公司组合
          this.induustryListLabel = [],//清空行业类型,
          this.timeFrameLabel = null,//清空时间段
          this.riskTypesLabel = [],//清空风险类型
          this.companyGroup = null
        this.resetCompanyRelatedInfo()
      }
      if (this.form.companyCodes) {
        if (this.companyCodesLabel.some(item => item == this.form.companyCodes) == false) {
          this.companyCodesLabel.push(this.form.companyCodes)
          if (this.spliceIndex) {
            this.companyCodesLabel.splice(this.spliceIndex, 1)
          }
        }
      }
      this.loading = true
      request.post('/ml-product/lightning/lightningnewsv2/pageNews', {
        ...this.form,
        companyCodes: this.companyCodesLabel || [],
        page: page,
        limit: pageSize,
        isCollection: this.isCollection,
        combinationId: this.companyGroup ? this.combinationId : null,
      }).then(({ data }) => {
        if (data.code == 0) {
          this.loading = false
          this.newsList = data.data.list
          this.total = data.data.total
          window.scrollTo(0, 0)
        }
      })
    },
    resetCompanyRelatedInfo () {
      this.combinationId = null
      this.level = null
      this.securType = null
    },
    changePage (val) {
      if (this.token) {
        if (this.authority == 1 || this.authority == 4) {
          this.page = val
          this.currentPage = val
          this.getNewsList(this.page, 5)
        } else {
          this.showApplicance()
        }
      } else {
        this.$router.push('/login')
      }
    }

  },
}
</script>

<style lang="less" scoped>
.tabs {
  width: 375px;
}
.pagination {
  margin: auto;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 79px;
}
/deep/.mytabs {
  .van-tabs__wrap {
    background: #ffffff;
    box-shadow: 0px 1px 0px 0px rgba(65, 125, 255, 0.2);
    border-bottom: 1px solid rgba(65, 125, 255, 0.2);
  }
}
/deep/.van-tab__text--ellipsis {
  height: 50px;
  line-height: 50px;
}
/deep/ .van-tab__text {
  font-size: 16px;
  letter-spacing: 0.89px;
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC;
  background: #ffffff;
}
/deep/.van-tabs__line {
  height: 3px !important;
}
/deep/.risk-list {
  .list {
    border-top: 0px solid #dadada !important;
  }
}
.search-box {
  margin-top: 12px;
  position: relative;
  .nosearch {
    display: flex;
    margin-top: 14px;
    margin-bottom: 14px;
    padding-left: 7px;
    padding-right: 7px;
    .reset {
      width: 168px;
      height: 33px;
      border: 1px solid #417dff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #417dff;
      line-height: 33px;
      text-align: center;
    }
    .search {
      width: 168px;
      height: 33px;
      background: #417dff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 33px;
      text-align: center;
      margin-left: 25px;
      position: relative;
      .arrow_down {
        width: 0;
        height: 0;
        position: absolute;
        top: 15px;
        right: 53px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #ffffff;
      }
    }
  }
  .hassearch {
    margin-bottom: 14px;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 2;
    /deep/.search-form {
      .el-input {
        width: 280px;
      }
      .el-input__inner {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a0a3ac;
      }
      .el-form-item__label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323338;
        min-width: 80px;
      }
      .search-form-row {
        .el-form-item__content {
          display: flex;
        }
      }
    }
    .filter-condition {
      padding-left: 12px;
      height: auto;
      .filter-condition-left {
        height: auto;
        display: flex;
        // 筛选条件四个字
        .filter-condition-text {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #323338;
          height: 30px;
          line-height: 30px;
        }
        // 内容盒子
        .filter-condition-title {
          height: 26px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          padding-right: 9px;
          padding-top: 4px;
          padding-bottom: 4px;
          border-radius: 13px;
          opacity: 0.9;
          border: 1px solid #417dff;
          cursor: pointer;
          margin-left: 10px;
          margin-top: 4px;
          .filter-condition-title-text {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #417dff;
            line-height: 15px;
          }
          .filter-condition-title-icon {
            display: flex;
            align-items: center;
          }
        }
      }
    }
    // 按钮
    .filter-condition-btn {
      height: 33px;
      display: flex;
      padding-left: 7px;
      padding-right: 7px;
      margin-bottom: 20px;
      margin-top: 17px;
      .filter-condition-btn-reset {
        width: 168px;
        height: 33px;
        border: 1px solid #417dff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #417dff;
        line-height: 33px;
        text-align: center;
      }
      .filter-condition-btn-search {
        width: 168px;
        height: 33px;
        background: #417dff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 33px;
        text-align: center;
        margin-left: 25px;
        position: relative;
        .arrow_top {
          width: 0;
          height: 0;
          position: absolute;
          bottom: 14px;
          right: 28px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 4px solid #ffffff;
        }
      }
    }
  }
}
/deep/.newslist {
  width: 361px;
  height: auto;
  padding-left: 7px;
  padding-right: 7px;

  .list-item-box {
    width: 361px !important;
    padding-left: 13px !important;
  }
}
/deep/.mask {
  .van-overlay {
    top: 100px;
  }
}
/deep/.vue-treeselect {
  width: 280px;
}
/deep/.vue-treeselect__single-value{
  color: #a0a3ac;
  display: flex;
  align-items: center;
}
/deep/.vue-treeselect__value-container{
  display: flex;
  align-items: center;
}
/deep/.vue-treeselect__placeholder{
  display: block;
  display: flex;
  align-items: center;
}
.filter-condition-text-box {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.filter-condition-title-box {
  display: flex;
  flex-wrap: 1;
  flex: 1;
}

/deep/.el-icon-close {
  color: #fff !important;
  background-color: #dee1e9 !important;
}
.radios {
  border-radius: 50%;
}
/deep/.riskType {
  .el-tag {
    max-width: 140px;
    display: inline-block;
    display: flex;
    align-items: center;
  }
  .el-select__tags-text {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
/deep/.el-icon-close {
  color: #fff !important;
  background-color: #dee1e9 !important;
}
</style>